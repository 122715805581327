import { NgModule } from '@angular/core';
import {HelperService} from './helper.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { CalendarService } from 'app/core/calendar.service';
import {EntryService} from './entry.service';
import {MessageService} from './message.service';
import {GroupService} from './group.service';
import {InstitutionService} from './institution.service';
import {InvitationService} from './invitation.service';
import {RelationService} from './relation.service';
import {ServiceReceiverService} from './service-receiver.service';
import {AddressService} from './address.service';
import {QAService} from './qa.service';
import {EulaService} from './eula.service';
import {ImageService} from './image.service';
import {PermissionService} from './permission.service';
import {FCMService} from './fcm.service';
import {DialogService} from './dialog.service';

@NgModule({
  providers: [
    HelperService,
    AuthService,
    UserService,
    ApiService,
    CalendarService,
    EntryService,
    MessageService,
    GroupService,
    InstitutionService,
    InvitationService,
    RelationService,
    ServiceReceiverService,
    AddressService,
    QAService,
    EulaService,
    ImageService,
    PermissionService,
    FCMService,
    DialogService
  ]
})

export class CoreModule {}

import {NgModule} from '@angular/core';
import {NotifierModule} from 'angular-notifier';

@NgModule({
  imports: [
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 30
        },
        vertical: {
          position: 'top',
          distance: 70,
          gap: 10
        }
      }
    })
  ]
})

export class NotificationModule { }

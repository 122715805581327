import {Action} from '@ngrx/store';

export const LOAD_BY_OWNER = '[Occurrence] load by owner';
export const LOAD_BY_OWNER_SUCCESS = '[Occurrence] load by owner success';
export const LOAD_BY_OWNER_FAILURE = '[Occurrence] load by owner failure';
export const LOAD_BY_EVENT_ID = '[Occurrence] load by event id';
export const LOAD_BY_EVENT_ID_SUCCESS = '[Occurrence] load by event id success';
export const LOAD_BY_EVENT_ID_FAILURE = '[Occurrence] load by event id failure';
export const LOAD_MORE_OCCURRENCES = '[Occurrence] load more occurrences';
export const LOAD_MORE_OCCURRENCES_SUCCESS = '[Occurrence] load more occurrences success';
export const LOAD_MORE_OCCURRENCES_FAILURE = '[Occurrence] load more occurrences failure';
export const COMMENT = '[Occurrence] comment';
export const COMMENT_SUCCESS = '[Occurrence] comment success';
export const COMMENT_FAILURE = '[Occurrence] comment failure';
export const DELETE_COMMENT = '[Occurrence] delete comment';
export const DELETE_COMMENT_SUCCESS = '[Occurrence] delete comment success';
export const DELETE_COMMENT_FAILURE = '[Occurrence] delete comment failure';
export const UPDATE_COMMENT = '[Occurrence] update comment';
export const UPDATE_COMMENT_SUCCESS = '[Occurrence] update comment success';
export const UPDATE_COMMENT_FAILURE = '[Occurrence] update comment failure';
export const LOAD_COMMENTS_BY_OCCURRENCE_ID = '[Occurrence] load comment by occurrence id';
export const LOAD_COMMENTS_BY_OCCURRENCE_ID_SUCCESS = '[Occurrence] load comments by occurrence id success';
export const LOAD_COMMENTS_BY_OCCURRENCE_ID_FAILURE = '[Occurrence] load comments by occurrence id failure';
export const LOAD_COMMENTS_BY_EVENT_ID = '[Occurrence] load comments by event id ';
export const LOAD_COMMENTS_BY_EVENT_ID_SUCCESS = '[Occurrence] load comment by event id success';
export const LOAD_COMMENTS_BY_EVENT_ID_FAILURE = '[Occurrence] load comment by event id failure';
export const CANCEL = '[Occurrence] cancel';
export const CANCEL_SUCCESS = '[Occurrence] cancel success';
export const CANCEL_FAILURE = '[Occurrence] cancel failure';
export const DELETE = '[Occurrence] delete';
export const DELETE_SUCCESS = '[Occurrence] delete success';
export const DELETE_FAILURE = '[Occurrence] delete failure';
export const UPDATE = '[Occurrence] update';
export const UPDATE_SUCCESS = '[Occurrence] update success';
export const UPDATE_FAILURE = '[Occurrence] update failure';
export const RESET_STATE_MESSAGE = '[Occurrence] reset state message';

export class LoadByOwner implements Action {
  readonly type = LOAD_BY_OWNER;

  constructor(public payload?: any) {}
}

export class LoadByOwnerSuccess implements Action {
  readonly type = LOAD_BY_OWNER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByOwnerFailure implements Action {
  readonly type = LOAD_BY_OWNER_FAILURE;

  constructor(public payload: string) {}
}

export class LoadByEventId implements Action {
  readonly type = LOAD_BY_EVENT_ID;

  constructor(public payload: any) {}
}

export class LoadByEventIdSuccess implements Action {
  readonly type = LOAD_BY_EVENT_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByEventIdFailure implements Action {
  readonly type = LOAD_BY_EVENT_ID_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreOccurrences implements Action {
  readonly type = LOAD_MORE_OCCURRENCES;

  constructor(public payload: string) {}
}

export class LoadMoreOccurrencesSuccess implements Action {
  readonly type = LOAD_MORE_OCCURRENCES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreOccurrencesFailure implements Action {
  readonly type = LOAD_MORE_OCCURRENCES_FAILURE;

  constructor(public payload: string) {}
}

export class Comment implements Action {
  readonly type = COMMENT;

  constructor(public payload: any) {}
}

export class CommentSuccess implements Action {
  readonly type = COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class CommentFailure implements Action {
  readonly type = COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class DeleteComment implements Action {
  readonly type = DELETE_COMMENT;

  constructor(public payload: any) {}
}

export class DeleteCommentSuccess implements Action {
  readonly type = DELETE_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteCommentFailure implements Action {
  readonly type = DELETE_COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateComment implements Action {
  readonly type = UPDATE_COMMENT;

  constructor(public payload: any) {}
}

export class UpdateCommentSuccess implements Action {
  readonly type = UPDATE_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateCommentFailure implements Action {
  readonly type = UPDATE_COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class Cancel implements Action {
  readonly type = CANCEL;

  constructor(public payload: string) {}
}

export class CancelSuccess implements Action {
  readonly type = CANCEL_SUCCESS;

  constructor(public payload: any) {}
}

export class CancelFailure implements Action {
  readonly type = CANCEL_FAILURE;

  constructor(public payload: string) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public payload: string) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteFailure implements Action {
  readonly type = DELETE_FAILURE;

  constructor(public payload: string) {}
}

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: any) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: string) {}
}

export class ResetStateMessage implements Action {
  readonly type = RESET_STATE_MESSAGE;

  constructor(public payload?: any) {}
}

export class LoadCommentsByOccurrenceId implements Action {
  readonly type = LOAD_COMMENTS_BY_OCCURRENCE_ID;

  constructor(public payload?: any) {}
}

export class LoadCommentsByOccurrenceIdSuccess implements Action {
  readonly type = LOAD_COMMENTS_BY_OCCURRENCE_ID_SUCCESS;

  constructor(public payload?: any) {}
}

export class LoadCommentsByOccurrenceIdFailure implements Action {
  readonly type = LOAD_COMMENTS_BY_OCCURRENCE_ID_FAILURE;

  constructor(public payload?: any) {}
}

export class LoadCommentsByEventId implements Action {
  readonly type = LOAD_COMMENTS_BY_EVENT_ID;

  constructor(public payload: any) {}
}

export class LoadCommentsByEventIdSuccess implements Action {
  readonly type = LOAD_COMMENTS_BY_EVENT_ID_SUCCESS;

  constructor(public payload?: any) {}
}

export class LoadCommentsByEventIdFailure implements Action {
  readonly type = LOAD_COMMENTS_BY_EVENT_ID_FAILURE;

  constructor(public payload?: any) {}
}

export type All
  = LoadByOwner | LoadByOwnerSuccess | LoadByOwnerFailure | LoadByEventId | LoadByEventIdSuccess | LoadByEventIdFailure |
  LoadMoreOccurrences | LoadMoreOccurrencesSuccess | LoadMoreOccurrencesFailure | Comment | CommentSuccess |
  CommentFailure | DeleteComment | DeleteCommentSuccess | DeleteCommentFailure | Cancel | CancelSuccess |
  CancelFailure | Delete | DeleteSuccess | DeleteFailure | Update | UpdateSuccess | UpdateFailure | ResetStateMessage |
  LoadCommentsByEventId | LoadCommentsByEventIdFailure | LoadCommentsByEventIdSuccess | LoadCommentsByOccurrenceId |
  LoadCommentsByOccurrenceIdFailure | LoadCommentsByOccurrenceIdSuccess | UpdateComment | UpdateCommentFailure | UpdateCommentSuccess;

import {Action} from '@ngrx/store';

export const LOAD = '[Groups] load groups';
export const LOAD_SUCCESS = '[Groups] load groups success';
export const LOAD_FAILURE = '[Groups] load groups failure';
export const LOAD_MORE = '[Groups] load more groups';
export const LOAD_MORE_SUCCESS = '[Groups] load more groups success';
export const LOAD_MORE_FAILURE = '[Groups] load more groups failure';
export const SEARCH = '[Groups] search';
export const SEARCH_SUCCESS = '[Groups] search success';
export const SEARCH_FAILURE = '[Groups] search failed';
export const LOAD_BY_ID = '[Groups] load by id';
export const LOAD_BY_ID_SUCCESS = '[Groups] load by id success';
export const LOAD_BY_ID_FAILURE = '[Groups] load by id failure';
export const CREATE = '[Groups] create';
export const CREATE_SUCCESS = '[Groups] create success';
export const CREATE_FAILURE = '[Groups] create failure';
export const LOAD_BY_USER_ID = '[Groups] load by user id';
export const LOAD_BY_USER_ID_SUCCESS = '[Groups] load by user id success';
export const LOAD_BY_USER_ID_FAILURE = '[Groups] load by user id failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}
export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload: string) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class Search implements Action {
  readonly type = SEARCH;

  constructor(public payload: string) {}
}

export class SearchSuccess implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchFailed implements Action {
  readonly type = SEARCH_FAILURE;

  constructor(public payload: string) {}
}

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: number) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class Create implements Action {
  readonly type = CREATE;

  constructor(public payload: any) {}
}

export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateFailure implements Action {
  readonly type = CREATE_FAILURE;

  constructor(public payload: string) {}
}

export class LoadByUserId implements Action {
  readonly type = LOAD_BY_USER_ID;

  constructor(public payload: string) {}
}

export class LoadByUserIdSuccess implements Action {
  readonly type = LOAD_BY_USER_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByUserIdFailure implements Action {
  readonly type = LOAD_BY_USER_ID_FAILURE;

  constructor(public payload: string) {}
}

export type All
  = Load | LoadSuccess | LoadFailure | LoadMore| LoadMoreSuccess | LoadMoreFailure | Search | SearchSuccess |
  SearchFailed | LoadById | LoadByIdSuccess | LoadByIdFailure | Create | CreateSuccess | CreateFailure |
  LoadByUserId | LoadByUserIdSuccess | LoadByUserIdFailure;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as EULAActions from './eula.actions';
import {EulaService} from '../../core/eula.service';
import * as AuthActions from '../auth/auth.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class EULAEffects {

  @Effect() load$: Observable<Action> = this.actions$.pipe(
    ofType(EULAActions.LOAD),
    map((action: EULAActions.Load) => action.payload),
    switchMap(payload => {
      return this._service.load(payload).pipe(
        map(data => new EULAActions.LoadSuccess(data)),
        catchError(error => of(new EULAActions.LoadFailure(error.json())))
      );
    }),
    catchError(error => of(new EULAActions.LoadFailure('AN_ERROR_OCCURRED')))
  );

  @Effect() send$: Observable<Action> = this.actions$.pipe(
    ofType(EULAActions.SEND),
    map((action: EULAActions.Send) => action.payload),
    switchMap(consent => {
      return this._service.sendEula(consent).pipe(
        map(data => new EULAActions.SendSuccess(data)),
        catchError(error => of(new EULAActions.SendFailure(error.json())))
      );
    }),
    catchError(error => of(new EULAActions.SendFailure('AN_ERROR_OCCURRED')))
  );

  @Effect() loadLoggedInUser$: Observable<Action> = this.actions$.pipe(
    ofType(EULAActions.SEND_SUCCESS),
    map(_ => new AuthActions.LoadAuthUser())
  );

  @Effect() loadByUser$: Observable<Action> = this.actions$.pipe(
    ofType(EULAActions.LOAD_BY_USER),
    map((action: EULAActions.LoadByUser) => action.payload),
    switchMap(payload => {
      return this._service.loadByUser(payload).pipe(
        map(data => new EULAActions.LoadByUserSuccess(data)),
        catchError(error => of(new EULAActions.LoadByUserFailure(error.json())))
      );
    }),
    catchError(error => of(new EULAActions.LoadByUserFailure('AN_ERROR_OCCURRED')))
  );

  constructor(private actions$: Actions, private _service: EulaService) {}
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import * as RelativeActions from './relative.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class RelativeEffects {

  @Effect() loadRelatives$: Observable<Action> = this._actions.pipe(
    ofType(RelativeActions.LOAD),
    switchMap(_ => {
      return this._service.loadRelatives().pipe(
        map(data => new RelativeActions.LoadSuccess(data)),
        catchError(response => of(new RelativeActions.LoadFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _service: UserService) {
  }
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as CreateInstitutionChildActions from './create-institution-child.actions';
import {InstitutionService} from '../../core/institution.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class CreateInstitutionChildEffects {

  @Effect() createInstitutionChild$: Observable<Action> = this._actions$.pipe(
    ofType(CreateInstitutionChildActions.CREATE_INSTITUTION_CHILD),
    map((action: CreateInstitutionChildActions.CreateInstitutionChild) => action.payload),
    switchMap(payload => {
      return this._service.createChildren(payload.subDivision, payload.address).pipe(
        map(data => new CreateInstitutionChildActions.CreateInstitutionChildSuccess(data)),
        catchError(error => of(new CreateInstitutionChildActions.CreateInstitutionChildFailed(error)))
      );
    })
  );

  constructor(private _actions$: Actions, private _service: InstitutionService) {}
}

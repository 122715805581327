import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';


@Injectable()
export class QAService extends ApiService {

  private _feedbackUrl = 'https://us-central1-jodacare-system-tests.cloudfunctions.net/feedbackWebhook';

  constructor(private _http: HttpClient, protected _ls: LocalStorageService) {
    super(_ls);
  }

  sendFeedback(feedback: any): Observable<any> {
    return this._http.post(this._feedbackUrl, JSON.stringify(feedback), { headers: this._getHeaders() });
  }

}

import {Action} from '@ngrx/store';

export const LOAD_ADDRESS = '[Address] load address';
export const LOAD_ADDRESS_SUCCESS =  '[Address] load address success';
export const LOAD_ADDRESS_FAILED = '[Address] load address failes';
export const LOAD_COUNTRIES = '[Address] load countries';
export const LOAD_COUNTRIES_SUCCESS = '[Address] load countries success';
export const LOAD_COUNTRIES_FAILED = '[Address] load countries failure';
export const LOAD_MUNICIPALITIES = '[Address] load municipalities';
export const LOAD_MUNICIPALITIES_SUCCESS = '[Address] load municipalities success';
export const LOAD_MUNICIPALITIES_FAILED = '[Address] load municipalities failure';
export const SEARCH_MUNICIPALITIES = '[Address] search municipalities';
export const SEARCH_MUNICIPALITIES_SUCCESS = '[Address] search municipalities success';
export const SEARCH_MUNICIPALITIES_FAILED = '[Address] search municipalities failure';

export class LoadAddress implements Action {
  readonly type = LOAD_ADDRESS;

  constructor(public payload: any) {}
}

export class LoadAddressSuccess implements Action {
  readonly type = LOAD_ADDRESS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadAddressFailed implements Action {
  readonly type = LOAD_ADDRESS_FAILED;

  constructor(public payload: string) {}
}

export class LoadCountries implements Action {
  readonly type = LOAD_COUNTRIES;

  constructor(public payload?: any) {}
}

export class LoadCountriesSuccess implements Action {
  readonly type = LOAD_COUNTRIES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadCountriesFailed implements Action {
  readonly type = LOAD_COUNTRIES_FAILED;

  constructor(public payload: string) {}
}

export class LoadMunicipalities implements Action {
  readonly type = LOAD_MUNICIPALITIES;

  constructor(public payload: string) {}
}

export class LoadMunicipalitiesSuccess implements Action {
  readonly type = LOAD_MUNICIPALITIES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMunicipalitiesFailed implements Action {
  readonly type = LOAD_MUNICIPALITIES_FAILED;

  constructor(public payload: string) {}
}

export class SearchMunicipalities implements Action {
  readonly type = SEARCH_MUNICIPALITIES;

  constructor(public payload: string) {}
}

export class SearchMunicipalitiesSuccess implements Action {
  readonly type = SEARCH_MUNICIPALITIES_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchMunicipalitiesFailed implements Action {
  readonly type = SEARCH_MUNICIPALITIES_FAILED;

  constructor(public payload: string) {}
}

export type All = LoadAddress | LoadAddressSuccess | LoadAddressFailed | LoadCountries | LoadCountriesFailed | LoadCountriesSuccess |
  LoadMunicipalities | LoadMunicipalitiesSuccess | LoadMunicipalitiesFailed | SearchMunicipalities | SearchMunicipalitiesSuccess |
  SearchMunicipalitiesFailed;


import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {API_URL, API_VERSION} from './config';
import {Observable} from 'rxjs';

@Injectable()
export class PermissionService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  checkPermission(id: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${id}/permissions`;
    return this._http.get(url, { headers: this._getHeaders() });
  }
}

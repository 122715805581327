import * as AddressActions from './address.action';
import {handleError} from '../helpers';
import {Municipality} from '../../interfaces';

export type Action = AddressActions.All;

export interface AddressState {
  loading: boolean;
  error: string;
  entitiesByUrl: any;
  countries: string[];
  municipalities: Municipality[];
  searchResults: Municipality[];
}

const initalState: AddressState = {
  loading: false,
  error: null,
  entitiesByUrl: null,
  countries: null,
  municipalities: null,
  searchResults: null,
};

export function reducer(state: AddressState = initalState, {type, payload}: Action): AddressState {
  switch (type) {
    case AddressActions.LOAD_ADDRESS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.LOAD_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        entitiesByUrl: payload
      });
    case AddressActions.LOAD_ADDRESS_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AddressActions.LOAD_COUNTRIES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.LOAD_COUNTRIES_SUCCESS:
      console.log(payload);
      return Object.assign({}, state, {
        loading: false,
        error: null,
        countries: payload
      });
    case AddressActions.LOAD_COUNTRIES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AddressActions.LOAD_MUNICIPALITIES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.LOAD_MUNICIPALITIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        municipalities: payload.results
      });
    case AddressActions.LOAD_MUNICIPALITIES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AddressActions.SEARCH_MUNICIPALITIES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case AddressActions.SEARCH_MUNICIPALITIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        searchResults: payload
      });
    case AddressActions.SEARCH_MUNICIPALITIES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getAddressLoadingState = (state: AddressState) => state.loading;
export const getAddressErrorState = (state: AddressState) => state.error;
export const getCountries = (state: AddressState) => state.countries;
export const getMunicipalities = (state: AddressState) => state.municipalities;
export const getSearchResults = (state: AddressState) => state.searchResults;

import * as InstitutionActions from './institutions.actions';
import {addEntity, handleError, sortEntitiesById} from '../helpers';

export type Action = InstitutionActions.All;

export interface InstitutionState {
  loading: boolean;
  loadingChildren: boolean;
  entities: any[];
  entitiesById: any;
  nextUrl: string;
  error: string;
  message: string;
  searchResult: any;
  employees: any;
  serviceReceivers: any;
  entitiesByUser: any;
}

const initialState: InstitutionState = {
  loading: false,
  loadingChildren: false,
  entities: null,
  entitiesById: null,
  nextUrl: null,
  error: null,
  message: null,
  searchResult: null,
  employees: null,
  serviceReceivers: null,
  entitiesByUser: null
};

export function reducer(state: InstitutionState = initialState, {type, payload}: Action): InstitutionState {
  switch (type) {
    case InstitutionActions.LOAD:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });
    case InstitutionActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        entitiesById: sortEntitiesById(payload.results),
        nextUrl: payload.next
      });
    case InstitutionActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });
    case InstitutionActions.LOAD_MORE:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });
    case InstitutionActions.LOAD_MORE_SUCCESS:
      const added = [].concat(...state.entities, payload.results);

      return Object.assign({}, state, {
        loading: false,
        entities: added,
        entitiesById: sortEntitiesById(added),
        nextUrl: payload.next
      });
    case InstitutionActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });
    case InstitutionActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_BY_ID_SUCCESS:
      const added1 = addEntity(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        entities: added1,
        entitiesById: sortEntitiesById(added1),
      });
    case InstitutionActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload.error)
      });
    case InstitutionActions.SEARCH_SUCCESS:
      return Object.assign({}, state, {
        searchResult: payload.results,
        loading: false,
        message: 'SUCCESS_MESSAGE'
      });
    case InstitutionActions.SEARCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorMessage: 'AN_ERROR_OCCURRED'
      });
    case InstitutionActions.LOAD_CHILDREN:
      return Object.assign({}, state, {
        loadingChildren: true,
        error: null
      });
    case InstitutionActions.LOAD_CHILDREN_SUCCESS:
      const childrenAdded = state.entities.map(entity => {
        if (entity.url === payload.parent) {
          return Object.assign({}, entity, {
            children_detailed: payload.children
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        loadingChildren: false,
        entities: childrenAdded,
        entitiesById: sortEntitiesById(childrenAdded),
      });
    case InstitutionActions.LOAD_CHILDREN_FAILURE:
      return Object.assign({}, state, {
        loadingChildren: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_EMPLOYEES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_EMPLOYEES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        employees: Object.assign({}, state.employees, {
          [payload.institutionId]: payload.result.results
        }),
        error: null
      });
    case InstitutionActions.LOAD_EMPLOYEES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_SERVICE_RECEIVERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_SERVICE_RECEIVERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceivers: Object.assign({}, state.serviceReceivers, {
          [payload.institutionId]: payload.result.results
        })
      });
    case InstitutionActions.LOAD_SERVICE_RECEIVERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_BY_USER_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_BY_USER_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByUser: Object.assign({}, state.entitiesByUser, {
          [payload.userId]: payload.result
        })
      });
    case InstitutionActions.LOAD_BY_USER_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.ADD_EMPLOYEE:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case InstitutionActions.ADD_EMPLOYEE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        employees: Object.assign({}, state.employees, {
          [payload.institutionId]: payload.employees
        })
      });
    case InstitutionActions.ADD_EMPLOYEE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.REMOVE_EMPLOYEE:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case InstitutionActions.REMOVE_EMPLOYEE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        employees: Object.assign({}, state.employees, {
          [payload.institutionId]: payload.employees
        }),
        error: null
      });
    case InstitutionActions.REMOVE_EMPLOYEE_FAILURE:
      return Object.assign({}, state, {
        loading: true,
        error: handleError(payload)
      });
    case InstitutionActions.ADD_SERVICE_RECEIVER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.ADD_SERVICE_RECEIVER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceivers: Object.assign({}, state.serviceReceivers, {
          [payload.institutionId]: payload.serviceReceivers
        })
      });
    case InstitutionActions.ADD_SERVICE_RECEIVER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.REMOVE_SERVICE_RECEIVER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.REMOVE_SERVICE_RECEIVER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceivers: Object.assign({}, state.serviceReceivers, {
          [payload.institutionId]: payload.serviceReceivers
        }),
        error: null
      });
    case InstitutionActions.REMOVE_SERVICE_RECEIVER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_MANAGER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_MANAGER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: {
          [payload.institutionId]: Object.assign({}, state.entitiesById[payload.institutionId], {manager: payload.manager})
        },
        entities: state.entities.map((entity) =>
          (entity.id === payload.institutionId) ? Object.assign({}, entity, {manager: payload.manager}) : entity),
        error: null
      });
    case InstitutionActions.LOAD_MANAGER_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: InstitutionState) => state.loading;
export const getEntities = (state: InstitutionState) => state.entities;
export const getById = (state: InstitutionState) => state.entitiesById;
export const getNextUrl = (state: InstitutionState) => state.nextUrl;
export const getError = (state: InstitutionState) => state.error;
export const getMessage = (state: InstitutionState) => state.message;
export const getSearchResult = (state: InstitutionState) => state.searchResult;
export const getChildrenLoadingState = (state: InstitutionState) => state.loadingChildren;
export const getEmployees = (state: InstitutionState) => state.employees;
export const getServiceReceivers = (state: InstitutionState) => state.serviceReceivers;
export const getByUserId = (state: InstitutionState) => state.entitiesByUser;

import * as SupportActions from './support.action';

export type Action = SupportActions.All;

export interface SupportState {
  loading: boolean;
  message: string;
  error: string;
}

const initialState: SupportState = {
  loading: null,
  message: null,
  error: null
};

export function reducer(state: SupportState = initialState, {type, payload}: Action): SupportState {
  switch (type) {
    case SupportActions.SEND_BUG_REPORT:
      return Object.assign({}, state, {
        loading: true,
        message: 'SENDING_BUG_REPORT',
        error: null
      });
    case SupportActions.SEND_BUG_REPORT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'BUG_REPORT_SENT',
        error: null
      });
    case SupportActions.SEND_BUG_REPORT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: 'BUG_REPORT_NOT_SENT'
      });
    case SupportActions.RESET_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: SupportState) => state.loading;
export const getMessage = (state: SupportState) => state.message;
export const getError = (state: SupportState) => state.error;

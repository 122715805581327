import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import * as SettingsActions from './settings.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class SettingsEffects {

  @Effect() loadByUser$: Observable<Action> = this._actions.pipe(
    ofType(SettingsActions.LOAD_BY_USER),
    map((action: SettingsActions.LoadByUser) => action.payload),
    switchMap(payload => {
      return this._service.loadSettings(payload).pipe(
        map(data => new SettingsActions.LoadByUserSuccess(data)),
        catchError(response => of(new SettingsActions.LoadByUserFailure(response)))
      );
    }),
    catchError(response => of(new SettingsActions.LoadByUserFailure('AN_ERROR_OCCURRED')))
  );

  @Effect() save$: Observable<Action> = this._actions.pipe(
    ofType(SettingsActions.SAVE),
    map((action: SettingsActions.Save) => action.payload),
    switchMap(payload => {
      return this._service.saveSettings(payload).pipe(
        map(data => new SettingsActions.SaveSuccess(data)),
        catchError(response => of(new SettingsActions.SaveFailure(response)))
      );
    }),
    catchError(response => of(new SettingsActions.SaveFailure('AN_ERROR_OCCURRED')))
  );

  constructor(private _actions: Actions, private _service: UserService) {
  }
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {MessageService} from '../../core/message.service';
import * as MessageActions from './message.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as CreateActions from '../create/create.actions';
import {switchMap, map, catchError} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class MessageEffects {

  @Effect() loadMessages$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD),
    switchMap(_ => {
      return this._service.load().pipe(
        map(data => new MessageActions.LoadSuccess(data)),
        catchError(response => of(new MessageActions.LoadFailure(response)))
      );
    })
  );

  @Effect() loadMoreMessages$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD_MORE),
    map((action: MessageActions.LoadMore) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload).pipe(
        map(data => new MessageActions.LoadMoreSuccess(data)),
        catchError(response => of(new MessageActions.LoadMoreFailure(response)))
      );
    })
  );

  @Effect() loadFeedByOwner$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD_FEED_BY_OWNER),
    map((action: MessageActions.LoadFeedByOwner) => action.payload),
    switchMap(payload => {
      return this._service.loadFeedByOwner(payload).pipe(
        map(data => new MessageActions.LoadFeedByOwnerSuccess(data)),
        catchError(error => of(new MessageActions.LoadFeedByOwnerFailure(error)))
      );
    })
  );

  @Effect() delete$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.DELETE),
    map((action: MessageActions.Delete) => action.payload),
    switchMap(payload => {
      return this._service.deleteMessage(payload).pipe(
        map(data => new MessageActions.DeleteSuccess(data)),
        catchError(error => of(new MessageActions.DeleteFailure(error)))
      );
    })
  );

  @Effect() update$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.UPDATE),
    map((action: MessageActions.Update) => action.payload),
    switchMap(payload => {
      return this._service.update(payload).pipe(
        map(data => new MessageActions.UpdateSuccess(data)),
        catchError(error => of(new MessageActions.UpdateFailure(error)))
      );
    })
  );

  @Effect() toggleRead$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.TOGGLE_READ),
    map((action: MessageActions.ToggleRead) => action.payload),
    switchMap(payload => {
      return this._service.toggleRead(payload.message, payload.user).pipe(
        map(data => new MessageActions.ToggleReadSuccess(data)),
        catchError(error => of(new MessageActions.ToggleReadFailure(error)))
      );
    })
  );

  @Effect() comment$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.COMMENT),
    map((action: MessageActions.Comment) => action.payload),
    switchMap(payload => {
      return this._service.comment(payload).pipe(
        map(data => new MessageActions.CommentSuccess(data)),
        catchError(response => of(new MessageActions.CommentFailure(response)))
        );
    })
  );

  @Effect() deleteComment$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.DELETE_COMMENT),
    map((action: MessageActions.DeleteComment) => action.payload),
    switchMap(payload => {
      return this._service.deleteComment(payload).pipe(
        map(data => new MessageActions.DeleteCommentSuccess(data)),
        catchError(response => of(new MessageActions.DeleteCommentFailure(response)))
      );
    })
  );

  @Effect() updateComment$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.UPDATE_COMMENT),
    map((action: MessageActions.UpdateComment) => action.payload),
    switchMap(payload => {
      return this._service.updateComment(payload).pipe(
        map(data => new MessageActions.UpdateCommentSuccess(data)),
        catchError(response => of(new MessageActions.UpdateCommentFailure(response)))
      );
    }),
    catchError(response => of(new MessageActions.UpdateCommentFailure(response)))
  );

  @Effect() created1$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.BULK_PUBLISH_MESSAGE_SUCCESS),
    switchMap(payload => of(new MessageActions.Load()))
  );

  @Effect() created2$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.PUBLISH_MESSAGE_SUCCESS),
    switchMap(payload => of(new MessageActions.Load()))
  );

  @Effect() loadById$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD_BY_ID),
    map((action: MessageActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload).pipe(
        map(data => new MessageActions.LoadByIdSuccess(data)),
        catchError(error => of(new MessageActions.LoadByIdFailure(error)))
      );
    })
  );

  @Effect() loadUnread$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD_UNREAD),
    switchMap(_ => {
      return this._service.loadUnreadMessages().pipe(
        map(data => new MessageActions.LoadUnreadSuccess(data)),
        catchError(response => of(new MessageActions.LoadUnreadFailure(response)))
      );
    })
  );

  @Effect() markAllAsRead$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.MARK_ALL_AS_READ),
    switchMap(_ => {
      return this._service.toggleReadAll().pipe(
        map(data => new MessageActions.MarkAllAsReadSuccess(data)),
        catchError(response => of(new MessageActions.MarkAllAsReadFailure(response)))
      );
    })
  );

  @Effect() loadPinnedMessages$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD_PINNED_MESSAGES),
    switchMap(_ => {
      return this._service.loadPinnedMessages().pipe(
        map(data => new MessageActions.LoadPinnedMessagesSuccess(data)),
        catchError(response => of(new MessageActions.LoadPinnedMessagesFailure(response)))
      );
    }),
    catchError(response => of(new MessageActions.LoadPinnedMessagesFailure(response)))
  );

  @Effect() loadMorePinnedMessages$: Observable<Action> = this._actions.pipe(
    ofType(MessageActions.LOAD_MORE_PINNED_MESSAGES),
    map((action: MessageActions.LoadMorePinnedMessages) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload).pipe(
        map(data => new MessageActions.LoadMorePinnedMessagesSuccess(data)),
        catchError(response => of(new MessageActions.LoadMorePinnedMessagesFailure(response)))
      );
    })
  );


  constructor(private _actions: Actions, private _service: MessageService) {
  }
}

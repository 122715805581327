import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {forkJoin, Observable} from 'rxjs';
import {API_VERSION, API_URL} from './config';

import { LocalStorageService } from 'ngx-webstorage';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class MessageService extends ApiService {
  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = API_URL + API_VERSION + 'messages?limit=10&offset=0';
    return this._http.get(url, { headers: this._getHeaders() });
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url, { headers: this._getHeaders() });
  }

  toggleRead(message: any, user: any): Observable<any> {
    const url = API_URL + API_VERSION + 'messages/' + message.id + '/read';

    return this._http.post(url, JSON.stringify({ read: user.id }), { headers: this._getHeaders() });
  }

  toggleReadAll(): Observable<any> {
    const url = API_URL + API_VERSION + 'messages/read-all';

    return this._http.post(url, null, { headers: this._getHeaders() });
  }

  publish(message: string): Observable<any> {
    return this._http.post(API_URL + API_VERSION + 'messages', JSON.stringify(message), { headers: this._getHeaders() });
  }

  bulkPublish(message: any, owners: any[]): Observable<any> {
    const messages$ = owners.map(owner => this.publish(Object.assign({}, message, {owner_id: owner.id})));
    return forkJoin(messages$);
  }

  loadFeedByOwner(id: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}messages/owner/${id}`;

    return this._http.get(url, { headers: this._getHeaders() });
  }

  deleteMessage(message: any): Observable<any> {
    const url = API_URL + API_VERSION  + 'messages/' + message.id;
    return this._http.delete(url, { headers: this._getHeaders() }).pipe(map(res => message));
  }

  update(message: any): Observable<any> {
    const url = API_URL + API_VERSION  + 'messages/' + message.id;

    return this._http.patch(url, JSON.stringify({ text: message.text, priority: message.priority, pinned: message.pinned }), { headers: this._getHeaders() });
  }

  comment(comment: any): Observable<any> {
    return this._http.post(`${API_URL}${API_VERSION}comments`, JSON.stringify(comment), { headers: this._getHeaders() });
  }

  deleteComment(comment: any): Observable<any> {
    return this._http.delete(`${API_URL}${API_VERSION}comments/${comment.id}`, { headers: this._getHeaders() })
      .pipe(map(_ => comment));
  }

  updateComment(comment: any): Observable<any> {
    return this._http.patch(`${API_URL}${API_VERSION}comments/${comment.id}`, JSON.stringify({ text: comment.text }), { headers: this._getHeaders() });
  }

  loadById(id: string): Observable<any> {
    const url = API_URL + API_VERSION + 'messages/' + id;
    return this._http.get(url, { headers: this._getHeaders() })
      .pipe(switchMap(message => this._http.get(`${url}/comments`, {headers: this._getHeaders()})
        .pipe(map(comments => Object.assign({}, message, {comments: comments})))
      ));
  }

  loadUnreadMessages(): Observable<any> {
    const url = API_URL + API_VERSION + 'messages/unread?limit=100';
    return this._http.get(url, {headers: this._getHeaders() });
  }

  loadPinnedMessages(): Observable<any> {
    const url = `${API_URL}${API_VERSION}messages/pinned`;

    return this._http.get(url, {headers: this._getHeaders() });
  }

}

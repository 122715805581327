import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import { LocalStorageService } from 'ngx-webstorage';



import {HttpClient} from '@angular/common/http';
import {API_URL, API_VERSION} from './config';

@Injectable()
export class ServiceReceiverService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorageService) {
    super(_ls);
  }

  search(search: string): Observable<any> {
    const url = (search) ?  `${API_URL}${API_VERSION}users/service-receivers?name=${search}` :
      `${API_URL}${API_VERSION}users/service-receivers`;
    return this._http.get(url, { headers: this._getHeaders() });
  }
}

import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from './config';
import {ApiService} from './api.service';
import {LocalStorageService} from 'ngx-webstorage';
import {forkJoin, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class InstitutionService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions?limit=10`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadById(id: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${id}`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadManager(institutionId: string, userId: string): Observable<any> {
    const managerUrl = `${API_URL}${API_VERSION}users/${userId}`;
    return this._http.get(managerUrl, {headers: this._getHeaders()})
      .pipe(
        map((user) => ({institutionId: institutionId, manager: user}))
      );
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url, {headers: this._getHeaders()});
  }

  search(name: string): Observable<any> {
    if (name) {
      const url = `${API_URL}${API_VERSION}institutions/?name=${name}`;
      return this._http.get(url, {headers: this._getHeaders()});
    } else {
      const url = `${API_URL}${API_VERSION}institutions?limit=10`;
      return this._http.get(url, {headers: this._getHeaders()});
    }
  }

  loadEmployees(institutionId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${institutionId}/employees`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(map(result => ({result: result, institutionId: institutionId})));
  }

  loadServiceReceivers(institutionId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${institutionId}/service-receivers`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(map(result => ({result: result, institutionId: institutionId})));
  }

  loadChildren(urls: string[]): Observable<any> {
    const observables$ = urls.map(url => this._http.get(url, {headers: this._getHeaders()}));
    return forkJoin(observables$)
      .pipe(map((res: any) => ({parent: res[0].parent, children: res})));
  }

  createChildren(childInstitution: any, address: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/`;
    const addressUrl = `${API_URL}${API_VERSION}addresses/`;
    return this._http.post(addressUrl, JSON.stringify(address), {headers: this._getHeaders()})
      .pipe(
        switchMap(newAddress => {
          childInstitution.address = newAddress;
          return this._http.post(url, JSON.stringify(childInstitution), {headers: this._getHeaders()});
        })
      );
  }

  addEmployee(institutionId: string, employeeId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${institutionId}/employees`;
    return this._http.post(url, JSON.stringify({user_id: employeeId}), {headers: this._getHeaders()})
      .pipe(map(result => ({institutionId: institutionId, employees: result})));
  }

  removeEmployee(institutionId: string, employeeId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${institutionId}/employees/${employeeId}`;
    return this._http.delete(url, {headers: this._getHeaders()})
      .pipe(map(result => ({institutionId: institutionId, employees: result})));
  }

  addServiceReceiver(institutionId: string, serviceReceiverId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${institutionId}/service-receivers`;
    return this._http.post(url, JSON.stringify({user_id: serviceReceiverId}), {headers: this._getHeaders()})
      .pipe(map(result => ({institutionId: institutionId, serviceReceivers: result})));
  }

  removeServiceReceiver(institutionId: string, serviceReceiverId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}institutions/${institutionId}/service-receivers/${serviceReceiverId}`;
    return this._http.delete(url, {headers: this._getHeaders()})
      .pipe(map(result => ({institutionId: institutionId, serviceReceivers: result})));
  }

  loadByUserId(userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}/institutions`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(map(result => ({userId: userId, result: result})));
  }

}

import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from './config';
import {ApiService} from './api.service';
import {LocalStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ImageService} from './image.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class UserService extends ApiService {

  constructor(protected _ls: LocalStorageService,
              private _http: HttpClient, private _image: ImageService) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${API_URL}${API_VERSION}users?limit=20`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadServiceReceivers(): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/service-receivers?limit=20`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadRelatives(): Observable<any> {
    const url = API_URL + API_VERSION + 'users/relatives?limit=20';
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadEmployees(): Observable<any> {
    const url = API_URL + API_VERSION + 'users/employees?limit=20';
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadMore(url: string) {
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadSettings(userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}/settings`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  saveSettings(settings: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${settings.user_id}/settings`;
    return this._http.patch(url, JSON.stringify(settings), {headers: this._getHeaders()});
  }

  update(user: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${user.id}`;

    const accountInfo = Object.assign({}, {
      first_name: user.first_name || null,
      last_name: user.last_name || null,
      email: user.email || null,
    });

    const profile = Object.assign({}, {
      description: user.userprofile.description || null,
      cell_phone_number: user.userprofile.cell_phone_number || null,
      job_title: user.userprofile.job_title || null,
      date_of_birth: user.userprofile.date_of_birth || null
    });

    return this._http.patch(url, JSON.stringify(accountInfo), {headers: this._getHeaders()})
      .pipe(
        switchMap(updatedUser =>
          this._http.patch(`${url}/profile`, JSON.stringify(profile), {headers: this._getHeaders()})
            .pipe(map(updatedProfile => Object.assign({}, updatedUser, {userprofile: updatedProfile})))
        ));
  }

  updateProfileImage(userId: string, image: File): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}/profile`;

    return this._image.uploadImage(image, 1)
      .pipe(
        map(uploadedImage => uploadedImage.id),
        switchMap(imageId => this._http.patch(url, {image_id: imageId}, {headers: this._getHeaders()})),
        map(_ => image)
      );
  }

  loadByUrl(userUrl: string): Observable<any> {
    return this._http.get(userUrl, {headers: this._getHeaders()});
  }

  loadById(userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(
        switchMap(user => this._http.get(`${url}/profile`, {headers: this._getHeaders()})
          .pipe(map(profile => Object.assign({}, user, {userprofile: profile})))
        ));
  }

  loadProfile(userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}/profile`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  search(search: string): Observable<any> {
    let url = `${API_URL}${API_VERSION}users`;
    if (search) {
      url += `?name=${search}`;
    }
    return this._http.get(url, {headers: this._getHeaders()});
  }

  createUserInfo(userUrl: string, description: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}user-info/`;
    const body = {user: userUrl, description: description};
    return this._http.post(url, JSON.stringify(body), {headers: this._getHeaders()});
  }

  create(user: any, institution: any, relation: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}account/users/create-user/`;
    const authData = {email: user.email, password: user.password};

    if (user.is_employee) {
      return this._createEmployee(user, institution);
    } else if (user.is_service_receiver) {
      return this._createServiceReceiver(user, institution);
    } else {
      return this._http.post(url, JSON.stringify(authData), {headers: this._getHeaders()})
        .pipe(
          switchMap((createdUser: any) =>
            this._http.patch(createdUser.url, JSON.stringify(user), {headers: this._getHeaders()})),
          switchMap((updatedUser: any) =>
            this._http.post(`${API_URL}${API_VERSION}relations/`, JSON.stringify(Object.assign({}, relation, {grantee: updatedUser.url})), {headers: this._getHeaders()})),
          switchMap(_ =>
            this._http.post(`${API_URL}${API_VERSION}account/reset-password/`, JSON.stringify({email: user.email}), {headers: this._getHeaders()}))
        );
    }
  }

  changePassword(userId: string, password: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}/password`;
    return this._http.patch(url, JSON.stringify({password: password}), {headers: this._getHeaders()});
  }

  private _createEmployee(user: any, institution: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}account/users/create-user/`;
    const authData = {email: user.email, password: user.password};

    return this._http.post(url, JSON.stringify(authData), {headers: this._getHeaders()})
      .pipe(
        switchMap((createdUser: any) =>
          this._http.patch(createdUser.url, JSON.stringify(user), {headers: this._getHeaders()})),
        switchMap((updatedUser: any) => {
          const employees = institution.employees.concat(updatedUser.url);
          return this._http.patch(institution.url, JSON.stringify({employees: employees}), {headers: this._getHeaders()});
        }),
        switchMap(_ =>
          this._http.post(`${API_URL}${API_VERSION}account/reset-password/`, JSON.stringify({email: user.email}), {headers: this._getHeaders()}))
      );
  }

  private _createServiceReceiver(user: any, institution: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}account/users/create-user/`;
    const authData = {email: user.email, password: user.password};
    let userUrl: string;

    return this._http.post(url, JSON.stringify(authData), {headers: this._getHeaders()})
      .pipe(
        switchMap((createdUser: any) => this._http.patch(createdUser.url, JSON.stringify(user), {headers: this._getHeaders()})),
        switchMap((updatedUser: any) => {
          userUrl = updatedUser.url;
          const userInfo = {user: userUrl, description: 'feed'};
          return this._http.post(`${API_URL}${API_VERSION}user-info/`, JSON.stringify(userInfo), {headers: this._getHeaders()});
        }),
        switchMap(_ => {
          const userInfo = {user: userUrl, description: 'messages'};
          return this._http.post(`${API_URL}${API_VERSION}user-info/`, JSON.stringify(userInfo), {headers: this._getHeaders()});
        }),
        switchMap(_ => {
          const users = institution.users.concat(userUrl);
          return this._http.patch(institution.url, JSON.stringify({users: users}), {headers: this._getHeaders()});
        }),
        switchMap(_ =>
          this._http.post(`${API_URL}${API_VERSION}account/reset-password/`, JSON.stringify({email: user.email}), {headers: this._getHeaders()}))
      );
  }

}

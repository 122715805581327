import {Action} from '@ngrx/store';

export const SET_TITLE = '[NavBarButton] set title';
export const RAISE_EVENT = '[NavBarButton] raise event';
export const RESET_EVENT = '[NavBarButton] reset event';
export const RESET = '[NavBarButton] reset';

export class SetTitle implements Action {
  readonly type = SET_TITLE;

  constructor(public payload: string) {}
}

export class RaiseEvent implements Action {
  readonly type = RAISE_EVENT;

  constructor(public payload: string) {}
}

export class ResetEvent implements Action {
  readonly type = RESET_EVENT;

  constructor(public payload?: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export type All = SetTitle | RaiseEvent | ResetEvent | Reset;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import * as EmployeeActions from './employee.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class EmployeeEffects {

  @Effect() loadEmployees$: Observable<Action> = this._actions.pipe(
    ofType(EmployeeActions.LOAD),
    switchMap(_ => {
      return this._userService.loadEmployees().pipe(
        map(data => new EmployeeActions.LoadSuccess(data)),
        catchError(response => of(new EmployeeActions.LoadFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _userService: UserService) {}
}

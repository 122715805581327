import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as MemberActions from './member.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {GroupService} from '../../core/group.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class MemberEffects {

  @Effect() loadById$: Observable<Action> = this._actions.pipe(
    ofType(MemberActions.LOAD_BY_ID),
    map((action: MemberActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._groupService.loadMemberByGroupId(payload).pipe(
        map(data => new MemberActions.LoadByIdSuccess(data)),
        catchError(response => of(new MemberActions.LoadByIdFailure(response)))
      );
    })
  );

  @Effect() removeMember$: Observable<Action> = this._actions.pipe(
    ofType(MemberActions.REMOVE_MEMBER),
    map((action: MemberActions.RemoveMember) => action.payload),
    switchMap(payload => {
      return this._groupService.removeMember(payload.group, payload.memberId).pipe(
        map(data => new MemberActions.RemoveMemberSuccess(data)),
        catchError(response => of(new MemberActions.RemoveMemberFailure(response)))
      );
    })
  );

  @Effect() addMember$: Observable<Action> = this._actions.pipe(
    ofType(MemberActions.ADD_MEMBER),
    map((action: MemberActions.AddMember) => action.payload),
    switchMap(payload => {
      return this._groupService.addMember(payload.groupId, payload.memberId).pipe(
        map(data => new MemberActions.AddMemberSuccess(data)),
        catchError(response => of(new MemberActions.AddMemberFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _groupService: GroupService) {
  }
}

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LocalStorageService} from 'ngx-webstorage';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL, API_VERSION} from './config';

@Injectable()
export class DialogService extends ApiService {
  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  loadDialog(): Observable<any> {
    const url = `${API_URL}${API_VERSION}dialog`;
    return this._http.get(url, {headers: this._getHeaders() });
  }

  loadRecipients(): Observable<any> {
    const url = `${API_URL}${API_VERSION}dialog/recipients`;
    return this._http.get(url, {headers: this._getHeaders() });
  }

  createDialogMessage(data: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}dialog`;

    return this._http.post(url, JSON.stringify(data), {headers: this._getHeaders() });
  }

  loadDialogTread(id: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}dialog/thread/${id}?limit=40&offset=0`;

    return this._http.get(url, {headers: this._getHeaders() });
  }

  loadMoreDialogTread(url: string): Observable<any> {
    return this._http.get(url, {headers: this._getHeaders() });
  }
}

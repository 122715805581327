import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../core/auth.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as CreateServiceReceiverActions from './create-service-receiver.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class CreateServiceReceiverEffects {

  @Effect() createSubscription$: Observable<Action> = this.actions$.pipe(
    ofType(CreateServiceReceiverActions.CREATE_SUBSCRIPTION),
    map((action: CreateServiceReceiverActions.CreateSubscription) => action.payload),
    switchMap(payload => {
      return this._service.createSubscription(payload.serviceReceiver, payload.cardToken).pipe(
        map(data => new CreateServiceReceiverActions.CreateSubscriptionSuccess(data)),
        catchError(error => of(new CreateServiceReceiverActions.CreateSubscriptionFailure(error)))
      );
    })
  );

  constructor(private actions$: Actions, private _service: AuthService) {}
}

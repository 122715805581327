import {Action} from '@ngrx/store';

export const LOAD_BY_ID = '[Members] load';
export const LOAD_BY_ID_SUCCESS = '[Members] load success';
export const LOAD_BY_ID_FAILURE = '[Members] load failure';
export const REMOVE_MEMBER = '[Members] remove';
export const REMOVE_MEMBER_SUCCESS = '[Members] remove success';
export const REMOVE_MEMBER_FAILURE = '[Members] remove failure';
export const ADD_MEMBER = '[Members] add';
export const ADD_MEMBER_SUCCESS = '[Members] add success';
export const ADD_MEMBER_FAILURE = '[Members] add failure';

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: any) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveMember implements Action {
  readonly type = REMOVE_MEMBER;

  constructor(public payload: any) {}
}

export class RemoveMemberSuccess implements Action {
  readonly type = REMOVE_MEMBER_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveMemberFailure implements Action {
  readonly type = REMOVE_MEMBER_FAILURE;

  constructor(public payload: string) {}
}

export class AddMember implements Action {
  readonly type = ADD_MEMBER;

  constructor(public payload: any) {}
}

export class AddMemberSuccess implements Action {
  readonly type = ADD_MEMBER_SUCCESS;

  constructor(public payload: any) {}
}

export class AddMemberFailure implements Action {
  readonly type = ADD_MEMBER_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadById | LoadByIdSuccess | LoadByIdFailure | RemoveMember | RemoveMemberSuccess | RemoveMemberFailure |
  AddMember | AddMemberSuccess | AddMemberFailure;

import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from './config';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {LocalStorageService} from 'ngx-webstorage';


import {forkJoin, Observable} from 'rxjs';
import * as Moment from 'moment';
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);
import {HelperService} from './helper.service';
import {ImageService} from './image.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class CalendarService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient, private _helpers: HelperService,
              private _image: ImageService) {
    super(_ls);
  }

  loadByOwner(ownerId: any, start: string, end: string): Observable<any> {
    const startDate: string = moment(start).startOf('week').startOf('day').format('YYYY-MM-DD');
    const endDate: string = moment(end).endOf('week').endOf('day').format('YYYY-MM-DD');
    const url = `${API_URL}${API_VERSION}events/owner/${ownerId}?start=${startDate}&end=${endDate}`;
    const events$ = this._http.get(url, {headers: this._getHeaders()}).pipe(map((response: any) => response.results));
    return events$.pipe(
      map(events => {
        const occurrences = [];
        events.map(event => {
          if (event.occurrences !== null) {
            event.occurrences.map(occurrence => {
              occurrences.push(this._prepareOcc(event, occurrence));
            });
          } else {
            occurrences.push(Object.assign(this._prepare(event)));
          }
        });
        return occurrences;
      }));
  }

  bulkPublish(event: any, image: File, targets: any[], isRecurring: boolean): Observable<any> {
    const posts$ = targets.map(target => this.publish(Object.assign({}, event, {owner_id: target.id}), image, isRecurring));
    return forkJoin(posts$);
  }

  publish(event: any, image: any, isRecurring: boolean): Observable<any> {
    const url = `${API_URL}${API_VERSION}events`;

    if (!isRecurring) {
      delete event.options;
    }

    if (image.imageFile !== undefined) {
      const imageUpload$ = this._image.uploadImage(image.imageFile, image.orientation);
      return imageUpload$.pipe(
        map(upLoadedImage => upLoadedImage.id),
        switchMap(imageId => {
          return this._http.post(url, JSON.stringify(Object.assign(event, {image_id: imageId})), {headers: this._getHeaders()});
        })
      );
    } else {
      return this._http.post(url, JSON.stringify(event), {headers: this._getHeaders()});
    }
  }

  loadEventById(eventId: string, start: string, end: string): Observable<any> {
    const startDate: string = moment(start).startOf('week').startOf('day').format('YYYY-MM-DD');
    const endDate: string = moment(end).endOf('week').endOf('day').format('YYYY-MM-DD');

    const url = `${API_URL}${API_VERSION}events/${eventId}?start=${startDate}&end=${endDate}`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(
        map((event: any) => {
          const occurrences = [];
          if (event.occurrences !== null) {
            event.occurrences.map(occurrence => {
              occurrences.push(this._prepareOcc(event, occurrence));
            });
          } else {
            occurrences.push(Object.assign(this._prepare(event)));
          }
          return occurrences;
        }));
  }

  deleteEvent(occurrence: any): Observable<any> {
    let id;

    if (occurrence.id && occurrence.event_id) {
      id = occurrence.event_id;
    } else if (occurrence.id) {
      id = occurrence.id;
    } else {
      id = occurrence.event_id;
    }

    const deleteUrl = `${API_URL}${API_VERSION}events/${id}`;
    return this._http.delete(deleteUrl, {headers: this._getHeaders()});
  }

  update(event: any, deleteImage: any, image: any[], editMode: string, isRecurring: boolean): Observable<any> {
    if (deleteImage !== undefined) {
      this._image.remove(deleteImage[0]);
    }
    if (isRecurring) {
      if (image.length > 0) {
        const imageUpload$ = this._image.uploadImage(image[0]);
        return imageUpload$.pipe(
          map((uploadedImage: any) => uploadedImage.id),
          switchMap(imageId => {
            if (editMode === 'SINGLE') {
              if (event.id === null) {
                return this._createOccurrence(event);
              }
            } else if (editMode === 'ALL') {
              const updateOccUrl = `${API_URL}${API_VERSION}events/${event.event_id}`;
              const preparedEvent = this._prepareEvent(event, isRecurring, imageId);
              return this._http.patch(updateOccUrl, JSON.stringify(preparedEvent), {headers: this._getHeaders()});
            } else if (editMode === 'EVENT') {
              const updateEventUrl = `${API_URL}${API_VERSION}events/${event.id}`;
              const preparedEvent = this._prepareEvent(event, isRecurring, imageId);
              return this._http.patch(updateEventUrl, JSON.stringify(preparedEvent), {headers: this._getHeaders()});
            }
          }));
      } else {
        if (editMode === 'SINGLE') {
          if (event.id === null) {
            return this._createOccurrence(event);
          }
        } else if (editMode === 'ALL') {
          const updateOccUrl = `${API_URL}${API_VERSION}events/${event.event_id}`;
          const preparedEvent = this._prepareEvent(event, isRecurring);
          return this._http.patch(updateOccUrl, JSON.stringify(preparedEvent), {headers: this._getHeaders()});
        } else if (editMode === 'EVENT') {
          const updateEventUrl = `${API_URL}${API_VERSION}events/${event.id}`;
          const preparedEvent = this._prepareEvent(event, isRecurring);
          return this._http.patch(updateEventUrl, JSON.stringify(preparedEvent), {headers: this._getHeaders()});
        }
      }
    } else {
      if (image.length > 0) {
        const imageUpload$ = this._image.uploadImage(image[0]);
        return imageUpload$.pipe(
          map((uploadedImage: any) => uploadedImage.id),
          switchMap(imageId => {
            const updateEventUrl = `${API_URL}${API_VERSION}events/${event.id}`;
            const preparedEvent = this._prepareEvent(event, isRecurring, imageId);
            return this._http.patch(updateEventUrl, JSON.stringify(preparedEvent), {headers: this._getHeaders()});
          }));
      } else {
        const updateEventUrl = `${API_URL}${API_VERSION}events/${event.id}`;
        const preparedEvent = this._prepareEvent(event, isRecurring);
        return this._http.patch(updateEventUrl, JSON.stringify(preparedEvent), {headers: this._getHeaders()});
      }
    }
  }

  cancel(occurrence: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}events/${occurrence.event_id}/occurrences`;
    const updateBody = {title: occurrence.title, start: occurrence.start, end: occurrence.end, is_cancelled: true};

    return this._http.post(url, updateBody, {headers: this._getHeaders()});
  }

  loadCommentByEventId(id: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}events/${id}/comments`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(map((result: any) => ({result: result, parent_id: id})));
  }

  loadCommentByOccurrenceId(event_id: any, id: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}events/${event_id}/occurrences/${id}/comments`;
    return this._http.get(url, {headers: this._getHeaders()})
      .pipe(map((result: any) => ({result: result, parent_id: id})));
  }

  comment(comment: any, occurrence: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}comments/`;
    if (occurrence.is_recurring) {
      if (occurrence.id === null) {
        return this._createOccurrence(occurrence)
          .pipe(
            switchMap(newOccurrence => {
              return this._http.post(url, JSON.stringify({text: comment, parent_id: newOccurrence.id}), {headers: this._getHeaders()})
                .pipe(map(newComment => ({comment: newComment, occurrence: occurrence})));
            }));
      } else {
        return this._http.post(url, JSON.stringify({text: comment, parent_id: occurrence.id}), {headers: this._getHeaders()})
          .pipe(map(newComment => ({comment: newComment, occurrence: occurrence})));
      }
    } else {
      return this._http.post(url, JSON.stringify({text: comment, parent_id: occurrence.id}), {headers: this._getHeaders()})
        .pipe(map(newComment => ({comment: newComment, occurrence: occurrence})));
    }
  }

  deleteComment(comment: any): Observable<any> {
    return this._http.delete(`${API_URL}${API_VERSION}comments/${comment.id}`, {headers: this._getHeaders()}).pipe(map(_ => comment));
  }

  updateComment(comment: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}comments/${comment.id}`;
    return this._http.patch(url, {text: comment.text}, {headers: this._getHeaders()})
      .pipe(map(_ => comment));
  }

  private _prepareOcc(event: any, occurrence: any): any {
    occurrence.owner = event.owner;
    occurrence.creator = event.creator;
    occurrence.images = event.images;
    occurrence.duration = event.duration;
    occurrence.height = this._calcOccurrenceHeight(event);
    occurrence.has_passed = moment().valueOf() > moment(occurrence.start).valueOf();
    occurrence.is_recurring = true;
    occurrence.options = event.options;
    occurrence.description = event.description;
    occurrence.is_all_day = event.is_all_day;
    occurrence.natural_language = event.natural_language;

    return occurrence;
  }

  private _prepare(event: any): any {
    event.height = this._calcOccurrenceHeight(event);
    event.has_passed = moment().valueOf() > moment(event.start).valueOf();

    return event;
  }

  private _calcOccurrenceHeight(occurrence: any): string {
    let height: number;
    const diff = moment(occurrence.end).diff(occurrence.start);
    const duration = moment.duration(diff);

    if (occurrence.is_all_day || duration._days > 0) {
      height = 8;
    } else if (occurrence.duration.hours < 1) {
      height = 4;
    } else {
      height = occurrence.duration.hours * 8;
    }

    return height + 'rem';
  }

  private _prepareEvent(event: any, isRecurring: boolean, imageId?: string): any {
    let preparedEvent;
    if (isRecurring) {
      if (imageId) {
        preparedEvent = {
          title: event.title,
          start: event.start,
          end: event.end,
          image_id: imageId,
          options: {
            frequency: event.options.frequency,
            end_recurring_date: event.options.end_recurring_date,
            interval: event.options.interval,
            weekdays: event.options.weekdays
          }
        };
      } else {
        preparedEvent = {
          title: event.title,
          start: event.start,
          end: event.end,
          options: {
            frequency: event.options.frequency,
            end_recurring_date: event.options.end_recurring_date,
            interval: event.options.interval,
            weekdays: event.options.weekdays
          }
        };
      }
    } else {
      if (imageId) {
        preparedEvent = {title: event.title, start: event.start, end: event.end, image_id: imageId};
      } else {
        preparedEvent = {title: event.title, start: event.start, end: event.end};
      }
    }
    return preparedEvent;
  }

  private _createOccurrence(occurrence: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}events/${occurrence.event_id}/occurrences`;
    const body = {title: occurrence.title, start: occurrence.start, end: occurrence.end};
    return this._http.post(url, JSON.stringify(body), {headers: this._getHeaders()});
  }
}

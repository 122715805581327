import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {API_VERSION, API_URL} from './config';
import {Observable} from 'rxjs';

import { LocalStorageService } from 'ngx-webstorage';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class RelationService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorageService) {
    super(_ls);
  }

  loadByUser(id: string): Observable<any> {
    return this._http.get(`${API_URL}${API_VERSION}users/${id}/relations`, { headers: this._getHeaders() })
      .pipe(map(result => Object.assign({}, {user_id: id, relations: result})));
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './core/auth.service';
import {Router, NavigationStart} from '@angular/router';
import {Store, select} from '@ngrx/store';
import * as rootState from './store';
import {Observable, Subscription, Subject, of, combineLatest} from 'rxjs';
import * as ServiceReceiverActions from './store/service-receiver/service-receiver.actions';
import {NavService} from './nav/nav.service';
import * as AuthActions from './store/auth/auth.actions';
import {AuthenticationState} from './enums';
import * as EntryActions from './store/entry/entry.actions';
import * as MessageActions from './store/message/message.actions';
import {LOGOUT_URL} from './core/config';
import {map} from 'rxjs/operators';
import {merge} from 'rxjs/internal/observable/merge';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {NotifierService} from 'angular-notifier';
import {MainScroll} from './store/ui/ui.action';

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  hasTab: boolean;
  hasCommentForm: boolean;
  isConnectedToWifi$: Observable<boolean>;
  currentUrl: string;
  entryNextUrl: any;
  messageNextUrl: any;
  language: any;
  loggedInUser: any;

  private _redirectRoutes: string[] = ['', 'login', 'eula-accept'];
  private _currentUrl$: Subject<string> = new Subject();
  private _authSubscription: Subscription;
  private _routerEventSubscription: Subscription;
  private _updateSubscription: Subscription;
  private _entryNextUrlSubscription: Subscription;
  private _messageNextUrlSubscription: Subscription;

  private readonly notifier: NotifierService;

  constructor(private _translate: TranslateService, private _router: Router,
              private _navService: NavService, private _store: Store<rootState.IAppState>, private _auth: AuthService,
              private _angularFireMessaging: AngularFireMessaging, private _notifierService: NotifierService) {
    this.notifier = _notifierService;
  }

  ngOnInit(): void {
    this._setLanguage();
    this._handleWifi();

    this._entryNextUrlSubscription = this._store.pipe(select(rootState.getEntryNextUrl))
      .pipe(map(nextUrl => this.entryNextUrl = nextUrl))
      .subscribe();

    this._messageNextUrlSubscription = this._store.pipe(select(rootState.getMessagesNextUrl))
      .pipe(map(nextUrl => this.messageNextUrl = nextUrl))
      .subscribe();

    this._routerEventSubscription = this._router.events.pipe(
      map(event => this._setCurrentUrl(event))
    ).subscribe();

    this._handleAuthStateChanges();

    this._store.dispatch(new AuthActions.CheckAuth());
  }

  onRouteChanged(data: any) {
    this.hasTab = !data.isRoot;

    const isMessageDetail = data.url.indexOf('message-detail');
    const isEntryDetail = data.url.indexOf('entry-detail');
    const isEventDetail = data.url.indexOf('event-detail');
    this.hasCommentForm = isMessageDetail || isEntryDetail || isEventDetail;
  }

  mainWasClicked(): void {
    this._navService.navEvents$.next('MAIN_CLICKED');
  }

  scrolling(event): void {
    this._store.dispatch(new MainScroll(event));

    // TODO: Move this to their components
    const scrollH = event.srcElement.scrollHeight;
    const scrollT = event.srcElement.scrollTop;
    const clientH = event.srcElement.clientHeight;

    if (this.currentUrl === '/home') {
      if (scrollH === Math.floor(scrollT + clientH)) {
        if (this.entryNextUrl) {
          this._store.dispatch(new EntryActions.LoadMore(this.entryNextUrl));
        }
      }
    }
    if (this.currentUrl === '/message') {
      if (scrollH === scrollT + clientH) {
        if (this.messageNextUrl) {
          this._store.dispatch(new MessageActions.LoadMore(this.messageNextUrl));
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this._authSubscription) {
      this._authSubscription.unsubscribe();
    }

    if (this._updateSubscription) {
      this._updateSubscription.unsubscribe();
    }

    if (this._routerEventSubscription) {
      this._routerEventSubscription.unsubscribe();
    }

    if (this._entryNextUrlSubscription) {
      this._entryNextUrlSubscription.unsubscribe();
    }

    if (this._messageNextUrlSubscription) {
      this._messageNextUrlSubscription.unsubscribe();
    }
  }

  private _handleAuthStateChanges(): void {
    const authState$ = this._store.pipe(select(rootState.getAuthenticationState));
    const loggedInUser$ = this._store.pipe(select(rootState.getLoggedInUser));
    const isLoadingLoggedInUser$ = this._store.pipe(select(rootState.getAuthLoadingState));
    const serviceReceivers$ = this._store.pipe(select(rootState.getServiceReceivers));
    const isLoadingServiceReceivers$ = this._store.pipe(select(rootState.getServiceReceiversLoadingState));

    const values$ = combineLatest(loggedInUser$, isLoadingLoggedInUser$, this._currentUrl$, isLoadingServiceReceivers$, serviceReceivers$);

    this._authSubscription = combineLatest(authState$, values$)
      .pipe(
        map((values: any) => this._structureValues(values)),
        map((values: any) => {
          const {
            authState, loggedInUser, isLoadingLoggedInUser, currentUrl, isLoadingServiceReceivers, serviceReceivers
          } = values;

          if (authState !== null) {
            this.isAuthenticated = authState === AuthenticationState.AUTHENTICATED;

            if (authState === AuthenticationState.AUTHENTICATED) {
              this._handleUserIsAuthenticated(loggedInUser, isLoadingLoggedInUser, currentUrl, isLoadingServiceReceivers, serviceReceivers);
              this._store.dispatch(new MessageActions.LoadUnread());
            } else if (authState === AuthenticationState.UNAUTHENTICATED) {
              this._handleUserIsUnauthenticated(currentUrl);
            } else if (authState === AuthenticationState.UNAUTHENTICATING) {
              this._handleUserIsUnauthenticating();
            } else if (authState === AuthenticationState.TOKEN_EXPIRED) {
              this._store.dispatch(new AuthActions.UnAuthenticate());
            }
          }
        })
      ).subscribe();
  }

  private _handleUserIsAuthenticated(loggedInUser: any, isLoadingLoggedInUser: boolean,
                                     currentUrl: string, isLoadingServiceReceivers: boolean,
                                     serviceReceivers: any[]): void {
    if (!loggedInUser && !isLoadingLoggedInUser) {
      this._store.dispatch(new AuthActions.LoadAuthUser());
    } else if (loggedInUser) {
      this.loggedInUser = loggedInUser;

      if (loggedInUser.accepted_eula === null) {
        if (currentUrl !== 'eula-accept') {
          this._router.navigate(['eula-accept']).catch(error => console.error(error));
        }
      } else {
        const doRedirect = this._redirectRoutes.indexOf(currentUrl) !== -1;



        if (doRedirect) {
          if (loggedInUser.is_employee || loggedInUser.is_service_receiver) {
            this._router.navigate(['home']).catch(error => console.error(error));
          } else if (!loggedInUser.is_employee && !loggedInUser.is_service_receiver) {
            if (!isLoadingServiceReceivers && !serviceReceivers) {
              this._store.dispatch(new ServiceReceiverActions.Load());
            } else if (serviceReceivers) {
              if (serviceReceivers.length === 0) {
                this._router.navigate(['welcome']).catch(error => console.error(error));
              } else {
                this._router.navigate(['home']).catch(error => console.error(error));
              }
            }
          }
        } else {
          if (!isLoadingServiceReceivers && !serviceReceivers) {
            this._store.dispatch(new ServiceReceiverActions.Load());
          }
        }
      }
    }
  }

  private _handleUserIsUnauthenticated(currentUrl: string): void {
    const isLogin = currentUrl.indexOf('login') !== -1;

    if (!isLogin) {
      this._router.navigate(['login']).catch(error => console.log(error));
    }
  }

  private _handleUserIsUnauthenticating(): void {
    this._store.dispatch(new AuthActions.ClearAuth());
  }

  private _setLanguage(): void {
    this._translate.setDefaultLang('en');
    this._translate.use('en');
  }

  private _handleWifi(): void {
    this.isConnectedToWifi$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );
  }

  private _setCurrentUrl(event): any {
    if (event instanceof NavigationStart) {
      this.currentUrl = event.url;
      const path = event.url.split('/');
      this._currentUrl$.next(path[1]);
    }
  }

  private _structureValues(values: any[]): any {
    return ({
      authState: values[0],
      loggedInUser: values[1][0],
      isLoadingLoggedInUser: values[1][1],
      currentUrl: values[1][2],
      isLoadingServiceReceivers: values[1][3],
      serviceReceivers: values[1][4]
    });
  }
}

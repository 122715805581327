import * as SettingsActions from './settings.actions';

export type Action = SettingsActions.All;

export interface SettingsState {
  loading: boolean;
  entitiesById: any;
  error: string;
  message: string;
}

const initialState: SettingsState = {
  loading: null,
  entitiesById: null,
  error: null,
  message: null
};

export function reducer(state: SettingsState = initialState, {type, payload}: Action): SettingsState {
  switch (type) {
    case SettingsActions.LOAD_BY_USER:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case SettingsActions.LOAD_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, {
          [payload.user_id]: payload
        })
      });
    case SettingsActions.LOAD_BY_USER_FAILURE:
      return Object.assign({}, state, {
        error: payload
      });
    case SettingsActions.RESET:
      return Object.assign({}, state, initialState);
    case SettingsActions.RESET_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: SettingsState) => state.loading;
export const getEntitiesById = (state: SettingsState) => state.entitiesById;
export const getError = (state: SettingsState) => state.error;
export const getMessage = (state: SettingsState) => state.message;

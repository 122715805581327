import {Injectable} from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import {API_URL, API_VERSION, AUTH_TOKEN} from './config';
import { ApiService } from 'app/core/api.service';
import * as rootState from '../store';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {forkJoin, Observable, of} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {AuthenticationState} from '../enums';
import {map, switchMap} from 'rxjs/operators';
const jwtDecode = require('jwt-decode');

@Injectable()
export class AuthService extends ApiService {

  constructor(protected _ls: LocalStorageService,
              private _http: HttpClient,
              private _store: Store<rootState.IAppState>,
              private _user: UserService) {
    super(_ls);
  }

  authWithCredentials(email: string, password: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const body = {email: email, password: password};

    return this._http.post(`${API_URL}${API_VERSION}auth/service-receiver`, body, { headers: headers })
      .pipe(
        map((authData: any) => {
          this._ls.store(AUTH_TOKEN, authData.token);
          return authData;
        }),
        switchMap(_ => this.getLoggedInUser())
      );
  }

  authenticate(token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this._http.post(`${API_URL}${API_VERSION}auth`, JSON.stringify({}), { headers: headers })
      .pipe(
        map((authData: any) => {
        this._ls.store(AUTH_TOKEN, authData.token);
        return authData;
      }),
      switchMap(_ => this.getLoggedInUser()));
  }

  register(user: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const authUser = { email: user.username, password: user.password };

    return this._http.post(API_URL + API_VERSION + 'account/users/register', JSON.stringify(authUser), { headers: headers })
      .pipe(
        map((authData: any) => {
        this._ls.store(AUTH_TOKEN, authData.token);
        return authData;
      }),
      switchMap(authData => {
        const userData = {
          // eula_accepted: true,
          first_name: user.first_name,
          last_name: user.last_name,
          userprofile: {
            cell_phone_number: user.cell_phone_number,
            job_title: user.job_title
          },
          is_employee: user.is_employee
        };

        headers = headers.append('Authorization', 'Bearer ' + authData.token);

        return this._http.patch(authData.url, JSON.stringify(userData), { headers: headers });
      }));
  }

  createSubscription(serviceReceiver: any, cardToken: string): Observable<any> {
    let userUrl: string;
    const url = `${API_URL}${API_VERSION}account/users/stripe-id/`;
    return this._http.put(url, JSON.stringify({ source: cardToken }), { headers: this._getHeaders()})
      .pipe(
        switchMap(_ => this.createServiceReceiver(serviceReceiver)),
      switchMap(serviceReceiverId => {
        userUrl = `${API_URL}${API_VERSION}account/users/${serviceReceiverId}/`;
        return this._createStripeSubscription(serviceReceiverId, '3');
      }),
      switchMap(serviceReceiverId => this._user.createUserInfo(userUrl, 'feed')),
      switchMap(serviceReceiverId => this._user.createUserInfo(userUrl, 'messages')));
  }

  private _createStripeSubscription(serviceReceiverId: number, plan: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}account/subscriptions/`;
    return this._http.post(url, JSON.stringify({ service_receiver: serviceReceiverId, plan: plan }), { headers: this._getHeaders() });
  }

  createServiceReceiver(serviceReceiver: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}account/users/create-service-receiver`;

    return this._http.post(url, JSON.stringify({ email: serviceReceiver.email }), { headers: this._getHeaders() })
      .pipe(
        switchMap((authData: any) => {
        const userData = {
          first_name: serviceReceiver.first_name,
          last_name: serviceReceiver.last_name
        };

        const paths = authData.url.split('/');
        const userId = +paths[paths.length - 2];
        const passwordUrl = API_URL + API_VERSION + `account/users/${userId}/set-password`;

        const observables$ = [
          this._http.patch(passwordUrl, JSON.stringify({ password: serviceReceiver.password }), { headers: this._getHeaders() }),
          this._http.patch(authData.url, JSON.stringify(userData), { headers: this._getHeaders() })
        ];

        return forkJoin(observables$).pipe(map(res => userId));
      }));
  }

  getDecodedToken(): string {
    const token = this._ls.retrieve(AUTH_TOKEN);
    if (token) {
      return jwtDecode(token);
    } else {
      return null;
    }
  }

  getToken(): string {
    return this._ls.retrieve(AUTH_TOKEN);
  }

  getLoggedInUser() {
    const decodedToken = this.getDecodedToken();
    const userId = decodedToken['id'];
    const url = `${API_URL}${API_VERSION}users/${userId}`;
    const profileUrl = `${API_URL}${API_VERSION}users/${userId}/profile`;

    return this._http.get(url, { headers: this._getHeaders() })
      .pipe(
        switchMap(user =>
        this._http.get(profileUrl, { headers: this._getHeaders() })
          .pipe(map(userprofile => Object.assign({}, user, { userprofile: userprofile })))
      ));
  }

  forgotPassword(email: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(API_URL + API_VERSION + 'auth/reset-password', JSON.stringify({ email: email }), {headers: headers});
  }

  tokenHasExpired(token: string) {
    if (token) {
      const decodedToken = jwtDecode(token);
      const now = moment().valueOf();
      return moment.unix(decodedToken.exp).valueOf() < now;
    } else {
      this._ls.store(AUTH_TOKEN, null);
      return true;
    }
  }

  clearAuth(): Observable<any> {
    return of(this._ls.store(AUTH_TOKEN, null));
  }

  checkAuthentication(): Observable<AuthenticationState> {
    const token = this._ls.retrieve(AUTH_TOKEN);

    if (token) {
      if (this.tokenHasExpired(token)) {
        return of(AuthenticationState.TOKEN_EXPIRED);
      } else {
        return of(AuthenticationState.AUTHENTICATED);
      }
    } else {
      return of(AuthenticationState.UNAUTHENTICATED);
    }
  }

  sendConfirm(cellPhoneNumber: string, token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${token}`);

    const body = { phone_no: cellPhoneNumber };

    const url = `${API_URL}${API_VERSION}auth/send-confirm`;
    return this._http.post(url, JSON.stringify(body), { headers: headers });
  }

  confirmAccount(code: number, sub: string, token: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${token}`);

    const body = { code: code, sub: sub };

    const url = `${API_URL}${API_VERSION}auth/confirm-account`;
    return this._http.post(url, JSON.stringify(body), { headers: headers })
      .pipe(
        map((authData: any) => {
          this._ls.store(AUTH_TOKEN, authData.token);
          return authData;
        }),
        switchMap(_ => this.getLoggedInUser())
      );
  }

  setNewPassword(userId: string, password: string): Observable<any> {
    const url = API_URL + API_VERSION + `users/${userId}/password`;
    return this._http.patch(url, {password}, {headers: this._getHeaders()});
  }

}

import * as AuthActions from './auth.actions';
import {AuthenticationState} from '../../enums';
import {handleError} from '../helpers';

export type Action = AuthActions.All;

export interface AuthState {
  loading: boolean;
  loggedInUser: any;
  state: AuthenticationState;
  errorMessage: string;
  successMessage: string;
}

const initialState: AuthState = {
  loading: false,
  state: null,
  loggedInUser: null,
  errorMessage: null,
  successMessage: null
};

function handleConfirmError(payload) {
  if (payload.status === 404) {
    return 'NO_ACCOUNT_WITH_CELL_PHONE_NUMBER';
  } else {
    return handleError(payload);
  }
}

export function reducer(state: AuthState = initialState, {type, payload}: Action): AuthState {
  switch (type) {
    case AuthActions.AUTHENTICATE:
      return Object.assign({}, state, {
        loading: true,
        state: AuthenticationState.AUTHENTICATING,
        errorMessage: null,
        successMessage: null
      });
    case AuthActions.AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.AUTHENTICATED,
        loggedInUser: payload,
        successMessage: 'AUTH_SUCCESS'
      });
    case AuthActions.AUTHENTICATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.UNAUTHENTICATED,
        errorMessage: handleError(payload),
        successMessage: null
      });
    case AuthActions.AUTH_WITH_CREDENTIALS:
      return Object.assign({}, state, {
        loading: true,
        state: AuthenticationState.AUTHENTICATING,
        errorMessage: null,
        successMessage: null
      });
    case AuthActions.AUTH_WITH_CREDENTIALS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.AUTHENTICATED,
        loggedInUser: payload,
        successMessage: 'AUTH_SUCCESS'
      });
    case AuthActions.AUTH_WITH_CREDENTIALS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.UNAUTHENTICATED,
        errorMessage: handleError(payload),
        successMessage: null
      });
    case AuthActions.LOAD_AUTH_USER:
      return Object.assign({}, state, {
        loading: true,
        errorMessage: null,
        successMessage: null
      });
    case AuthActions.LOAD_AUTH_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loggedInUser: payload
      });
    case AuthActions.LOAD_AUTH_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorMessage: handleError(payload),
        successMessage: null
      });
    case AuthActions.UNAUTHENTICATE:
      return Object.assign({}, state, {
        loading: true,
        state: AuthenticationState.UNAUTHENTICATING
      });
    case AuthActions.CLEAR_AUTH:
      return Object.assign({}, state, {
        loading: false,
        loggedInUser: null,
        state: AuthenticationState.UNAUTHENTICATED,
      });
    case AuthActions.FORGOT_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
        errorMessage: null,
        successMessage: null
      });
    case AuthActions.FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        errorMessage: null,
        successMessage: 'RESET_PASSWORD_EMAIL_SENT'
      });
    case AuthActions.FORGOT_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorMessage: handleError(payload),
        successMessage: null
      });
    case AuthActions.UPDATE:
      return Object.assign({}, state, {
        loading: true
      });
    case AuthActions.UPDATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loggedInUser: payload,
        successMessage: 'UPDATE_SUCCESS'
      });
    case AuthActions.UPDATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AuthActions.CHECK_AUTH:
      return Object.assign({}, state, {
        loading: true
      });
    case AuthActions.CHECK_AUTH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: payload
      });
    case AuthActions.CHECK_AUTH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case AuthActions.SEND_CONFIRM:
      return Object.assign({}, state, {
        loading: true,
        errorMessage: null
      });
    case AuthActions.SEND_CONFIRM_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.CONFIRM_SENT,
        loggedInUser: payload,
        errorMessage: null,
        successMessage: 'CONFIRM_SENT'
      });
    case AuthActions.SEND_CONFIRM_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.UNAUTHENTICATED,
        errorMessage: handleConfirmError(payload),
        successMessage: null
      });
    case AuthActions.CONFIRM_ACCOUNT:
      return Object.assign({}, state, {
        loading: true,
        successMessage: null,
      });
    case AuthActions.CONFIRM_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.AUTHENTICATED,
        successMessage: null,
        loggedInUser: payload,
      });
    case AuthActions.CONFIRM_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        state: AuthenticationState.UNAUTHENTICATED,
        errorMessage: handleConfirmError(payload),
        successMessage: null
      });
    case AuthActions.SET_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
        errorMessage: null,
      });
    case AuthActions.SET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        successMessage: 'PASSWORD_SET'
      });
    case AuthActions.SET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errorMessage: handleError(payload),
      });
    default:
      return state;
  }
}

export const getAuthLoadingState = (state: AuthState) => state.loading;
export const getState = (state: AuthState) => state.state;
export const getLoggedInUser = (state: AuthState) => state.loggedInUser;
export const getAuthErrorMessage = (state: AuthState) => state.errorMessage;
export const getAuthSuccessMessage = (state: AuthState) => state.successMessage;

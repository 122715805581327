import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../core/auth.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as RegisterActions from '../register/register.actions';
import {UserService} from '../../core/user.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class AuthEffects {

  @Effect() authenticate$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.AUTHENTICATE),
    map((action: AuthActions.Authenticate) => action.payload),
    switchMap(payload => {
      return this._service.authenticate(payload).pipe(
        map(authData => new AuthActions.AuthenticateSuccess(authData)),
        catchError(error => of(new AuthActions.AuthenticateFailure(error)))
      );
    })
  );

  @Effect() authWithCredentials$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.AUTH_WITH_CREDENTIALS),
    map((action: AuthActions.AuthWithCredentials) => action.payload),
    switchMap(payload => {
      return this._service.authWithCredentials(payload.email, payload.password)
        .pipe(
          map(authData => new AuthActions.AuthWithCredentialsSuccess(authData)),
          catchError(error => of(new AuthActions.AuthWithCredentialsFailure(error)))
        );
    })
  );

  @Effect() checkAuth$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.CHECK_AUTH),
    switchMap(_ => {
      return this._service.checkAuthentication().pipe(
        map(state => new AuthActions.CheckAuthSuccess(state)),
        catchError(error => of(new AuthActions.CheckAuthFailure(error)))
      );
    })
  );

  @Effect() loadAuthUser$: Observable<Action> = this.actions$.pipe(
    ofType(AuthActions.LOAD_AUTH_USER),
    switchMap(_ => {
      return this._service.getLoggedInUser().pipe(
        map(authData => new AuthActions.LoadAuthUserSuccess(authData)),
        catchError(error => of(new AuthActions.UnAuthenticate()))
      );
    })
  );

  @Effect() registerSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(RegisterActions.REGISTER_SUCCESS),
    map((action: RegisterActions.RegisterSuccess) => action.payload),
    switchMap(_ => {
      return this._service.getLoggedInUser().pipe(
        map(authData => new AuthActions.LoadAuthUserSuccess(authData)),
        catchError(error => of(new AuthActions.LoadAuthUserFailure(error)))
      );
    })
  );

  @Effect() forgotPassword$ = this.actions$.pipe(
    ofType(AuthActions.FORGOT_PASSWORD),
    map((action: AuthActions.ForgotPassword) => action.payload),
    switchMap(payload => {
      return this._service.forgotPassword(payload).pipe(
        map(data => new AuthActions.ForgotPasswordSuccess()),
        catchError(response => of(new AuthActions.ForgotPasswordFailure(response)))
      );
    })
  );

  @Effect() unauth$ = this.actions$.pipe(
    ofType(AuthActions.UNAUTHENTICATE),
    switchMap(_ => {
      return this._service.clearAuth()
        .pipe(
          map(data => new AuthActions.UnAuthenticateSuccess()),
          catchError(response => of(new AuthActions.UnAuthenticateSuccess()))
      );
    })
  );

  @Effect() update$ = this.actions$.pipe(
    ofType(AuthActions.UPDATE),
    map((action: AuthActions.Update) => action.payload),
    switchMap(payload => {
      return this._userService.update(payload).pipe(
        map(data => new AuthActions.UpdateSuccess(data)),
        catchError(response => of(new AuthActions.UpdateFailure(response)))
      );
    })
  );

  @Effect() sendConfirm$ = this.actions$
    .pipe(
      ofType(AuthActions.SEND_CONFIRM),
      map((action: AuthActions.SendConfirm) => action.payload),
      switchMap(payload => {
        return this._service.sendConfirm(payload.cellPhoneNumber, payload.token)
          .pipe(
            map(data => new AuthActions.SendConfirmSuccess(data)),
            catchError(response => of(new AuthActions.SendConfirmFailure(response))),
          );
      })
    );

  @Effect() confirmAccount$ = this.actions$
    .pipe(
      ofType(AuthActions.CONFIRM_ACCOUNT),
      map((action: AuthActions.ConfirmAccount) => action.payload),
      switchMap(payload => {
        return this._service.confirmAccount(payload.code, payload.sub, payload.token)
          .pipe(
            map(data => new AuthActions.ConfirmAccountSuccess(data)),
            catchError(response => of(new AuthActions.ConfirmAccountFailure(response)))
          );
      })
    );

  @Effect() setPassword$ = this.actions$
    .pipe(
      ofType(AuthActions.SET_PASSWORD),
      map((action: AuthActions.SetPassword) => action.payload),
      switchMap(payload => {
        return this._service.setNewPassword(payload.userId, payload.password)
          .pipe(
            map(data => new AuthActions.SetPasswordSuccess(data)),
            catchError(response => of(new AuthActions.SetPasswordFailure(response)))
          );
      })
    );

  constructor(private actions$: Actions, private _service: AuthService, private _userService: UserService) {}
}

import {Routes} from '@angular/router';

export const appRoutes: Routes = [
  { path: 'login', loadChildren: '../login-credentials/login-credentials.module#LoginCredentialsModule' },
  { path: 'home', loadChildren: '../home/home.module#HomeModule' },
  { path: 'registration', loadChildren: '../registration/registration.module#RegistrationModule' },
  { path: 'welcome', loadChildren: '../welcome/welcome.module#WelcomeModule' },
  { path: 'create-service-receiver', loadChildren: '../create-service-receiver/create-service-receiver.module#CreateServiceReceiverModule' },
  { path: 'message', loadChildren: '../message/message.module#MessageModule' },
  { path: 'service-receiver', loadChildren: '../service-receiver/service-receiver.module#ServiceReceiverModule' },
  { path: 'service-receiver-detail', loadChildren: '../service-receiver-detail/service-receiver-detail.module#ServiceReceiverDetailModule' },
  { path: 'group', loadChildren: '../group/group.module#GroupModule' },
  { path: 'more', loadChildren: '../more/more.module#MoreModule' },
  { path: 'create-message', loadChildren: '../create-message/create-message.module#CreateMessageModule' },
  { path: 'create-entry', loadChildren: '../create-entry/create-entry.module#CreateEntryModule' },
  { path: 'create-event', loadChildren: '../create-event/create-event.module#CreateEventModule'},
  { path: 'message-detail', loadChildren: '../message-detail/message-detail.module#MessageDetailModule' },
  { path: 'edit-message', loadChildren: '../edit-message/edit-message.module#EditMessageModule' },
  { path: 'profile', loadChildren: '../profile-route/profile-route.module#ProfileRouteModule' },
  { path: 'entry-detail', loadChildren: '../entry-detail/entry-detail.module#EntryDetailModule' },
  { path: 'edit-entry', loadChildren: '../edit-entry/edit-entry.module#EditEntryModule' },
  { path: 'my-profile', loadChildren: '../my-profile/my-profile.module#MyProfileModule' },
  { path: 'event-detail', loadChildren: '../event-detail/event-detail.module#EventDetailModule'},
  { path: 'edit-event', loadChildren: '../edit-event/edit-event.module#EditEventModule'},
  { path: 'group-detail', loadChildren: '../group-detail/group-detail.module#GroupDetailModule'},
  { path: 'settings', loadChildren: '../settings/settings.module#SettingsModule'},
  { path: 'create-user', loadChildren: '../create-user/create-user.module#CreateUserModule' },
  { path: 'user', loadChildren: '../user/user.module#UserModule' },
  { path: 'calendar', loadChildren: '../calendar-route/calendar-route.module#CalendarRouteModule' },
  { path: 'create-group', loadChildren: '../create-group/create-group.module#CreateGroupModule' },
  { path: 'institution', loadChildren: '../institution/institution.module#InstitutionModule'},
  { path: 'institution-detail', loadChildren: '../institution-detail/institution-detail.module#InstitutionDetailModule'},
  { path: 'support', loadChildren: '../support/support.module#SupportModule'},
  { path: 'create-institution-child', loadChildren: '../create-institution-child/create-institution-child.module#CreateInstitutionChildModule'},
  { path: 'eula-accept', loadChildren: '../eula-accept/eula-accept.module#EulaAcceptModule'},
  { path: 'eula-review', loadChildren: '../eula-review/eula-review.module#EulaReviewModule'},
  { path: 'my-relations', loadChildren: '../my-relations/my-relations.module#MyRelationsModule'},
  { path: 'dialog-window', loadChildren: '../message/dialog-route/dialog-route.module#DialogRouteModule'},
  { path: 'set-password', loadChildren: '../set-password/set-password.module#SetPasswordModule'}
];

import {Action} from '@ngrx/store';

export const LOAD = '[Relative] loads';
export const LOAD_SUCCESS = '[Relative] load';
export const LOAD_FAILURE = '[Relative] load failed';
export const LOAD_MORE = '[Relative] loads';
export const LOAD_MORE_SUCCESS = '[Relative] load success';
export const LOAD_MORE_FAILURE = '[Relative] load failed';
export const LOAD_BY_ID = '[User] load by id';
export const LOAD_BY_ID_SUCCESS = '[User] load by id success';
export const LOAD_BY_ID_FAILURE = '[User] load by id failure';
export const RESET = '[Relative] reset';
export const SEARCH = '[ServiceReceiver] search';
export const SEARCH_SUCCESS = '[ServiceReceiver] search succes';
export const SEARCH_FAILURE = '[ServiceReceiver] search failed';
export const UPDATE = '[User] update';
export const UPDATE_FAILURE = '[User] update failure';
export const UPDATE_SUCCESS = '[User] update success';
export const UPDATE_PROFILE_IMAGE = '[User] update profile image';
export const UPDATE_PROFILE_IMAGE_SUCCESS = '[User] update profile image success';
export const UPDATE_PROFILE_IMAGE_FAILURE = '[User] update profile image failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload: string) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: string) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export class Search implements Action {
  readonly type = SEARCH;

  constructor(public payload: any) {}
}

export class SearchSuccess implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchFailure implements Action {
  readonly type = SEARCH_FAILURE;

  constructor(public payload: string) {}
}

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: any) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateProfileImage implements Action {
  readonly type = UPDATE_PROFILE_IMAGE;

  constructor(public payload: { userId: string, image: File }) {}
}

export class UpdateProfileImageSuccess implements Action {
  readonly type = UPDATE_PROFILE_IMAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateProfileImageFailure implements Action {
  readonly type = UPDATE_PROFILE_IMAGE_FAILURE;

  constructor(public payload: string) {}
}

export type All = Load | LoadSuccess | LoadFailure | LoadMore | LoadMoreSuccess | LoadMoreFailure | LoadById | LoadByIdSuccess |
  LoadByIdFailure | Reset | Search | SearchSuccess |
  SearchFailure | Update | UpdateSuccess | UpdateFailure | UpdateProfileImage | UpdateProfileImageSuccess | UpdateProfileImageFailure;

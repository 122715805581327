import { Injectable } from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Injectable()
export class HelperService {

  constructor(private _deviceService: DeviceDetectorService) {}

  randomString(length, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
    let result = '';

    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }

    return result;
  }

  isMobile(): boolean {
    return window.innerWidth <= 768;
  }

  isIpad(): boolean {
    return window.innerWidth <= 1024;
  }

  sortSuggestiveList(members: any[], users: any[]): any[] {
    const suggestiveList = [];
    if (members && users) {
      users.map((user, i) => {
        const index = members.findIndex(member => member.id === user.id);

        if (index === -1) {
          suggestiveList.push([i]);
        }
      });
      return suggestiveList;
    }
  }

  getVersion(): string {
    // Don't go beyond 2 decimals, versions should match milestone unless it's beta.
    return '3.1.1';
  }

  isIphone(): boolean {
    const deviceInfo = this._deviceService.getDeviceInfo();
    return deviceInfo.device === 'iphone';
  }

  isAndroid(): boolean {
    const deviceInfo = this._deviceService.getDeviceInfo();
    return deviceInfo.device === 'android';
  }

  getDeviceInfo(): any {
    return this._deviceService.getDeviceInfo();
  }

  isIos(): boolean {
    const deviceInfo = this._deviceService.getDeviceInfo();
    return deviceInfo.device === 'iphone' || deviceInfo.device === 'ipad';
  }

}

import {Action} from '@ngrx/store';

export const LOAD = '[ServiceReceiver] load service receivers';
export const LOAD_SUCCESS = '[ServiceReceiver] load service receivers success';
export const LOAD_FAILURE = '[ServiceReceiver] load service receivers failed';
export const LOAD_MORE = '[ServiceReceiver] load more service receivers';
export const LOAD_MORE_SUCCESS = '[ServiceReceiver] load more service receivers success';
export const LOAD_MORE_FAILURE = '[ServiceReceiver] load more service receivers failed';
export const LOAD_BY_ID = '[ServiceReceiver] load service receivers by id';
export const LOAD_BY_ID_SUCCESS = '[ServiceReceiver] load service receivers by id success';
export const LOAD_BY_ID_FAILURE = '[ServiceReceiver] load service receivers by id failed';
export const RESET = '[ServiceReceiver] reset';
export const SEARCH = '[ServiceReceiver] search';
export const SEARCH_SUCCESS = '[ServiceReceiver] search succes';
export const SEARCH_FAILURE = '[ServiceReceiver] search failed';
export const UPDATE = '[ServiceReceiver] update';
export const UPDATE_SUCCESS = '[ServiceReceiver] update success';
export const UPDATE_FAILURE = '[ServiceReceiver] update failure';
export const LOAD_PROFILE = '[ServiceReceivers] load profile';
export const LOAD_PROFILE_SUCCESS = '[ServiceReceivers] load profile success';
export const LOAD_PROFILE_FAILURE = '[ServiceReceivers] load profile failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload: string) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: any) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export class Search implements Action {
  readonly type = SEARCH;

  constructor(public payload: string) {}
}

export class SearchSuccess implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchFailure implements Action {
  readonly type = SEARCH_FAILURE;

  constructor(public payload: string) {}
}

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: any) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: string) {}
}

export class LoadProfile implements Action {
  readonly type = LOAD_PROFILE;

  constructor(public payload: any) {}
}

export class LoadProfileSuccess implements Action {
  readonly type = LOAD_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadProfileFailure implements Action {
  readonly type = LOAD_PROFILE_FAILURE;

  constructor(public payload: string) {}
}

export type All = Load | LoadSuccess | LoadFailure | LoadMore | LoadMoreSuccess | LoadMoreFailure | Reset |
  Search | SearchSuccess | SearchFailure | Update | UpdateSuccess |
  UpdateFailure | LoadById | LoadByIdSuccess | LoadByIdFailure | LoadProfile | LoadProfileFailure | LoadProfileSuccess;

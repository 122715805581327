import {MemberEffects} from './member/member.effects';
import {CreateEffects} from './create/create.effects';
import {GroupEffects} from './groups/groups.effects';
import {UserEffects} from './user/user.effects';
import {PermissionsEffects} from './permissions/permissions.effects';
import {AuthEffects} from './auth/auth.effects';
import {InstitutionEffects} from './institutions/institutions.effects';
import {SettingsEffects} from './settings/settings.effects';
import {FCMEffects} from './fcm/fcm.effects';
import {ServiceReceiverEffects} from './service-receiver/service-receiver.effects';
import {EULAEffects} from './eula/eula.effects';
import {RegisterEffects} from './register/register.effects';
import {EmployeeEffects} from './employees/employee.effects';
import {RelativeEffects} from './relatives/relative.effects';
import {InvitationEffects} from './invitation/invitation.effects';
import {RelationEffects} from './relation/relation.effects';
import {MessageEffects} from './message/message.effects';
import {CreateInstitutionChildEffects} from './create-institution-child/create-institution-child.effects';
import {OccurrenceEffects} from './occurrence/occurrence.effects';
import {SupportEffects} from './support/support.effects';
import {CreateServiceReceiverEffects} from './create-service-receiver/create-service-receiver.effects';
import {AddressEffects} from './address/address.effects';
import {EntryEffects} from './entry/entry.effects';

export const effects = [
  AuthEffects,
  ServiceReceiverEffects,
  RelativeEffects,
  EmployeeEffects,
  GroupEffects,
  InstitutionEffects,
  EntryEffects,
  MessageEffects,
  OccurrenceEffects,
  CreateEffects,
  RegisterEffects,
  InvitationEffects,
  RelationEffects,
  MemberEffects,
  SettingsEffects,
  CreateServiceReceiverEffects,
  UserEffects,
  AddressEffects,
  SupportEffects,
  CreateInstitutionChildEffects,
  EULAEffects,
  PermissionsEffects,
  FCMEffects
];

import {Action} from '@ngrx/store';

export const LOAD = '[EULA] load';
export const LOAD_SUCCESS = '[EULA] load success';
export const LOAD_FAILURE = '[EULA] load failure';
export const SEND = '[EULA] send';
export const SEND_SUCCESS = '[EULA] send success';
export const SEND_FAILURE = '[EULA] send failure';
export const RESET_MESSAGE = '[EULA] reset message';
export const LOAD_BY_USER = '[EULA] load by user';
export const LOAD_BY_USER_SUCCESS = '[EULA] load by user success';
export const LOAD_BY_USER_FAILURE = '[EULA] load by user failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload: string) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class Send implements Action {
  readonly type = SEND;

  constructor(public payload?: any) {}
}

export class SendSuccess implements Action {
  readonly type = SEND_SUCCESS;

  constructor(public payload: any) {}
}

export class SendFailure implements Action {
  readonly type = SEND_FAILURE;

  constructor(public payload: string) {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor(public payload?: string) {}
}

export class LoadByUser implements Action {
  readonly type = LOAD_BY_USER;

  constructor(public payload: string) {}
}

export class LoadByUserSuccess implements Action {
  readonly type = LOAD_BY_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByUserFailure implements Action {
  readonly type = LOAD_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export type All = Load | LoadSuccess | LoadFailure | Send | SendSuccess | SendFailure | ResetMessage | LoadByUser |
  LoadByUserSuccess | LoadByUserFailure;

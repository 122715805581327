import * as RelationActions from './relation.actions';
import {handleError} from '../helpers';

export type Action = RelationActions.All;

export interface RelationState {
  loading: boolean;
  entitiesByUser: any;
  error: string;
}

const initialState: RelationState = {
  loading: null,
  entitiesByUser: null,
  error: null
};

export function reducer(state: RelationState = initialState, {type, payload}: Action): RelationState {
  switch (type) {
    case RelationActions.LOAD_BY_USER:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case RelationActions.LOAD_BY_USER_SUCCESS:
      if (!state.entitiesByUser) {
        state.entitiesByUser = {};
      }

      return Object.assign({}, state, {
        loading: false,
        entitiesByUser: Object.assign({}, state.entitiesByUser,{
          [payload.user_id]: Object.assign({}, payload.relations)
        })
      });
    case RelationActions.LOAD_BY_USER_FAILURE:
      return Object.assign({}, state, {
        error: handleError(payload)
      });
    case RelationActions.RESET:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}

export const getLoadingState = (state: RelationState) => state.loading;
export const getEntitiesByUser = (state: RelationState) => state.entitiesByUser;
export const getError = (state: RelationState) => state.error;

import {Action} from '@ngrx/store';

export const LOAD = '[FCM] load';
export const LOAD_SUCCESS = '[FCM] load success';
export const LOAD_FAILURE = '[FCM] load failed';
export const REGISTER_DEVICE = '[FCM] register device';
export const REGISTER_DEVICE_SUCCESS = '[FCM] register device success';
export const REGISTER_DEVICE_FAILURE = '[FCM] register device failed';
export const REMOVE_DEVICE = '[FCM] remove device';
export const REMOVE_DEVICE_SUCCESS = '[FCM] remove device success';
export const REMOVE_DEVICE_FAILURE = '[FCM] remove device failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class RegisterDevice implements Action {
  readonly type = REGISTER_DEVICE;

  constructor(public payload: any) {}
}

export class RegisterDeviceSuccess implements Action {
  readonly type = REGISTER_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class RegisterDeviceFailure implements Action {
  readonly type = REGISTER_DEVICE_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveDevice implements Action {
  readonly type = REMOVE_DEVICE;

  constructor(public payload?: any) {}
}

export class RemoveDeviceSuccess implements Action {
  readonly type = REMOVE_DEVICE_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveDeviceFailure implements Action {
  readonly type = REMOVE_DEVICE_FAILURE;

  constructor(public payload: any) {}
}

export type All = Load | LoadSuccess | LoadFailure | RegisterDevice | RegisterDeviceSuccess | RegisterDeviceFailure |
  RemoveDevice | RemoveDeviceFailure | RemoveDeviceSuccess;
import * as NavActions from './nav.actions';

export type Action = NavActions.All;

export interface NavState {
  message: string;
  pageTitle: string;
  backButton: boolean;
}

const initialState: NavState = {
  message: null,
  pageTitle: null,
  backButton: null
};

export function reducer(state: NavState = initialState, {type, payload}: Action): NavState {
  switch (type) {
    case NavActions.SET_MESSAGE:
      return Object.assign({}, state, {
        message: payload
      });
    case NavActions.RESET_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });
    case NavActions.SET_TITLE:
      return Object.assign({}, state, {
        pageTitle: payload
      });
    case NavActions.SET_BACK_BUTTON:
      return Object.assign({}, state, {
        backButton: payload
      });
    default:
      return state;
  }
}

export const getMessage = (state: NavState) => state.message;
export const getPageTitle = (state: NavState) => state.pageTitle;
export const getBackButton = (state: NavState) => state.backButton;

import {createSelector} from 'reselect';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromAuth from './auth/auth.reducer';
import * as fromServiceReceivers from './service-receiver/service-receiver.reducer';
import * as fromRelatives from './relatives/relative.reducer';
import * as fromEmployees from './employees/employee.reducer';
import * as fromGroup from './groups/groups.reducer';
import * as fromInstitution from './institutions/institutions.reducer';
import * as fromEntry from './entry/entry.reducer';
import * as fromMessages from './message/message.reducer';
import * as fromOccurrences from './occurrence/occurrence.reducer';
import * as fromCreate from './create/create.reducer';
import * as fromRegister from './register/register.reducer';
import * as fromCreateServiceReceiver from './create-service-receiver/create-service-receiver.reducer';
import * as fromInvitation from './invitation/invitation.reducer';
import * as fromNav from './nav/nav.reducer';
import * as fromRelations from './relation/relation.reducer';
import * as fromMembers from './member/member.reducer';
import * as fromSettings from './settings/settings.reducer';
import * as fromUsers from './user/user.reducer';
import * as AuthActions from './auth/auth.actions';
import * as fromAddress from './address/address.reducer';
import * as fromSupport from './support/support.reducer';
import * as fromCreateInstitutionChild from './create-institution-child/create-institution-child.reducer';
import * as fromNavBarButton from './nav-bar-button/nav-bar-button.reducer';
import * as fromEula from './eula/eula.reducer';
import * as fromPermissions from './permissions/permissions.reducer';
import * as fromFcm from './fcm/fcm.reducer';
import * as fromDialog from './dialog/dialog.reducer';
import * as fromUI from './ui/ui.reducer';

export interface IAppState {
  auth: fromAuth.AuthState;
  serviceReceivers: fromServiceReceivers.ServiceReceiverState;
  relatives: fromRelatives.RelativeState;
  employees: fromEmployees.EmployeeState;
  group: fromGroup.GroupState;
  institution: fromInstitution.InstitutionState;
  entry: fromEntry.EntryState;
  messages: fromMessages.MessageState;
  occurrences: fromOccurrences.OccurrenceState;
  create: fromCreate.CreateState;
  register: fromRegister.RegisterState;
  createServiceReceiver: fromCreateServiceReceiver.CreateServiceReceiverState;
  invitation: fromInvitation.InvitationState;
  nav: fromNav.NavState;
  relations: fromRelations.RelationState;
  members: fromMembers.MemberState;
  settings: fromSettings.SettingsState;
  users: fromUsers.UserState;
  addresses: fromAddress.AddressState;
  support: fromSupport.SupportState;
  createInstituionChild: fromCreateInstitutionChild.CreateInstitutionChildState;
  navBarButton: fromNavBarButton.NavBarButtonState;
  eula: fromEula.EULAState;
  permissions: fromPermissions.PermissionsState;
  fcm: fromFcm.FCMState;
  dialog: fromDialog.DialogState;
  ui: fromUI.UIState;
}

export const reducers: ActionReducerMap<IAppState> = {
  auth: fromAuth.reducer,
  serviceReceivers: fromServiceReceivers.reducer,
  relatives: fromRelatives.reducer,
  employees: fromEmployees.reducer,
  group: fromGroup.reducer,
  institution: fromInstitution.reducer,
  entry: fromEntry.reducer,
  messages: fromMessages.reducer,
  occurrences: fromOccurrences.reducer,
  create: fromCreate.reducer,
  register: fromRegister.reducer,
  createServiceReceiver: fromCreateServiceReceiver.reducer,
  invitation: fromInvitation.reducer,
  nav: fromNav.reducer,
  relations: fromRelations.reducer,
  members: fromMembers.reducer,
  settings: fromSettings.reducer,
  users: fromUsers.reducer,
  addresses: fromAddress.reducer,
  support: fromSupport.reducer,
  createInstituionChild: fromCreateInstitutionChild.reducer,
  navBarButton: fromNavBarButton.reducer,
  eula: fromEula.reducer,
  permissions: fromPermissions.reducer,
  fcm: fromFcm.reducer,
  dialog: fromDialog.reducer,
  ui: fromUI.reducer,
};

export function resetReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    switch (action.type) {
      case AuthActions.UNAUTHENTICATE:
        state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [resetReducer];

export const getAuthState = (state: IAppState) => state.auth;
export const getAuthenticationState = createSelector(getAuthState, fromAuth.getState);
export const getLoggedInUser = createSelector(getAuthState, fromAuth.getLoggedInUser);
export const getAuthLoadingState = createSelector(getAuthState, fromAuth.getAuthLoadingState);
export const getAuthErrorMessage = createSelector(getAuthState, fromAuth.getAuthErrorMessage);
export const getAuthSuccessMessage = createSelector(getAuthState, fromAuth.getAuthSuccessMessage);

export const getServiceReceiverState = (state: IAppState) => state.serviceReceivers;
export const getServiceReceiversLoadingState = createSelector(getServiceReceiverState, fromServiceReceivers.getLoadingState);
export const getServiceReceivers = createSelector(getServiceReceiverState, fromServiceReceivers.getEntities);
export const getServiceReceiversById = createSelector(getServiceReceiverState, fromServiceReceivers.getEntitiesById);
export const getServiceReceiverSearchResults = createSelector(getServiceReceiverState, fromServiceReceivers.getSearchResults);
export const getServiceReceiversNextUrl = createSelector(getServiceReceiverState, fromServiceReceivers.getNextUrl);
export const getServiceReceiversError = createSelector(getServiceReceiverState, fromServiceReceivers.getError);
export const getServiceReceiverSearchState = createSelector(getServiceReceiverState, fromServiceReceivers.getSearchState);
export const getServiceReceiversLoadingMoreState = createSelector(getServiceReceiverState, fromServiceReceivers.getLoadingMoreState);
export const getServiceReceiversUserProfile = createSelector(getServiceReceiverState, fromServiceReceivers.getUserProfile);

export const getRelativeState = (state: IAppState) => state.relatives;
export const getRelativesLoadingState = createSelector(getRelativeState, fromRelatives.getLoadingState);
export const getRelatives = createSelector(getRelativeState, fromRelatives.getEntities);
export const getRelativesById = createSelector(getRelativeState, fromRelatives.getEntitiesById);
export const getRelativesNextUrl = createSelector(getRelativeState, fromRelatives.getNextUrl);
export const getRelativesError = createSelector(getRelativeState, fromRelatives.getError);

export const getEmployeeState = (state: IAppState) => state.employees;
export const getEmployeesLoadingState = createSelector(getEmployeeState, fromEmployees.getLoadingState);
export const getEmployees = createSelector(getEmployeeState, fromEmployees.getEntities);
export const getEmployeesById = createSelector(getEmployeeState, fromEmployees.getEntitiesById);
export const getEmployeesNextUrl = createSelector(getEmployeeState, fromEmployees.getNextUrl);
export const getEmployeesError = createSelector(getEmployeeState, fromEmployees.getError);
export const getEmployeesLoadedByUrl = createSelector(getEmployeeState, fromEmployees.getEntitiesByInstitutionUrl);

export const getGroupState = (state: IAppState) => state.group;
export const getGroupLoadingState = createSelector(getGroupState, fromGroup.getLoadingState);
export const getGroupLoadingMoreState = createSelector(getGroupState, fromGroup.getLoadingMoreState);
export const getGroups = createSelector(getGroupState, fromGroup.getGroups);
export const getGroupsById = createSelector(getGroupState, fromGroup.getGroupsById);
export const getGroupsNextUrl = createSelector(getGroupState, fromGroup.getGroupsNextUrl);
export const getSearchResults = createSelector(getGroupState, fromGroup.getSearchResults);
export const getGroupError = createSelector(getGroupState, fromGroup.getError);
export const getGroupMessage = createSelector(getGroupState, fromGroup.getMessage);
export const getGroupsByUserId = createSelector(getGroupState, fromGroup.getByUserId);

export const getInstitutionState = (state: IAppState) => state.institution;
export const getInstitutionLoadingState = createSelector(getInstitutionState, fromInstitution.getLoadingState);
export const getInstitutions = createSelector(getInstitutionState, fromInstitution.getEntities);
export const getInstitutionsById = createSelector(getInstitutionState, fromInstitution.getById);
export const getInstitutionsNextUrl = createSelector(getInstitutionState, fromInstitution.getNextUrl);
export const getInstitutionSearchResult = createSelector(getInstitutionState, fromInstitution.getSearchResult);
export const getInstitutionError = createSelector(getInstitutionState, fromInstitution.getError);
export const getInstitutionLoadingChildrenState = createSelector(getInstitutionState, fromInstitution.getChildrenLoadingState);
export const getInstitutionEmployees = createSelector(getInstitutionState, fromInstitution.getEmployees);
export const getInstitutionServiceReceiver = createSelector(getInstitutionState, fromInstitution.getServiceReceivers);
export const getInstitutionsByUserId = createSelector(getInstitutionState, fromInstitution.getByUserId);

export const getRelationState = (state: IAppState) => state.relations;
export const getRelationsLoadingState = createSelector(getRelationState, fromRelations.getLoadingState);
export const getRelationsByUser = createSelector(getRelationState, fromRelations.getEntitiesByUser);
export const getRelationsError = createSelector(getRelationState, fromRelations.getError);

export const getEntryState = (state: IAppState) => state.entry;
export const getEntryLoadingState = createSelector(getEntryState, fromEntry.getLoadingState);
export const getEntryLoadingMoreState = createSelector(getEntryState, fromEntry.getLoadingMoreState);
export const getEntries = createSelector(getEntryState, fromEntry.getEntities);
export const getEntryById = createSelector(getEntryState, fromEntry.getEntitiesById);
export const getEntriesByOwner = createSelector(getEntryState, fromEntry.getEntitiesByOwner);
export const getEntryNextUrl = createSelector(getEntryState, fromEntry.getNextUrl);
export const getEntryErrorMessage = createSelector(getEntryState, fromEntry.getError);
export const getEntryStateMessage = createSelector(getEntryState, fromEntry.getMessage);
export const getEntryContentObjectNextUrl = createSelector(getEntryState, fromEntry.getContentObjectNextUrl);
export const getEntryComments = createSelector(getEntryState, fromEntry.getComments);
export const getPinnedPosts = createSelector(getEntryState, fromEntry.getPinnedPosts);

export const getMessageState = (state: IAppState) => state.messages;
export const getMessagesLoadingState = createSelector(getMessageState, fromMessages.getLoadingState);
export const getMessagesLoadingMoreState = createSelector(getMessageState, fromMessages.getLoadingMoreState);
export const getMessages = createSelector(getMessageState, fromMessages.getEntities);
export const getMessagesById = createSelector(getMessageState, fromMessages.getEntitiesById);
export const getMessagesNextUrl = createSelector(getMessageState, fromMessages.getNextUrl);
export const getMessagesError = createSelector(getMessageState, fromMessages.getError);
export const getMessageStateMessage = createSelector(getMessageState, fromMessages.getMessage);
export const getMessagesByOwner = createSelector(getMessageState, fromMessages.getEntitiesByOwner);
export const getMessagesUnread = createSelector(getMessageState, fromMessages.getUnread);
export const getMessagesPinned = createSelector(getMessageState, fromMessages.getPinnedMessages);
export const getMessagesNextPinnedUrl = createSelector(getMessageState, fromMessages.getNextPinnedUrl);

export const getOccurrenceState = (state: IAppState) => state.occurrences;
export const getOccurrenceLoadingState = createSelector(getOccurrenceState, fromOccurrences.getLoadingState);
export const getOccurrences = createSelector(getOccurrenceState, fromOccurrences.getEntities);
export const getOccurrencesError = createSelector(getOccurrenceState, fromOccurrences.getErrorMessage);
export const getOccurrencesByEventId = createSelector(getOccurrenceState, fromOccurrences.getEntitiesByEventId);
export const getOccurrenceStateMessage = createSelector(getOccurrenceState, fromOccurrences.getStateMessage);

export const getCreateState = (state: IAppState) => state.create;
export const getCreateLoadingState = createSelector(getCreateState, fromCreate.getLoadingState);
export const getPublishState = createSelector(getCreateState, fromCreate.getPublishingState);
export const getCreateError = createSelector(getCreateState, fromCreate.getError);
export const getPublishingMessage = createSelector(getCreateState, fromCreate.getPublishingMessage);

export const getRegisterState = (state: IAppState) => state.register;
export const getRegisterLoadingState = createSelector(getRegisterState, fromRegister.getLoadingState);
export const getRegisterError = createSelector(getRegisterState, fromRegister.getErrorMessage);

export const getCreateServiceReceiverState = (state: IAppState) => state.createServiceReceiver;
export const getCreateServiceReceiverLoadingState = createSelector(getCreateServiceReceiverState, fromCreateServiceReceiver.getLoadingState);
export const getCreateServiceReceiverMessage = createSelector(getCreateServiceReceiverState, fromCreateServiceReceiver.getMessage);
export const getCreateServiceReceiverError = createSelector(getCreateServiceReceiverState, fromCreateServiceReceiver.getErrorMessage);

export const getInvitationState = (state: IAppState) => state.invitation;
export const getInvitationLoadingState = createSelector(getInvitationState, fromInvitation.getLoadingState);
export const getInvitations = createSelector(getInvitationState, fromInvitation.getEntities);
export const getInvitationError = createSelector(getInvitationState, fromInvitation.getError);

export const getNavState = (state: IAppState) => state.nav;
export const getNavMessage = createSelector(getNavState, fromNav.getMessage);
export const getNavPageTitle = createSelector(getNavState, fromNav.getPageTitle);
export const getNavPageBackButton = createSelector(getNavState, fromNav.getBackButton);

export const getMemberState = (state: IAppState) => state.members;
export const getMemberLoadingState = createSelector(getMemberState, fromMembers.getLoadingState);
export const getMembersByGroupId = createSelector(getMemberState, fromMembers.getEntitiesByGroupId);
export const getMemberError = createSelector(getMemberState, fromMembers.getError);

export const getSettingsState = (state: IAppState) => state.settings;
export const getSettingsLoadingState = createSelector(getSettingsState, fromSettings.getLoadingState);
export const getSettingsById = createSelector(getSettingsState, fromSettings.getEntitiesById);
export const getSettingsError = createSelector(getSettingsState, fromSettings.getError);
export const getSettingsMessage = createSelector(getSettingsState, fromSettings.getMessage);

export const getUserState = (state: IAppState) => state.users;
export const getUsersLoadingState = createSelector(getUserState, fromUsers.getLoadingState);
export const getUsers = createSelector(getUserState, fromUsers.getEntities);
export const getUserSearchResults = createSelector(getUserState, fromUsers.getSearchResults);
export const getUsersById = createSelector(getUserState, fromUsers.getEntitiesById);
export const getUsersError = createSelector(getUserState, fromUsers.getError);
export const getUsersNextUrl = createSelector(getUserState, fromUsers.getNextUrl);
export const getUsersMessage = createSelector(getUserState, fromUsers.getMessage);
export const getUsersViewImage = createSelector(getUserState, fromUsers.getViewImage);

export const getAddressState = (state: IAppState) => state.addresses;
export const getAddressLoadingState = createSelector(getAddressState, fromAddress.getAddressLoadingState);
export const getAddressError = createSelector(getAddressState, fromAddress.getAddressErrorState);
export const getCountries = createSelector(getAddressState, fromAddress.getCountries);
export const getMunicipalities = createSelector(getAddressState, fromAddress.getMunicipalities);
export const getMunicipalitySearchResults = createSelector(getAddressState, fromAddress.getSearchResults);

export const getSupportState = (state: IAppState) => state.support;
export const getSupportLoadingState = createSelector(getSupportState, fromSupport.getLoadingState);
export const getSupportError = createSelector(getSupportState, fromSupport.getError);
export const getSupportMessage = createSelector(getSupportState, fromSupport.getMessage);

export const getCreateInstitutionChildState = (state: IAppState) => state.createInstituionChild;
export const getCreateInstitutionChildLoadingState = createSelector(getCreateInstitutionChildState, fromCreateInstitutionChild.getLoadingState);
export const getCreateInstitutionChildError = createSelector(getCreateInstitutionChildState, fromCreateInstitutionChild.getError);
export const getCreateInstitutionChildMessage = createSelector(getCreateInstitutionChildState, fromCreateInstitutionChild.getMessage);

export const getNavBarButtonState = (state: IAppState) => state.navBarButton;
export const getNavBarButtonTitle = createSelector(getNavBarButtonState, fromNavBarButton.getTitle);
export const getNavBarButtonEvent = createSelector(getNavBarButtonState, fromNavBarButton.getEvent);

export const getEulaState = (state: IAppState) => state.eula;
export const getEulaLoadingState = createSelector(getEulaState, fromEula.getLoadingState);
export const getEulas = createSelector(getEulaState, fromEula.getEntities);
export const getEulaResponseByUser = createSelector(getEulaState, fromEula.getResponseByUser);
export const getEulaMessage = createSelector(getEulaState, fromEula.getMessage);

export const getPermissionsState = (state: IAppState) => state.permissions;
export const getPermissionsLoading = createSelector(getPermissionsState, fromPermissions.getLoadingState);
export const getPermissionsError = createSelector(getPermissionsState, fromPermissions.getError);
export const getPermissions = createSelector(getPermissionsState, fromPermissions.getPermissions);

export const getFcmState = (state: IAppState) => state.fcm;
export const getFcmDevices = createSelector(getFcmState, fromFcm.getEntities);
export const getFcmMessage = createSelector(getFcmState, fromFcm.getMessage);
export const getFcmLoadingState = createSelector(getFcmState, fromFcm.getLoadingState);

export const getDialogState = (state: IAppState) => state.dialog;
export const getDialogLoadingState = createSelector(getDialogState, fromDialog.getLoadingState);
export const getDialogErrorState = createSelector(getDialogState, fromDialog.getErrorState);
export const getDialogMessageState = createSelector(getDialogState, fromDialog.getMessageState);
export const getDialogs = createSelector(getDialogState, fromDialog.getDialogState);
export const getDialogById = createSelector(getDialogState, fromDialog.getDialogByIdState);
export const getDialogRecipients = createSelector(getDialogState, fromDialog.getRecipientsState);
export const getDialogNextUrl = createSelector(getDialogState, fromDialog.getNextUrlState);
export const getDialogThread = createSelector(getDialogState, fromDialog.getDialogThreadState);

export const getUIState = (state: IAppState) => state.ui;
export const getUIMainScrollEvent = createSelector(getUIState, fromUI.getMainScrollEvent);
export const getUIScrolledPastCalendarHeader = createSelector(getUIState, fromUI.getScrolledPastCalendarHeader);

import * as RelativeActions from './relative.actions';
import {sortEntitiesById} from '../helpers';

export type Action = RelativeActions.All;

export interface RelativeState {
  loading: boolean;
  entities: any[];
  entitiesById: any;
  nextUrl: string;
  error: string;
}

const initialState: RelativeState = {
  loading: null,
  entities: null,
  entitiesById: null,
  nextUrl: null,
  error: null
};

export function reducer(state: RelativeState = initialState, {type, payload}: Action): RelativeState {
  switch (type) {
    case RelativeActions.LOAD:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case RelativeActions.LOAD_SUCCESS:

      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        entitiesById: sortEntitiesById(payload.results),
        nextUrl: payload.next
      });
    case RelativeActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        error: payload
      });
    case RelativeActions.LOAD_MORE:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case RelativeActions.LOAD_MORE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: [state.entities, ...payload.results]
      });
    case RelativeActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        error: payload
      });
    case RelativeActions.RESET:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}

export const getLoadingState = (state: RelativeState) => state.loading;
export const getEntities = (state: RelativeState) => state.entities;
export const getEntitiesById = (state: RelativeState) => state.entitiesById;
export const getNextUrl = (state: RelativeState) => state.nextUrl;
export const getError = (state: RelativeState) => state.error;

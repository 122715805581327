import {Action} from '@ngrx/store';

export const AUTHENTICATE = '[Auth] authenticate';
export const AUTHENTICATE_SUCCESS = '[Auth] authenticate success';
export const AUTHENTICATE_FAILURE = '[Auth] authenticate failure';
export const AUTH_WITH_CREDENTIALS = '[Auth] authenticate with credentials';
export const AUTH_WITH_CREDENTIALS_SUCCESS = '[Auth] authenticate with credentials success';
export const AUTH_WITH_CREDENTIALS_FAILURE = '[Auth] authenticate with credentials failure';
export const LOAD_AUTH_USER = '[Auth] load auth user';
export const LOAD_AUTH_USER_SUCCESS = '[Auth] load auth user success';
export const LOAD_AUTH_USER_FAILURE = '[Auth] load auth user failure';
export const UNAUTHENTICATE = '[Auth] unauthenticate';
export const UNAUTHENTICATE_SUCCESS = '[Auth] unauthenticate success';
export const UNAUTHENTICATE_FAILURE = '[Auth] unauthenticate failure';
export const CLEAR_AUTH = '[Auth] clear auth';
export const CLEAR_AUTH_SUCCESS = '[Auth] clear auth success';
export const CLEAR_AUTH_FAILURE = '[Auth] clear auth failure';
export const FORGOT_PASSWORD = '[Auth] forgot password';
export const FORGOT_PASSWORD_SUCCESS = '[Auth] forgot password success';
export const FORGOT_PASSWORD_FAILURE = '[Auth] forgot password failure';
export const UPDATE = '[Auth] update';
export const UPDATE_SUCCESS = '[Auth] update success';
export const UPDATE_FAILURE = '[Auth] update failure';
export const CHECK_AUTH = '[Auth] check auth';
export const CHECK_AUTH_SUCCESS = '[Auth] check auth success';
export const CHECK_AUTH_FAILURE = '[Auth] check auth failure';
export const SEND_CONFIRM = '[Auth] send confirm';
export const SEND_CONFIRM_SUCCESS = '[Auth] send confirm success';
export const SEND_CONFIRM_FAILURE = '[Auth] send confirm failure';
export const CONFIRM_ACCOUNT = '[Auth] confirm account';
export const CONFIRM_ACCOUNT_SUCCESS = '[Auth] confirm account success';
export const CONFIRM_ACCOUNT_FAILURE = '[Auth] confirm account failure';
export const SET_PASSWORD = '[Auth] set password';
export const SET_PASSWORD_SUCCESS = '[Auth] set password success';
export const SET_PASSWORD_FAILURE = '[Auth] set password failure';

export class Authenticate implements Action {
  readonly type = AUTHENTICATE;

  constructor(public payload: string) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(public payload: any) {}
}

export class AuthenticateFailure implements Action {
  readonly type = AUTHENTICATE_FAILURE;

  constructor(public payload: string) {}
}

export class AuthWithCredentials implements Action {
  readonly type = AUTH_WITH_CREDENTIALS;

  constructor(public payload: {email: string, password: string}) {}
}

export class AuthWithCredentialsSuccess implements Action {
  readonly type = AUTH_WITH_CREDENTIALS_SUCCESS;

  constructor(public payload: any) {}
}

export class AuthWithCredentialsFailure implements Action {
  readonly type = AUTH_WITH_CREDENTIALS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadAuthUser implements Action {
  readonly type = LOAD_AUTH_USER;

  constructor(public payload?: any) {}
}

export class LoadAuthUserSuccess implements Action {
  readonly type = LOAD_AUTH_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadAuthUserFailure implements Action {
  readonly type = LOAD_AUTH_USER_FAILURE;

  constructor(public payload: string) {}
}

export class UnAuthenticate implements Action {
  readonly type = UNAUTHENTICATE;

  constructor(public payload?: any) {}
}

export class UnAuthenticateSuccess implements Action {
  readonly type = UNAUTHENTICATE_SUCCESS;

  constructor(public payload?: any) {}
}

export class UnAuthenticateFailure implements Action {
  readonly type = UNAUTHENTICATE_FAILURE;

  constructor(public payload?: any) {}
}

export class ClearAuth implements Action {
  readonly type = CLEAR_AUTH;

  constructor(public payload?: any) {}
}

export class ClearAuthSuccess implements Action {
  readonly type = CLEAR_AUTH_SUCCESS;

  constructor(public payload?: any) {}
}

export class ClearAuthFailure implements Action {
  readonly type = CLEAR_AUTH_FAILURE;

  constructor(public payload?: any) {}
}

export class ForgotPassword implements Action {
  readonly type = FORGOT_PASSWORD;

  constructor(public payload?: any) {}
}

export class ForgotPasswordSuccess implements Action {
  readonly type = FORGOT_PASSWORD_SUCCESS;

  constructor(public payload?: any) {}
}

export class ForgotPasswordFailure implements Action {
  readonly type = FORGOT_PASSWORD_FAILURE;

  constructor(public payload?: any) {}
}

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: any) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: string) {
  }
}

export class CheckAuth implements Action {
  readonly type = CHECK_AUTH;

  constructor(public payload?: any) {
  }
}

export class CheckAuthSuccess implements Action {
  readonly type = CHECK_AUTH_SUCCESS;

  constructor(public payload: any) {
  }
}

export class CheckAuthFailure implements Action {
  readonly type = CHECK_AUTH_FAILURE;

  constructor(public payload: string) {
  }
}

export class SendConfirm implements Action {
  readonly type = SEND_CONFIRM;

  constructor(public payload: { cellPhoneNumber: string, token: string }) {
  }
}

export class SendConfirmSuccess implements Action {
  readonly type = SEND_CONFIRM_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SendConfirmFailure implements Action {
  readonly type = SEND_CONFIRM_FAILURE;

  constructor(public payload: string) {
  }
}

export class ConfirmAccount implements Action {
  readonly type = CONFIRM_ACCOUNT;

  constructor(public payload: {code: number, sub: string, token: string}) {
  }
}

export class ConfirmAccountSuccess implements Action {
  readonly type = CONFIRM_ACCOUNT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class ConfirmAccountFailure implements Action {
  readonly type = CONFIRM_ACCOUNT_FAILURE;

  constructor(public payload: string) {
  }
}

export class SetPassword implements Action {
  readonly type = SET_PASSWORD;

  constructor(public payload: {userId: string, password: string}) {
  }
}

export class SetPasswordSuccess implements Action {
  readonly type = SET_PASSWORD_SUCCESS;

  constructor(public payload: any) {
  }
}

export class SetPasswordFailure implements Action {
  readonly type = SET_PASSWORD_FAILURE;

  constructor(public payload: string) {
  }
}

export type All
  = Authenticate | AuthenticateSuccess | AuthenticateFailure | AuthWithCredentials | AuthWithCredentialsSuccess |
  AuthWithCredentialsFailure | LoadAuthUser | LoadAuthUserSuccess | LoadAuthUserFailure | UnAuthenticate | UnAuthenticateSuccess |
  UnAuthenticateFailure | ClearAuth | ClearAuthSuccess | ClearAuthFailure | ForgotPassword |
  ForgotPasswordSuccess | ForgotPasswordFailure | Update | UpdateSuccess | UpdateFailure | CheckAuth | CheckAuthSuccess |
  CheckAuthFailure | SendConfirm | SendConfirmSuccess | SendConfirmFailure | ConfirmAccount | ConfirmAccountSuccess |
  ConfirmAccountFailure | SetPassword | SetPasswordSuccess | SetPasswordFailure;

import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from './config';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import {ApiService} from './api.service';

@Injectable()
export class EulaService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorageService) {
    super(_ls);
  }

  load(language: string): Observable<any> {
    return this._http.get(`${API_URL}${API_VERSION}eula/latest/${language}`, { headers: this._getHeaders() });
  }

  sendEula(response: any): Observable<any> {
    return this._http.post(`${API_URL}${API_VERSION}eula/respond`, JSON.stringify(response), { headers: this._getHeaders() });
  }

  loadByUser(userId: string): Observable<any> {
    return this._http.get(`${API_URL}${API_VERSION}users/${userId}/eula`, { headers: this._getHeaders() });
  }

}

import {Action} from '@ngrx/store';

export const LOAD = '[Invitation] register';
export const LOAD_SUCCESS = '[Invitation] register success';
export const LOAD_FAILURE = '[Invitation] register failure';
export const ACCEPT = '[Invitation] accept';
export const ACCEPT_SUCCESS = '[Invitation] accept success';
export const ACCEPT_FAILURE = '[Invitation] accept failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class Accept implements Action {
  readonly type = ACCEPT;

  constructor(public payload: string) {}
}

export class AcceptSuccess implements Action {
  readonly type = ACCEPT_SUCCESS;

  constructor(public payload: string) {}
}

export class AcceptFailure implements Action {
  readonly type = ACCEPT_FAILURE;

  constructor(public payload: string) {}
}

export type All = Load | LoadSuccess | LoadFailure | Accept | AcceptSuccess | AcceptFailure;

import * as CreateActions from './create.actions';
import {handleError} from '../helpers';

export type Action = CreateActions.All;

export interface CreateState {
  loading: boolean;
  error: string;
  publishing: boolean;
  publishingMessage: string;
}

const initialState: CreateState = {
  loading: null,
  error: null,
  publishing: null,
  publishingMessage: null
};

export function reducer(state: CreateState = initialState, {type, payload}: Action): CreateState {
  switch (type) {
    case CreateActions.PUBLISH_MESSAGE:
      return Object.assign({}, state, {
        error: null,
        publishing: true,
        publishingMessage: 'PUBLISHING',
      });
    case CreateActions.PUBLISH_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        publishing: false,
        publishingMessage: 'PUBLISH_MESSAGE_SUCCESS',
      });
    case CreateActions.PUBLISH_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        publishing: false,
        error: 'UNABLE_TO_CREATE_MESSAGE',
        publishingMessage: null
      });
    case CreateActions.PUBLISH_ENTRY:
      return Object.assign({}, state, {
        error: null,
        publishingMessage: 'PUBLISHING',
        publishing: true
      });
    case CreateActions.PUBLISH_ENTRY_SUCCESS:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_ENTRY_SUCCESS',
        publishing: false
      });
    case CreateActions.PUBLISH_ENTRY_FAILURE:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_ENTRY_FAILURE',
        publishing: false,
        error: handleError(payload)
      });
    case CreateActions.PUBLISH_EVENT:
      return Object.assign({}, state, {
        error: null,
        publishingMessage: 'PUBLISHING',
        publishing: true
      });
    case CreateActions.PUBLISH_EVENT_SUCCESS:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_EVENT_SUCCESS',
        publishing: false
      });
    case CreateActions.PUBLISH_EVENT_FAILURE:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_EVENT_FAILURE',
        publishing: false,
        error: handleError(payload)
      });
    case CreateActions.BULK_PUBLISH_MESSAGE:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISHING',
        publishing: true
      });
    case CreateActions.BULK_PUBLISH_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_MESSAGE_SUCCESS',
        publishing: false
      });
    case CreateActions.BULK_PUBLISH_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_MESSAGE_FAILURE',
        publishing: false,
        error: handleError(payload)
      });
    case CreateActions.BULK_PUBLISH_ENTRY:
      return Object.assign({}, state, {
        error: null,
        publishingMessage: 'PUBLISHING',
        publishing: true
      });
    case CreateActions.BULK_PUBLISH_ENTRY_SUCCESS:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_ENTRY_SUCCESS',
        publishing: false
      });
    case CreateActions.BULK_PUBLISH_ENTRY_FAILURE:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_ENTRY_FAILURE',
        publishing: false,
        error: handleError(payload)
      });
    case CreateActions.BULK_PUBLISH_EVENT:
      return Object.assign({}, state, {
        error: null,
        publishingMessage: 'PUBLISHING',
        publishing: true
      });
    case CreateActions.BULK_PUBLISH_EVENT_SUCCESS:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_EVENT_SUCCESS',
        publishing: false
      });
    case CreateActions.BULK_PUBLISH_EVENT_FAILURE:
      return Object.assign({}, state, {
        publishingMessage: 'PUBLISH_EVENT_FAILURE',
        publishing: false,
        error: handleError(payload)
      });
    case CreateActions.CREATE_USER:
      return Object.assign({}, state, {
        loading: true,
        publishingMessage: 'CREATING_USER',
        error: null
      });
    case CreateActions.CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        publishingMessage: 'USER_CREATED'
      });
    case CreateActions.CREATE_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        publishingMessage: null,
        error: handleError(payload.error)
      });
    case CreateActions.RESET_STATE_MESSAGE:
      return Object.assign({}, state, {
        error: null,
        publishing: false,
        publishingMessage: null
      });
    case CreateActions.RESET_STATE_ERROR:
      return Object.assign({}, state, {
        error: null,
        publishing: false,
        publishingMessage: null
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: CreateState) => state.loading;
export const getError = (state: CreateState) => state.error;
export const getPublishingState = (state: CreateState) => state.publishing;
export const getPublishingMessage = (state: CreateState) => state.publishingMessage;

import { Injectable } from '@angular/core';
import {API_URL, API_VERSION} from 'app/core/config';
import {ApiService} from './api.service';
import { LocalStorageService } from 'ngx-webstorage';
import {forkJoin, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ImageService} from './image.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class EntryService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient, private _image: ImageService) {
    super(_ls);
  }

  load(): Observable<any> {
    return this._http.get(`${API_URL}${API_VERSION}posts?limit=10&offset=0`, { headers: this._getHeaders() });
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url, { headers: this._getHeaders() });
  }

  toggleCared(entry: any, user: any): Observable<any> {
    const hasCared = entry.cared.findIndex(u => u.id === user.id) !== -1;

    if (hasCared) {
      return this._http.delete(`${API_URL}${API_VERSION}posts/${entry.id}/cared`, { headers: this._getHeaders() });
    } else {
      return this._http.post(`${API_URL}${API_VERSION}posts/${entry.id}/cared`, JSON.stringify({}), { headers: this._getHeaders() });
    }
  }

  comment(comment: any): Observable<any> {
    return this._http.post(`${API_URL}${API_VERSION}comments`, JSON.stringify(comment), { headers: this._getHeaders() });
  }

  deleteComment(comment: any): Observable<any> {
    return this._http.delete(`${API_URL}${API_VERSION}comments/${comment.id}`, { headers: this._getHeaders() }).pipe(map(_ => comment));
  }

  updateComment(comment: any): Observable<any> {
    return this._http.patch(`${API_URL}${API_VERSION}comments/${comment.id}`, JSON.stringify({ text: comment.text }), { headers: this._getHeaders() });
  }

  loadComments(id: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}posts/${id}/comments`;
    return this._http.get(url, { headers: this._getHeaders()});
  }

  deleteEntry(post: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}posts/${post.id}`;
    return this._http.delete(url, { headers: this._getHeaders() }).pipe(map(_ => post));
  }

  publish(entry: any, images: any[]): Observable<any> {
    const postUrl = `${API_URL}${API_VERSION}posts`;

    if (images.length > 0) {
      const imageUploads$ = images.map(image => this._image.uploadImage(image.imageFile, image.orientation));
      let post;

      return this._http.post(postUrl, JSON.stringify(entry), { headers: this._getHeaders() })
        .pipe(
          switchMap(newPost => {
          post = newPost;
          return forkJoin(imageUploads$).pipe(map(uploadedImages => uploadedImages.map(image => image.id)));
        }),
        switchMap(imageIds => this._http.post(`${postUrl}/${post.id}/images`, JSON.stringify({ image_ids: imageIds }), { headers: this._getHeaders() })
          .pipe(map(data => Object.assign({}, data, {tempImages: images} )))));
    } else {
      return this._http.post(postUrl, JSON.stringify(entry), { headers: this._getHeaders() });
    }
  }

  bulkPublish(entry: any, images: File[], targets: any[]): Observable<any> {
    const posts$ = targets.map(target => this.publish(Object.assign({}, entry, { owner_id: target.id }), images));
    return forkJoin(posts$);
  }

  loadFeedByOwner(id: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}posts/owner/${id}`;
    return this._http.get(url, {headers: this._getHeaders() });
  }

  update(entry: any, images: any[], deleteIds: string[], pin: boolean): Observable<any> {
    const postUrl = `${API_URL}${API_VERSION}posts/${entry.id}`;

    if (pin !== null) {
      return this._http.patch(postUrl, JSON.stringify({ pinned: pin }), { headers: this._getHeaders() });
    } else {
      if (images.length > 0 && deleteIds.length > 0) {
        const imageUploads$ = images.map(image => this._image.uploadImage(image.imageFile, image.orientation));
        const deleteImages$ = deleteIds.map(deleteId => this._image.remove(deleteId));

        return forkJoin(imageUploads$)
          .pipe(
            map(uploadedImages => uploadedImages.map(image => image.id)),
            switchMap(imageIds => this._http.post(`${postUrl}/images`, JSON.stringify({ image_ids: imageIds }), { headers: this._getHeaders() })),
            switchMap(_ => forkJoin(deleteImages$)),
            switchMap(_ => this._http.patch(postUrl, JSON.stringify({ text: entry.text }), { headers: this._getHeaders() }))
          );
      } else if (images.length > 0) {
        const imageUploads$ = images.map(image => this._image.uploadImage(image.imageFile, image.orientation));

        return forkJoin(imageUploads$)
          .pipe(
            map(uploadedImages => uploadedImages.map(image => image.id)),
            switchMap(imageIds => this._http.post(`${postUrl}/images`, JSON.stringify({ image_ids: imageIds }), { headers: this._getHeaders() })),
            switchMap(_ => this._http.patch(postUrl, JSON.stringify({ text: entry.text }), { headers: this._getHeaders() }))
          );
      } else if (deleteIds.length > 0) {
        const deleteImages$ = deleteIds.map(deleteId => this._image.remove(deleteId));
        return forkJoin(deleteImages$)
          .pipe(
            switchMap(_ => this._http.patch(postUrl, JSON.stringify({ text: entry.text }), { headers: this._getHeaders() }))
          );
      } else {
        return this._http.patch(postUrl, JSON.stringify({ text: entry.text }), { headers: this._getHeaders() });
      }
    }
  }

  loadById(id: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}posts/${id}`;
    return this._http.get(url, { headers: this._getHeaders()});
  }

  loadPinnedPosts(): Observable<any> {
    const url = `${API_URL}${API_VERSION}posts/pinned`;

    return this._http.get(url, {headers: this._getHeaders() });
  }

}

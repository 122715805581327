import {Action} from '@ngrx/store';

export const LOAD = '[Institutions] load';
export const LOAD_SUCCESS = '[Institutions] load success';
export const LOAD_FAILURE = '[Institutions] load failure';
export const LOAD_MORE = '[Institutions] load more';
export const LOAD_MORE_SUCCESS = '[Institutions] load more success';
export const LOAD_MORE_FAILURE = '[Institutions] load more failure';
export const LOAD_BY_ID = '[Institutions] load by id';
export const LOAD_BY_ID_SUCCESS = '[Institutions] load by id success';
export const LOAD_BY_ID_FAILURE = '[Institutions] load by id failure';
export const SEARCH = '[Institutions] search';
export const SEARCH_SUCCESS = '[Institutions] search success';
export const SEARCH_FAILURE = '[Institutions] search failed';
export const LOAD_CHILDREN = '[Institutions] load children';
export const LOAD_CHILDREN_SUCCESS = '[Institutions] load children success';
export const LOAD_CHILDREN_FAILURE = '[Institutions] load children failure';
export const LOAD_EMPLOYEES = '[Institution] load employees';
export const LOAD_EMPLOYEES_SUCCESS = '[Institution] load employees success';
export const LOAD_EMPLOYEES_FAILURE = '[Institution] load employees failure';
export const LOAD_SERVICE_RECEIVERS = '[Institution] load service receivers';
export const LOAD_SERVICE_RECEIVERS_SUCCESS = '[Institution] load service receivers success';
export const LOAD_SERVICE_RECEIVERS_FAILURE = '[Institution] load service receivers failure';
export const LOAD_BY_USER_ID = '[Institution] load by user id';
export const LOAD_BY_USER_ID_SUCCESS = '[Institution] load by user id success';
export const LOAD_BY_USER_ID_FAILURE = '[Institution] load by user id failure';
export const ADD_EMPLOYEE = '[Institution] add Employee';
export const ADD_EMPLOYEE_SUCCESS = '[Institution] add Employee success';
export const ADD_EMPLOYEE_FAILURE = '[Institution] add Employee failure';
export const REMOVE_EMPLOYEE = '[Institution] remove Employee';
export const REMOVE_EMPLOYEE_SUCCESS = '[Institution] remove Employee success';
export const REMOVE_EMPLOYEE_FAILURE = '[Institution] remove Employee failure';
export const ADD_SERVICE_RECEIVER = '[Institution] add service receiver';
export const ADD_SERVICE_RECEIVER_SUCCESS = '[Institution] add service receiver success';
export const ADD_SERVICE_RECEIVER_FAILED = '[Institution] add service receiver failed';
export const REMOVE_SERVICE_RECEIVER = '[Institution] remove service receiver';
export const REMOVE_SERVICE_RECEIVER_SUCCESS = '[Institution] remove service receiver success';
export const REMOVE_SERVICE_RECEIVER_FAILED = '[Institution] remove service receiver failed';
export const LOAD_MANAGER = '[Institution] load manager';
export const LOAD_MANAGER_SUCCESS = '[Institution] load manager success';
export const LOAD_MANAGER_FAILED = '[Institution] load manager failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload: string) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: string) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class Search implements Action {
  readonly type = SEARCH;

  constructor(public payload: string) {}
}

export class SearchSuccess implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchFailed implements Action {
  readonly type = SEARCH_FAILURE;

  constructor(public payload: string) {}
}

export class LoadChildren implements Action {
  readonly type = LOAD_CHILDREN;

  constructor(public payload: string[]) {}
}

export class LoadChildrenSuccess implements Action {
  readonly type = LOAD_CHILDREN_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadChildrenFailure implements Action {
  readonly type = LOAD_CHILDREN_FAILURE;

  constructor(public payload: string) {}
}

export class LoadEmployees implements Action {
  readonly type = LOAD_EMPLOYEES;

  constructor(public payload: any) {}
}

export class LoadEmployeesSuccess implements Action {
  readonly type = LOAD_EMPLOYEES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadEmployeesFailure implements Action {
  readonly type = LOAD_EMPLOYEES_FAILURE;

  constructor(public payload: string) {}
}

export class LoadServiceReceivers implements Action {
  readonly type = LOAD_SERVICE_RECEIVERS;

  constructor(public payload: any) {}
}

export class LoadServiceReceiversSuccess implements Action {
  readonly type = LOAD_SERVICE_RECEIVERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadServiceReceiversFailure implements Action {
  readonly type = LOAD_SERVICE_RECEIVERS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadByUserId implements Action {
  readonly type = LOAD_BY_USER_ID;

  constructor(public payload: string) {}
}

export class LoadByUserIdSuccess implements Action {
  readonly type = LOAD_BY_USER_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByUserIdFailure implements Action {
  readonly type = LOAD_BY_USER_ID_FAILURE;

  constructor(public payload: string) {}
}

export class AddEmployee implements Action {
  readonly type = ADD_EMPLOYEE;

  constructor(public payload: { institutionId: string, employeeId: string }) {}
}

export class AddEmployeeSuccess implements Action {
  readonly type = ADD_EMPLOYEE_SUCCESS;

  constructor(public payload: any) {}
}

export class AddEmployeeFailure implements Action {
  readonly type = ADD_EMPLOYEE_FAILURE;

  constructor(public payload: string) {}
}

export class RemoveEmployee implements Action {
  readonly type = REMOVE_EMPLOYEE;

  constructor(public payload: { institutionId: string, employeeId: string }) {}
}

export class RemoveEmployeeSuccess implements Action {
  readonly type = REMOVE_EMPLOYEE_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveEmployeeFailure implements Action {
  readonly type = REMOVE_EMPLOYEE_FAILURE;

  constructor(public payload: string) {}
}

export class AddServiceReceiver implements Action {
  readonly type = ADD_SERVICE_RECEIVER;

  constructor(public payload: { institutionId: string, serviceReceiverId: string }) {}
}

export class AddServiceReceiverSuccess implements Action {
  readonly type = ADD_SERVICE_RECEIVER_SUCCESS;

  constructor(public payload: any) {}
}

export class AddServiceReceiverFailed implements Action {
  readonly type = ADD_SERVICE_RECEIVER_FAILED;

  constructor(public payload: string) {}
}

export class RemoveServiceReceiver implements Action {
  readonly type = REMOVE_SERVICE_RECEIVER;

  constructor(public payload: { institutionId: string, serviceReceiverId: string }) {}
}

export class RemoveServiceReceiverSuccess implements Action {
  readonly type = REMOVE_SERVICE_RECEIVER_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveServiceReceiverFailed implements Action {
  readonly type = REMOVE_SERVICE_RECEIVER_FAILED;

  constructor(public payload: string) {}
}

export class LoadManager implements Action {
  readonly type = LOAD_MANAGER;

  constructor(public payload: {institutionId: string, managerId: string}) {}
}

export class LoadManagerSuccess implements Action {
  readonly type = LOAD_MANAGER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadManagerFailed implements Action {
  readonly type = LOAD_MANAGER_FAILED;

  constructor(public payload: string) {}
}

export type All
  = Load | LoadSuccess | LoadFailure | LoadMore | LoadMoreSuccess | LoadMoreFailure | LoadById | LoadByIdSuccess |
  LoadByIdFailure | Search | SearchFailed | SearchSuccess | LoadChildren | LoadChildrenSuccess | LoadChildrenFailure |
  LoadEmployees | LoadEmployeesSuccess | LoadEmployeesFailure | LoadServiceReceivers | LoadServiceReceiversSuccess |
  LoadServiceReceiversFailure | LoadByUserId | LoadByUserIdSuccess | LoadByUserIdFailure | AddEmployee |
  AddEmployeeSuccess | AddEmployeeFailure | RemoveEmployee | RemoveEmployeeSuccess | RemoveEmployeeFailure |
  AddServiceReceiver | AddServiceReceiverSuccess | AddServiceReceiverFailed | RemoveServiceReceiver |
  RemoveServiceReceiverFailed | RemoveServiceReceiverSuccess | LoadManager | LoadManagerSuccess | LoadManagerFailed;

import * as UserActions from './user.actions';
import {addEntities, addEntity, handleError, sortEntitiesById} from '../helpers';

export type Action = UserActions.All;

export interface UserState {
  loading: boolean;
  entities: any[];
  entitiesById: any;
  nextUrl: string;
  error: string;
  searchResults: any[];
  message: string;
  viewImage: any;
}

const initialState: UserState = {
  loading: null,
  entities: null,
  entitiesById: null,
  nextUrl: null,
  error: null,
  searchResults: null,
  message: null,
  viewImage: null
};

export function reducer(state: UserState = initialState, {type, payload}: Action): UserState {
  switch (type) {
    case UserActions.LOAD:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case UserActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        nextUrl: payload.next,
        error: null
      });
    case UserActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.LOAD_MORE:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case UserActions.LOAD_MORE_SUCCESS:
      const added = addEntities(state.entities, payload.results);

      return Object.assign({}, state, {
        loading: false,
        entities: added,
        entitiesById: sortEntitiesById(added),
        nextUrl: payload.next
      });
    case UserActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        error: payload
      });
    case UserActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_BY_ID_SUCCESS:
      const updated = addEntity(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        entities: updated,
        entitiesById: sortEntitiesById(updated),
      });
    case UserActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.RESET:
      return Object.assign({}, state, initialState);
    case UserActions.SEARCH:
      return Object.assign({}, state, {
        loading: true
      });
    case UserActions.SEARCH_SUCCESS:
      return Object.assign({}, state, {
        searchResults: payload.results,
        loading: false
      });
    case UserActions.SEARCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.UPDATE:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.UPDATE_SUCCESS:
      const update = state.entities.map(entity => entity.url === payload.url ? payload : entity);

      return Object.assign({}, state, {
        loading: false,
        entities: update,
        entitiesById: sortEntitiesById(update),
      });
    case UserActions.UPDATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.UPDATE_PROFILE_IMAGE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.UPDATE_PROFILE_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'UPDATE_PROFILE_IMAGE_SUCCESS',
        viewImage: payload
      });
    case UserActions.UPDATE_PROFILE_IMAGE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: UserState) => state.loading;
export const getEntities = (state: UserState) => state.entities;
export const getEntitiesById = (state: UserState) => state.entitiesById;
export const getSearchResults = (state: UserState) => state.searchResults;
export const getNextUrl = (state: UserState) => state.nextUrl;
export const getError = (state: UserState) => state.error;
export const getMessage = (state: UserState) => state.message;
export const getViewImage = (state: UserState) => state.viewImage;

import * as EntryActions from './entry.actions';
import {
  sortEntitiesById, updateEntity, handleError, addEntities, sortEntitiesByOwnerId
} from '../helpers';

export type Action = EntryActions.All;

export interface EntryState {
  loading: boolean;
  loadingMore: boolean;
  message: string;
  entities: any[];
  entitiesByOwner: any;
  entitiesById: any;
  nextUrl: string;
  error: string;
  ownerNextUrl: string;
  comments: any;
  pinnedPosts: any[];
}

const initialState: EntryState = {
  loading: null,
  loadingMore: null,
  message: null,
  entities: null,
  entitiesByOwner: null,
  entitiesById: null,
  nextUrl: null,
  error: null,
  ownerNextUrl: null,
  comments: null,
  pinnedPosts: null
};

function updateCared(entities: any[], payload: any): any[] {
  return entities.map(entity => {
    if (entity.id === payload.id) {
      return Object.assign({}, entity, {
        cared: payload.cared
      });
    } else {
      return entity;
    }
  });
}

export function reducer(state: EntryState = initialState, {type, payload}: Action): EntryState {
  switch (type) {
    case EntryActions.LOAD:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });

    case EntryActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        entitiesById: sortEntitiesById(payload.results),
        entitiesByOwner: sortEntitiesByOwnerId(payload.results),
        nextUrl: payload.next
      });

    case EntryActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.LOAD_MORE:
      return Object.assign({}, state, {
        loadingMore: true,
        errorMessage: null
      });

    case EntryActions.LOAD_MORE_SUCCESS:
      const loadedMore = [].concat(...state.entities, payload.results);

      return Object.assign({}, state, {
        loadingMore: false,
        entities: loadedMore,
        entitiesById: sortEntitiesById(loadedMore),
        entititesByOwner: sortEntitiesByOwnerId(loadedMore),
        nextUrl: payload.next
      });

    case EntryActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        loadingMore: false,
        error: handleError(payload)
      });
    case EntryActions.TOGGLE_CARED:
      const entities = state.entities.map((entry) => {
        if (entry.id === payload.entry.id) {
          const index = entry.cared.findIndex((user) => user.id === payload.user.id);

          if (index === -1) {
            return Object.assign({}, entry, {
              cared: [].concat(...entry.cared, payload.user)
            });
          } else {
            return Object.assign({}, entry, {
              cared: entry.cared.slice(index, 1)
            });
          }
        } else {
          return entry;
        }
      });

      return Object.assign({}, state, {
        entities: entities,
        entitiesById: sortEntitiesById(entities),
        entitiesByOwner: sortEntitiesByOwnerId(entities)
      });
    case EntryActions.TOGGLE_CARED_SUCCESS:
      const updateEntry = updateCared(state.entities, payload);

      return Object.assign({}, state, {
        entities: updateEntry,
        entitiesById: sortEntitiesById(updateEntry),
        entitiesByOwner: sortEntitiesByOwnerId(updateEntry)
      });

    case EntryActions.DELETE:
      return Object.assign({}, state, {
        loading: true,
        message: 'DELETING'
      });

    case EntryActions.DELETE_SUCCESS:
      const filtered = state.entities.filter(entity => (entity.id !== payload.id));

      return Object.assign({}, state, {
        loading: false,
        message: null,
        entities: filtered,
        entitiesById: sortEntitiesById(filtered),
        entitiesByOwner: sortEntitiesByOwnerId(filtered)
      });

    case EntryActions.DELETE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: handleError(payload)
      });

    case EntryActions.LOAD_FEED_BY_OWNER:
      return Object.assign({}, state, {
        loading: true
      });

    case EntryActions.LOAD_FEED_BY_OWNER_SUCCESS:
      const feed = (state.entities) ? addEntities(state.entities, payload.results) : payload.results;

      return Object.assign({}, state, {
        loading: false,
        entities: feed,
        entitiesById: sortEntitiesById(feed),
        entitiesByOwner: sortEntitiesByOwnerId(feed),
        ownerNextUrl: payload.next
      });

    case EntryActions.LOAD_FEED_BY_OWNER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.LOAD_MORE_FEED:
      return Object.assign({}, state, {
        loadingMore: true,
        errorMessage: null
      });

    case EntryActions.LOAD_MORE_FEED_SUCCESS:
      const loadedMoreFeed = [].concat(...state.entities, payload.results);

      return Object.assign({}, state, {
        loadingMore: false,
        entities: loadedMoreFeed,
        entitiesById: sortEntitiesById(loadedMoreFeed),
        entitiesByOwner: sortEntitiesByOwnerId(loadedMoreFeed),
        ownerNextUrl: payload.next
      });

    case EntryActions.LOAD_MORE_FEED_FAILURE:
      return Object.assign({}, state, {
        loadingMore: false,
        error: handleError(payload)
      });

    case EntryActions.UPDATE:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: 'UPDATING'
      });

    case EntryActions.UPDATE_SUCCESS:
      const updated = updateEntity(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        entities: updated,
        entitiesById: sortEntitiesById(updated),
        entitiesByOwner: sortEntitiesByOwnerId(updated),
        error: null,
        message: 'UPDATE_SUCCESS'
      });

    case EntryActions.UPDATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    case EntryActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        loading: true
      });

    case EntryActions.LOAD_BY_ID_SUCCESS:
      let added: any[];

      if (state.entities) {
        const loaded = state.entities.findIndex(entry => entry.id === payload.id) !== -1;
        if (loaded) {
          added = updateEntity(state.entities, payload);
        } else {
          added = [].concat(...state.entities, payload);
        }
      } else {
        added = [].concat(payload);
      }

      return Object.assign({}, state, {
        loading: false,
        entities: added,
        entitiesById: sortEntitiesById(added),
        entitiesByOwner: sortEntitiesByOwnerId(added)
      });

    case EntryActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.LOAD_TEMP_IMAGES_SUCCESS:
      let entitiesTemp;
      if (payload.isArray) {
        entitiesTemp = [].concat(...payload, ...state.entities);
      } else {
        entitiesTemp = [].concat(...[payload], ...state.entities);
      }

      return Object.assign({}, state, {
        message: 'PUBLISH_ENTRY_SUCCESS',
        entities: entitiesTemp,
        entitiesById: sortEntitiesById(entitiesTemp),
        entitiesByOwner: sortEntitiesByOwnerId(entitiesTemp)
      });


      /*****    COMMENTS    *****/


    case EntryActions.LOAD_COMMENTS:
      return Object.assign({}, state, {
        loading: true,
        message: 'LOADING_COMMENTS',
        error: null
      });

    case EntryActions.LOAD_COMMENTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        comments: payload,
        error: null
      });

    case EntryActions.LOAD_COMMENTS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.COMMENT:
      return Object.assign({}, state, {
        loading: true,
        message: 'PUBLISHING',
        error: null
      });

    case EntryActions.COMMENT_SUCCESS:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        comments: [].concat(...state.comments, ...[payload])
      });

    case EntryActions.COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.DELETE_COMMENT:
      return Object.assign({}, state, {
        message: 'DELETING_COMMENT',
        error: null,
        loading: true
      });

    case EntryActions.DELETE_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        comments: state.comments.filter(comment => (comment.id !== payload.id))
      });

    case EntryActions.DELETE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: handleError(payload),
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.UPDATE_COMMENT:
      return Object.assign({}, state, {
        loading: true,
        message: 'UPDATING_COMMENT',
        error: null
      });

    case EntryActions.UPDATE_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        comments: state.comments.map(comment => (comment.id === payload.id) ? payload : comment)
      });

    case EntryActions.UPDATE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: handleError(payload),
        loading: false,
        error: handleError(payload)
      });

    case EntryActions.RESET_STATE_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });

    case EntryActions.RESET:
      return Object.assign({}, state, initialState);

    case EntryActions.LOAD_PINNED_POSTS:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });

    case EntryActions.LOAD_PINNED_POSTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        message: 'LOAD_PINNED_POSTS_SUCCESS',
        pinnedPosts: payload.results
      });

    case EntryActions.LOAD_PINNED_POSTS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    default:
      return state;
  }
}

export const getLoadingState = (state: EntryState) => state.loading;
export const getLoadingMoreState = (state: EntryState) => state.loadingMore;
export const getLoadingMessage = (state: EntryState) => state.message;
export const getEntities = (state: EntryState) => state.entities;
export const getEntitiesByOwner = (state: EntryState) => state.entitiesByOwner;
export const getEntitiesById = (state: EntryState) => state.entitiesById;
export const getNextUrl = (state: EntryState) => state.nextUrl;
export const getError = (state: EntryState) => state.error;
export const getMessage = (state: EntryState) => state.message;
export const getContentObjectNextUrl = (state: EntryState) => state.ownerNextUrl;
export const getComments = (state: EntryState) => state.comments;
export const getPinnedPosts = (state: EntryState) => state.pinnedPosts;

import * as GroupActions from './groups.actions';
import {addEntity, handleError, sortEntitiesById, sortEntitiesByUrl} from '../helpers';

export type Action = GroupActions.All;

export interface GroupState {
  loading: boolean;
  loadingMore: boolean;
  entities: any[];
  entitiesById: any;
  nextUrl: string;
  error: string;
  message: string;
  searchResult: any;
  entitiesByUser: any;
}

const initialState: GroupState = {
  loading: false,
  loadingMore: false,
  entities: null,
  entitiesById: null,
  nextUrl: null,
  error: null,
  message: null,
  searchResult: null,
  entitiesByUser: null
};

export function reducer(state: GroupState = initialState, {type, payload}: Action): GroupState {
  switch (type) {
    case GroupActions.LOAD:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });
    case GroupActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        loadingMore: false,
        entities: payload.results,
        entitiesById: sortEntitiesById(payload.results),
        nextUrl: payload.next,
        error: null,
        message: null
      });
    case GroupActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });
    case GroupActions.LOAD_MORE:
      return Object.assign({}, state, {
        loadingMore: true,
        error: null,
        message: null
      });
    case GroupActions.LOAD_MORE_SUCCESS:
      const more = [].concat(...state.entities, payload);

      return Object.assign({}, state, {
        loadingMore: false,
        entities: more,
        entitiesById: sortEntitiesById(more),
      });
    case GroupActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        loadingMore: false,
        error: handleError(payload),
        message: null
      });
    case GroupActions.SEARCH_SUCCESS:
      return Object.assign({}, state, {
        searchResult: payload.results,
        loading: false,
        message: 'SUCCESS_MESSAGE'
      });
    case GroupActions.SEARCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case GroupActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_BY_ID_SUCCESS:
      const added = addEntity(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        entities: added,
        entitiesById: sortEntitiesById(added),
      });
    case GroupActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload.error)
      });
    case GroupActions.CREATE:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.CREATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'GROUP_CREATED',
        error: null
      });
    case GroupActions.CREATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload.error)
      });
    case GroupActions.LOAD_BY_USER_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case GroupActions.LOAD_BY_USER_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByUser: Object.assign({}, state.entitiesByUser, {
          [payload.userId]: payload.result
        })
      });
    case GroupActions.LOAD_BY_USER_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: GroupState) => state.loading;
export const getLoadingMoreState = (state: GroupState) => state.loadingMore;
export const getGroups = (state: GroupState) => state.entities;
export const getGroupsById = (state: GroupState) => state.entitiesById;
export const getGroupsNextUrl = (state: GroupState) => state.nextUrl;
export const getError = (state: GroupState) => state.error;
export const getMessage = (state: GroupState) => state.message;
export const getSearchResults = (state: GroupState) => state.searchResult;
export const getByUserId = (state: GroupState) => state.entitiesByUser;

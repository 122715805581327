import * as CreateServiceReceiverActions from './create-service-receiver.actions';
import {handleError} from '../helpers';

export type Action = CreateServiceReceiverActions.All;

export interface CreateServiceReceiverState {
  loading: boolean;
  message: string;
  errorMessage: string;
}

const initialState: CreateServiceReceiverState = {
  loading: null,
  message: null,
  errorMessage: null
};

export function reducer(state: CreateServiceReceiverState = initialState, {type, payload}: Action): CreateServiceReceiverState {
  switch (type) {
    case CreateServiceReceiverActions.CREATE_SUBSCRIPTION:
      return Object.assign({}, state, {
        loading: true,
        message: 'CREATING_SUBSCRIPTION',
        errorMessage: null
      });
    case CreateServiceReceiverActions.CREATE_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'SUBSCRIPTION_CREATED'
      });
    case CreateServiceReceiverActions.CREATE_SUBSCRIPTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        errorMessage: handleError(payload.error)
      });
    case CreateServiceReceiverActions.RESET_MESSAGE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        errorMessage: null
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: CreateServiceReceiverState) => state.loading;
export const getErrorMessage = (state: CreateServiceReceiverState) => state.errorMessage;
export const getMessage = (state: CreateServiceReceiverState) => state.message;

import { Injectable } from '@angular/core';
import { AUTH_TOKEN } from './config';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiService {

  constructor(protected _ls: LocalStorageService) {}

  protected _getHeaders() {
    const token = this._ls.retrieve(AUTH_TOKEN);
    return new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
  }

}

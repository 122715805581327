import * as NavBarButtonActions from './nav-bar-button.actions';

export type Action = NavBarButtonActions.All;

export interface NavBarButtonState {
  title: string;
  event: string;
}

const initialState: NavBarButtonState = {
  title: null,
  event: null
};

export function reducer(state: NavBarButtonState = initialState, {type, payload}: Action): NavBarButtonState {
  switch (type) {
    case NavBarButtonActions.SET_TITLE:
      return Object.assign({}, state, {
        title: payload
      });
    case NavBarButtonActions.RAISE_EVENT:
      return Object.assign({}, state, {
        event: payload
      });
    case NavBarButtonActions.RESET_EVENT:
      return Object.assign({}, state, {
        event: null
      });
    case NavBarButtonActions.RESET:
      return Object.assign({}, state, {
        title: null,
        event: null
      });
    default:
      return state;
  }
}

export const getTitle = (state: NavBarButtonState) => state.title;
export const getEvent = (state: NavBarButtonState) => state.event;

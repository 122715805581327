import {Component, OnDestroy, OnInit} from '@angular/core';
import * as NavBarButtonActions from '../../store/nav-bar-button/nav-bar-button.actions';
import {Store, select} from '@ngrx/store';
import * as rootState from '../../store/index';
import {Subscription} from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-nav-bar-button, [app-nav-bar-button]',
  template: `
    <div class="nav__mobile-item-text" (click)="onClicked()">{{ title | translate }}</div>
  `
})
export class NavBarButtonComponent implements OnInit, OnDestroy {
  title: string;
  publishing: boolean;

  private _subscription: Subscription;
  private _publishingSubscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>) {}

  ngOnInit(): void {
    this._subscription = this._store.pipe(select(rootState.getNavBarButtonTitle))
      .subscribe(title => this.title = title);
    this._publishingSubscription = this._store.pipe(select(rootState.getPublishState))
      .subscribe(state => this.publishing = state);
  }

  onClicked(): void {
    if (!this.publishing) {
      this._store.dispatch(new NavBarButtonActions.RaiseEvent(this.title));
      setTimeout(() => this._store.dispatch(new NavBarButtonActions.ResetEvent()), 500);
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

}

import {Action} from '@ngrx/store';
import construct = Reflect.construct;

export const LOAD = '[Entries] load';
export const LOAD_SUCCESS = '[Entries] load success';
export const LOAD_FAILURE = '[Entries] load failed';
export const LOAD_MORE = '[Entries] load more';
export const LOAD_MORE_SUCCESS = '[Entries] load more success';
export const LOAD_MORE_FAILURE = '[Entries] load more failed';
export const TOGGLE_CARED = '[Entries] toggle cared';
export const TOGGLE_CARED_SUCCESS = '[Entries] toggle cared success';
export const TOGGLE_CARED_FAILURE = '[Entries] toggle cared failed';
export const COMMENT = '[Entry]';
export const COMMENT_SUCCESS = '[Entry] success';
export const COMMENT_FAILURE = '[Entry] failure';
export const DELETE_COMMENT = '[Entry] delete comment';
export const DELETE_COMMENT_SUCCESS = '[Entry] delete comment success';
export const DELETE_COMMENT_FAILURE = '[Entry] delete comment failure';
export const LOAD_COMMENTS = '[Entry] load comments';
export const LOAD_COMMENTS_SUCCESS = '[Entry] load comments success';
export const LOAD_COMMENTS_FAILURE = '[Entry] load comments failure';
export const DELETE = '[Entry] delete';
export const DELETE_SUCCESS = '[Entry] delete success';
export const DELETE_FAILURE = '[Entry] delete failure';
export const LOAD_FEED_BY_OWNER = '[Entry] load feed by owner';
export const LOAD_FEED_BY_OWNER_SUCCESS = '[Entry] load feed by owner success';
export const LOAD_FEED_BY_OWNER_FAILURE = '[Entry] load feed by owner failure';
export const LOAD_MORE_FEED = '[Entry] load more feed';
export const LOAD_MORE_FEED_SUCCESS = '[Entry] load more feed success';
export const LOAD_MORE_FEED_FAILURE = '[Entry] load more feed failure';
export const UPDATE = '[Entry] update';
export const UPDATE_SUCCESS = '[Entry] update success';
export const UPDATE_FAILURE = '[Entry] update failure';
export const RESET_STATE_MESSAGE = '[Entry] reset state message';
export const RESET = '[Entry] reset';
export const LOAD_BY_ID = '[Entry] load by id';
export const LOAD_BY_ID_SUCCESS = '[Entry] load by id success';
export const LOAD_BY_ID_FAILURE = '[Entry] load by id failure';
export const UPDATE_COMMENT = '[Entry] update comment';
export const UPDATE_COMMENT_SUCCESS = '[Entry] update comment success';
export const UPDATE_COMMENT_FAILURE = '[Entry] update comment failure';
export const LOAD_TEMP_IMAGES_SUCCESS = '[Entry] Load temporary images';
export const LOAD_TEMP_IMAGES_FAILURE = '[Entry] Load temporary images';
export const LOAD_PINNED_POSTS = '[Entry] load pinned posts';
export const LOAD_PINNED_POSTS_SUCCESS = '[Entry] load pinned posts success';
export const LOAD_PINNED_POSTS_FAILURE = '[Entry] load pinned posts failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload?: any) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class ToggleCared implements Action {
  readonly type = TOGGLE_CARED;

  constructor(public payload: any) {}
}

export class ToggleCaredSuccess implements Action {
  readonly type = TOGGLE_CARED_SUCCESS;

  constructor(public payload: any) {}
}

export class ToggleCaredFailure implements Action {
  readonly type = TOGGLE_CARED_FAILURE;

  constructor(public payload: string) {}
}

export class Comment implements Action {
  readonly type = COMMENT;

  constructor(public payload: any) {}
}

export class CommentSuccess implements Action {
  readonly type = COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class CommentFailure implements Action {
  readonly type = COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class DeleteComment implements Action {
  readonly type = DELETE_COMMENT;

  constructor(public payload: any) {}
}

export class DeleteCommentSuccess implements Action {
  readonly type = DELETE_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteCommentFailure implements Action {
  readonly type = DELETE_COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class LoadComments implements Action {
  readonly type = LOAD_COMMENTS;

  constructor(public payload: any) {}
}

export class LoadCommentsSuccess implements Action {
  readonly type = LOAD_COMMENTS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadCommentsFailure implements Action {
  readonly type = LOAD_COMMENTS_FAILURE;

  constructor(public payload: string) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public payload: any) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteFailure implements Action {
  readonly type = DELETE_FAILURE;

  constructor(public payload: string) {}
}

export class LoadFeedByOwner implements Action {
  readonly type = LOAD_FEED_BY_OWNER;

  constructor(public payload?: any) {}
}

export class LoadFeedByOwnerSuccess implements Action {
  readonly type = LOAD_FEED_BY_OWNER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFeedByOwnerFailure implements Action {
  readonly type = LOAD_FEED_BY_OWNER_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreFeed implements Action {
  readonly type = LOAD_MORE_FEED;

  constructor(public payload: any) {}
}

export class LoadMoreFeedSuccess implements Action {
  readonly type = LOAD_MORE_FEED_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreFeedFailure implements Action {
  readonly type = LOAD_MORE_FEED_FAILURE;

  constructor(public payload: string) {}
}


export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: { entry: any, images: File[], deleteIds: string[], pin: boolean }) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: string) {}
}

export class ResetStateMessage implements Action {
  readonly type = RESET_STATE_MESSAGE;

  constructor(public payload?: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: string) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateComment implements Action {
  readonly type = UPDATE_COMMENT;

  constructor(public payload: string) {}
}

export class UpdateCommentSuccess implements Action {
  readonly type = UPDATE_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateCommentFailure implements Action {
  readonly type = UPDATE_COMMENT_FAILURE;

  constructor(public payload: any) {}
}

export class LoadTempImagesSuccess implements Action {
  readonly type = LOAD_TEMP_IMAGES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadTempImagesFailure implements Action {
  readonly type = LOAD_TEMP_IMAGES_FAILURE;

  constructor(public payload: any) {}
}

export class LoadPinnedPosts implements Action {
  readonly type  = LOAD_PINNED_POSTS;

  constructor(public payload?: any) {}
}

export class LoadPinnedPostsSuccess implements Action {
  readonly type  = LOAD_PINNED_POSTS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadPinnedPostsFailure implements Action {
  readonly type  = LOAD_PINNED_POSTS_FAILURE;

  constructor(public payload: any) {}
}

export type All = Load | LoadSuccess | LoadFailure | LoadMore | LoadMoreSuccess | LoadMoreFailure | ToggleCared |
  ToggleCaredSuccess | ToggleCaredFailure | Comment | CommentSuccess | CommentFailure | DeleteComment |
  DeleteCommentSuccess | DeleteCommentFailure | Delete | DeleteSuccess | DeleteFailure |
  LoadFeedByOwner | LoadFeedByOwnerSuccess | LoadFeedByOwnerFailure |LoadMoreFeed | LoadMoreFeedSuccess | LoadMoreFeedFailure | Update |
  UpdateSuccess | UpdateFailure | ResetStateMessage | Reset | LoadById | LoadByIdSuccess | LoadByIdFailure |
  UpdateComment | UpdateCommentSuccess | UpdateCommentFailure | LoadTempImagesFailure | LoadTempImagesSuccess | LoadComments |
  LoadCommentsFailure | LoadCommentsSuccess | LoadPinnedPosts | LoadPinnedPostsFailure | LoadPinnedPostsSuccess;

import * as FCMActions from './fcm.actions';
import {sortEntitiesByDeviceToken} from '../helpers';

export type Action = FCMActions.All;

export interface FCMState {
  loading: boolean;
  entities: any[];
  error: string;
  message: string;
}

const initialState: FCMState = {
  loading: false,
  entities: null,
  error: null,
  message: null
};

export function reducer(state: FCMState = initialState, {type, payload}: Action): FCMState {
  switch (type) {
    case FCMActions.LOAD:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case FCMActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload
      });
    case FCMActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });

    case FCMActions.REMOVE_DEVICE:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case FCMActions.REMOVE_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'DEVICE_REMOVE_FROM_PUSH'
      });
    case FCMActions.REMOVE_DEVICE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: FCMState) => state.loading;
export const getEntities = (state: FCMState) => state.entities;
export const getMessage = (state: FCMState) => state.message;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import * as UserActions from './user.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class UserEffects {

  @Effect() load$: Observable<Action> = this._actions.pipe(
    ofType(UserActions.LOAD),
    switchMap(_ => {
      return this._service.load().pipe(
        map(data => new UserActions.LoadSuccess(data)),
        catchError(response => of(new UserActions.LoadFailure(response)))
      );
    }),
    catchError(response => of(new UserActions.LoadFailure(response)))
  );

  @Effect() userSearch$: Observable<Action> = this._actions.pipe(
    ofType(UserActions.SEARCH),
    map((action: UserActions.Search) => action.payload),
    switchMap(payload => {
      return this._service.search(payload.searchTerm).pipe(
        map(data => new UserActions.SearchSuccess(data)),
        catchError(response => of(new UserActions.SearchFailure(response)))
      );
    }),
    catchError(response => of(new UserActions.SearchFailure(response)))
  );

  @Effect() loadById$: Observable<Action> = this._actions.pipe(
    ofType(UserActions.LOAD_BY_ID),
    map((action: UserActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload).pipe(
        map(data => new UserActions.LoadByIdSuccess(data)),
        catchError(response => of(new UserActions.LoadByIdFailure(response)))
      );
    }),
    catchError(response => of(new UserActions.LoadByIdFailure(response)))
  );

  @Effect() loadMore$: Observable<Action> = this._actions.pipe(
    ofType(UserActions.LOAD_MORE),
    map((action: UserActions.LoadMore) => action.payload),
    switchMap(payload => {
      return this._service.loadByUrl(payload).pipe(
        map(data => new UserActions.LoadMoreSuccess(data)),
        catchError(response => of(new UserActions.LoadMoreFailure(response)))
      );
    }),
    catchError(response => of(new UserActions.LoadMoreFailure(response)))
  );

  @Effect() update$: Observable<Action> = this._actions.pipe(
    ofType(UserActions.UPDATE),
    map((action: UserActions.Update) => action.payload),
    switchMap(payload => {
      return this._service.update(payload).pipe(
        map(data => new UserActions.UpdateSuccess(data)),
        catchError(response => of(new UserActions.UpdateFailure(response)))
      );
    }),
    catchError(response => of(new UserActions.UpdateFailure(response)))
  );

  @Effect() updateProfileImage$: Observable<Action> = this._actions.pipe(
    ofType(UserActions.UPDATE_PROFILE_IMAGE),
    map((action: UserActions.UpdateProfileImage) => action.payload),
    switchMap(payload => {
      return this._service.updateProfileImage(payload.userId, payload.image).pipe(
        map(data => new UserActions.UpdateProfileImageSuccess(data)),
        catchError(response => of(new UserActions.UpdateProfileImageFailure(response)))
      );
    }),
    catchError(response => of(new UserActions.UpdateProfileImageFailure(response)))
  );

  constructor(private _actions: Actions, private _service: UserService) {
  }
}

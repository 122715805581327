import {Action}  from '@ngrx/store';

export const CHECK_PERMISSIONS = '[Permissions] check permissions';
export const CHECK_PERMISSIONS_SUCCESS = '[Permissions] check permissions success';
export const CHECK_PERMISSIONS_FAILURE = '[Permissions] check permissions failure';

export class CheckPermissions implements Action {
  readonly type = CHECK_PERMISSIONS;

  constructor(public payload: any) {}
}

export class CheckPermissionsSuccess implements Action {
  readonly type = CHECK_PERMISSIONS_SUCCESS;

  constructor(public payload?: any) {}
}

export class CheckPermissionsFailure implements Action {
  readonly type = CHECK_PERMISSIONS_FAILURE;

  constructor(public payload?: any) {}
}

export type ALL = CheckPermissions | CheckPermissionsFailure | CheckPermissionsSuccess;

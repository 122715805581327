import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as rootState from '../store/index';
import * as NavActions from '../store/nav/nav.actions';

@Injectable()
export class NavService {
  navEvents$: Subject<any> = new Subject();
  pageTitle$: Subject<any> = new Subject();

  rootRoutes: string[] = [
    'home', 'service-receiver', 'group', 'message', 'more', 'profile', 'calendar', 'message?tab=messages', 'message?tab=pinned-messages',
    'message?tab=dialogs'
  ];

  childRoutes: string[] = [
    'create-entry', 'create-message', 'message-detail', 'entry-detail', 'edit-entry', 'edit-message', 'event-detail',
    'service-receiver-detail', 'group-detail', 'user', 'my-profile', 'settings', 'create-event',
    'create-group', 'create-user', 'user', 'institution', 'support', 'institution-detail'
  ];

  constructor(private _store: Store<rootState.IAppState>) {}

  setPageTitle(title: string) {
    this._store.dispatch(new NavActions.SetTitle(title));
  }

  showBackButton(eventUrl: string): boolean {
    const url = this._parseUrl(eventUrl);
    return this.childRoutes.indexOf(url) !== -1;
  }

  getNavMode(loggedInUser: any, serviceReceivers: any[]): string {
    if (loggedInUser) {
      if (loggedInUser.is_employee) {
        return 'multiple';
      } else if (loggedInUser.is_service_receiver) {
        return 'single';
      } else {
        if (serviceReceivers) {
          if (serviceReceivers.length === 0) {
            return 'empty';
          } else if (serviceReceivers.length === 1) {
            return 'single';
          } else {
            return 'multiple';
          }
        }
      }
    }
  }

  isRootRoute(currentRoute: string) {
    return this.rootRoutes.indexOf(this._parseUrl(currentRoute)) !== -1;
  }

  private _parseUrl(url: string): string {
    const path = url.split('/');
    return path[1];
  }

}

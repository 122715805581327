import {Action} from '@ngrx/store';

export const MAIN_SCROLL = '[UI] Main Scroll';
export const SCROLLED_PAST_CALENDAR_HEADER = '[UI] Scrolled past calendar header';

export class MainScroll implements Action {
  readonly type = MAIN_SCROLL;

  constructor(public payload: any) {}
}

export class ScrolledPastCalendarHeader implements Action {
  readonly type = SCROLLED_PAST_CALENDAR_HEADER;

  constructor(public payload: boolean) {}
}

export type All = MainScroll | ScrolledPastCalendarHeader;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {RelationService} from '../../core/relation.service';
import * as RelationActions from './relation.actions';
import {Observable} from 'rxjs';


import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class RelationEffects {

  @Effect() loadRelations$: Observable<Action> = this._actions.pipe(
    ofType(RelationActions.LOAD_BY_USER),
    map((action: RelationActions.LoadByUser) => action.payload),
    switchMap(payload => {
      return this._service.loadByUser(payload).pipe(
        map(data => new RelationActions.LoadByUserSuccess(data)),
        catchError(response => of(new RelationActions.LoadByUserFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _service: RelationService) {
  }
}

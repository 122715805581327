import {Action} from '@ngrx/store';

export const PUBLISH_MESSAGE = '[Create] publish message';
export const PUBLISH_MESSAGE_SUCCESS = '[Create] publish message success';
export const PUBLISH_MESSAGE_FAILURE = '[Create] publish message failure';
export const PUBLISH_ENTRY = '[Create] publish entry';
export const PUBLISH_ENTRY_SUCCESS = '[Create] publish entry success';
export const PUBLISH_ENTRY_FAILURE = '[Create] publish entry failure';
export const PUBLISH_EVENT = '[Create] publish event';
export const PUBLISH_EVENT_SUCCESS = '[Create] publish event success';
export const PUBLISH_EVENT_FAILURE = '[Create] publish event failure';
export const BULK_PUBLISH_MESSAGE = '[Create] bulk publish message';
export const BULK_PUBLISH_MESSAGE_SUCCESS = '[Create] bulk publish message success';
export const BULK_PUBLISH_MESSAGE_FAILURE = '[Create] bulk publish message failure';
export const BULK_PUBLISH_ENTRY = '[Create] bulk publish entry';
export const BULK_PUBLISH_ENTRY_SUCCESS = '[Create] bulk publish entry success';
export const BULK_PUBLISH_ENTRY_FAILURE = '[Create] bulk publish entry failure';
export const BULK_PUBLISH_EVENT = '[Create] bulk publish event';
export const BULK_PUBLISH_EVENT_SUCCESS = '[Create] bulk publish event success';
export const BULK_PUBLISH_EVENT_FAILURE = '[Create] bulk publish event failure';
export const CREATE_USER = '[Create] create user';
export const CREATE_USER_SUCCESS = '[Create] create user success';
export const CREATE_USER_FAILURE = '[Create] create user failure';
export const RESET_STATE_MESSAGE = '[Create] reset state message';
export const RESET_STATE_ERROR = '[Create] reset state error';

export class PublishMessage implements Action {
  readonly type = PUBLISH_MESSAGE;

  constructor(public payload: any) {}
}

export class PublishMessageSuccess implements Action {
  readonly type = PUBLISH_MESSAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class PublishMessageFailure implements Action {
  readonly type = PUBLISH_MESSAGE_FAILURE;

  constructor(public payload: string) {}
}

export class PublishEntry implements Action {
  readonly type = PUBLISH_ENTRY;

  constructor(public payload: any) {}
}

export class PublishEntrySuccess implements Action {
  readonly type = PUBLISH_ENTRY_SUCCESS;

  constructor(public payload: any) {}
}

export class PublishEntryFailure implements Action {
  readonly type = PUBLISH_ENTRY_FAILURE;

  constructor(public payload: string) {}
}

export class PublishEvent implements Action {
  readonly type = PUBLISH_EVENT;

  constructor(public payload: any) {}
}

export class PublishEventSuccess implements Action {
  readonly type = PUBLISH_EVENT_SUCCESS;

  constructor(public payload: any) {}
}

export class PublishEventFailure implements Action {
  readonly type = PUBLISH_EVENT_FAILURE;

  constructor(public payload: string) {}
}

export class BulkPublishMessage implements Action {
  readonly type = BULK_PUBLISH_MESSAGE;

  constructor(public payload: any) {}
}

export class BulkPublishMessageSuccess implements Action {
  readonly type = BULK_PUBLISH_MESSAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class BulkPublishMessageFailure implements Action {
  readonly type = BULK_PUBLISH_MESSAGE_FAILURE;

  constructor(public payload: string) {}
}

export class BulkPublishEntry implements Action {
  readonly type = BULK_PUBLISH_ENTRY;

  constructor(public payload: {entry: any, images: File[], targets: any[]}) {}
}

export class BulkPublishEntrySuccess implements Action {
  readonly type = BULK_PUBLISH_ENTRY_SUCCESS;

  constructor(public payload: any) {}
}

export class BulkPublishEntryFailure implements Action {
  readonly type = BULK_PUBLISH_ENTRY_FAILURE;

  constructor(public payload: string) {}
}

export class BulkPublishEvent implements Action {
  readonly type = BULK_PUBLISH_EVENT;

  constructor(public payload: any) {}
}

export class BulkPublishEventSuccess implements Action {
  readonly type = BULK_PUBLISH_EVENT_SUCCESS;

  constructor(public payload: any) {}
}

export class BulkPublishEventFailure implements Action {
  readonly type = BULK_PUBLISH_EVENT_FAILURE;

  constructor(public payload: string) {}
}

export class CreateUser implements Action {
  readonly type = CREATE_USER;

  constructor(public payload: any) {}
}

export class CreateUserSuccess implements Action {
  readonly type = CREATE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateUserFailure implements Action {
  readonly type = CREATE_USER_FAILURE;

  constructor(public payload: string) {}
}

export class ResetStateMessage implements Action {
  readonly type = RESET_STATE_MESSAGE;

  constructor(public payload?: any) {}
}

export class ResetStateError implements Action {
  readonly type = RESET_STATE_ERROR;

  constructor(public payload?: any) {}
}

export type All = PublishMessage | PublishMessageSuccess | PublishMessageFailure | PublishEntry | PublishEntrySuccess |
  PublishEntryFailure | PublishEvent | PublishEventSuccess | PublishEventFailure | BulkPublishMessage |
  BulkPublishMessageSuccess | BulkPublishMessageFailure | BulkPublishEntry | BulkPublishEntrySuccess |
  BulkPublishEntryFailure | BulkPublishEvent | BulkPublishEventSuccess | BulkPublishEventFailure | CreateUser |
  CreateUserSuccess | CreateUserFailure | ResetStateMessage | ResetStateError;

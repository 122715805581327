import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {QAService} from '../../core/qa.service';
import * as SupportActions from './support.action';
import {Observable} from 'rxjs';


import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class SupportEffects {

  @Effect() sendFeedback$: Observable<Action> = this._actions.pipe(
    ofType(SupportActions.SEND_BUG_REPORT),
    map((action: SupportActions.SendBugReport) => action.payload),
    switchMap(payload => {
      return this._service.sendFeedback(payload).pipe(
        map(data => new SupportActions.SendBugReportSuccess(data)),
        catchError(response => of(new SupportActions.SendBugReportFailure(response)))
      );
    }),
    catchError(response => of(new SupportActions.SendBugReportFailure(response)))
  );

  constructor(private _actions: Actions, private _service: QAService) {
  }
}

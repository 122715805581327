import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {EntryService} from '../../core/entry.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as EntryActions from './entry.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class EntryEffects {

  @Effect() loadEntries$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD),
    switchMap(_ => {
      return this._service.load().pipe(
        map(data => new EntryActions.LoadSuccess(data)),
        catchError(response => of(new EntryActions.LoadFailure(response)))
      );
    })
  );

  @Effect() loadMoreEntries$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD_MORE),
    map((action: EntryActions.LoadMore) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload).pipe(
        map(data => new EntryActions.LoadMoreSuccess(data)),
        catchError(response => of(new EntryActions.LoadMoreFailure(response)))
      );
    })
  );

  @Effect() toggleCared$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.TOGGLE_CARED),
    map((action: EntryActions.ToggleCared) => action.payload),
    switchMap(payload => {
      return this._service.toggleCared(payload.entry, payload.user).pipe(
        map(data => new EntryActions.ToggleCaredSuccess(data)),
        catchError(response => of(new EntryActions.ToggleCaredFailure(response)))
      );
    })
  );

  @Effect() comment$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.COMMENT),
    map((action: EntryActions.Comment) => action.payload),
    switchMap(payload => {
      return this._service.comment(payload).pipe(
        map(data => new EntryActions.CommentSuccess(data)),
        catchError(response => of(new EntryActions.CommentFailure(response)))
      );
    })
  );

  @Effect() deleteComment$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.DELETE_COMMENT),
    map((action: EntryActions.DeleteComment) => action.payload),
    switchMap(payload => {
      return this._service.deleteComment(payload).pipe(
        map(data => new EntryActions.DeleteCommentSuccess(data)),
        catchError(response => of(new EntryActions.DeleteCommentFailure(response)))
      );
    })
  );

  @Effect() deleteEntry$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.DELETE),
    map((action: EntryActions.Delete) => action.payload),
    switchMap(payload => {
      return this._service.deleteEntry(payload).pipe(
        map(data => new EntryActions.DeleteSuccess(data)),
        catchError(response => of(new EntryActions.DeleteFailure(response)))
      );
    })
  );

  @Effect() loadFeedByOwner$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD_FEED_BY_OWNER),
    map((action: EntryActions.LoadFeedByOwner) => action.payload),
    switchMap(payload => {
      return this._service.loadFeedByOwner(payload).pipe(
        map(data => new EntryActions.LoadFeedByOwnerSuccess(data)),
        catchError(error => of(new EntryActions.LoadFeedByOwnerFailure(error)))
      );
    })
  );

  @Effect() loadMoreFeed$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD_MORE_FEED),
    map((action: EntryActions.LoadMoreFeed) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload).pipe(
        map(data => new EntryActions.LoadMoreFeedSuccess(data)),
        catchError(response => of(new EntryActions.LoadMoreFeedFailure(response)))
      );
    })
  );

  @Effect() update$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.UPDATE),
    map((action: EntryActions.Update) => action.payload),
    switchMap(payload => {
      return this._service.update(payload.entry, payload.images, payload.deleteIds, payload.pin).pipe(
        map(data => new EntryActions.UpdateSuccess(data)),
        catchError(error => of(new EntryActions.UpdateFailure(error)))
      );
    })
  );

  /*@Effect() created1$: Observable<Action> = this.actions$.ofType(CreateActions.BULK_PUBLISH_ENTRY_SUCCESS)
    .switchMap(payload => Observable.of(new EntryActions.Load()));

  @Effect() created2$: Observable<Action> = this.actions$.ofType(CreateActions.PUBLISH_ENTRY_SUCCESS)
    .switchMap(payload => Observable.of(new EntryActions.Load()));*/

  @Effect() loadById$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD_BY_ID),
    map((action: EntryActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload).pipe(
        map(data => new EntryActions.LoadByIdSuccess(data)),
        catchError(error => of(new EntryActions.LoadByIdFailure(error)))
      );
    })
  );

  @Effect() updateComment$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.UPDATE_COMMENT),
    map((action: EntryActions.UpdateComment) => action.payload),
    switchMap(payload => {
      return this._service.updateComment(payload).pipe(
        map(data => new EntryActions.UpdateCommentSuccess(data)),
        catchError(response => of(new EntryActions.UpdateCommentFailure(response)))
      );
    }),
    catchError(response => of(new EntryActions.UpdateCommentFailure(response)))
  );

  @Effect() loadComments$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD_COMMENTS),
    map((action: EntryActions.LoadComments) => action.payload),
    switchMap(payload => {
      return this._service.loadComments(payload).pipe(
        map(data => new EntryActions.LoadCommentsSuccess(data)),
        catchError(response => of(new EntryActions.LoadCommentsFailure(response)))
      );
    })
  );

  @Effect() loadPinnedPosts$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.LOAD_PINNED_POSTS),
    switchMap(_ => {
      return this._service.loadPinnedPosts().pipe(
        map(data => new EntryActions.LoadPinnedPostsSuccess(data)),
        catchError(response => of(new EntryActions.LoadPinnedPostsFailure(response)))
      );
    }),
    catchError(response => of(new EntryActions.LoadPinnedPostsFailure(response)))
  );

  @Effect() updateSuccessLoadPinned$: Observable<Action> = this.actions$.pipe(
    ofType(EntryActions.UPDATE_SUCCESS),
    switchMap(_ => of(new EntryActions.LoadPinnedPosts()))
  );


  constructor(private actions$: Actions, private _service: EntryService) {}
}

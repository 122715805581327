import * as OccurrenceActions from './occurrence.actions';
import {handleError, sortEntitiesByEventId} from '../helpers';

export type Action = OccurrenceActions.All;

export interface OccurrenceState {
  loading: boolean;
  entities: any[];
  entitiesByEventId: any;
  error: string;
  message: string;
}

const initialState: OccurrenceState = {
  loading: false,
  entities: null,
  entitiesByEventId: null,
  error: null,
  message: null,
};

function setErrorMessage(state: OccurrenceState, message: string): OccurrenceState {
  return Object.assign({}, state, {
    loading: false,
    loadingMore: false,
    error: message
  });
}

function updateOccurrence(occurrences: any[], occurrenceFromPayload: any): any[] {
  return occurrences.map(occurrence => {
    if (occurrenceFromPayload.event_id === occurrence.id &&
      occurrence.start === occurrenceFromPayload.start && occurrence.end === occurrenceFromPayload.end) {
      return occurrenceFromPayload;
    } else {
      return occurrence;
    }
  });
}

function removeOccurrence(occurrences: any[], occurrenceFromPayload: any): any[] {
  return occurrences.filter(occurrence => occurrence.id !== occurrenceFromPayload.id);
}

function handleOccurrenceErrors(errors: any[]): string {
  if (errors) {
    return errors.map(error => error.title).join(', ');
  }

  return null;
}

export function reducer(state: OccurrenceState = initialState, {type, payload}: Action): OccurrenceState {
  switch (type) {
    case OccurrenceActions.LOAD_BY_OWNER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case OccurrenceActions.LOAD_BY_OWNER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload,
        entitiesByEventId: sortEntitiesByEventId(payload),
        error: null
      });

    case OccurrenceActions.LOAD_BY_OWNER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload
      });

    case OccurrenceActions.LOAD_BY_EVENT_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });

    case OccurrenceActions.LOAD_BY_EVENT_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByEventId: sortEntitiesByEventId(payload),
        entities: payload,
        error: handleOccurrenceErrors(payload.errors)
      });

    case OccurrenceActions.LOAD_BY_EVENT_ID_FAILURE:
      return setErrorMessage(state, 'AN_ERROR_OCCURRED');

    case OccurrenceActions.DELETE:
      return Object.assign({}, state, {
        loading: true,
        message: 'DELETING'
      });
    case OccurrenceActions.DELETE_SUCCESS:
      const occ = removeOccurrence(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        message: 'DELETE_SUCCESS',
        entities: occ,
        entitiesByEventId: sortEntitiesByEventId(occ)
      });
    case OccurrenceActions.DELETE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: handleError(payload)
      });

    case OccurrenceActions.UPDATE:
      return Object.assign({}, state, {
        loading: true,
        message: 'UPDATING'
      });
    case OccurrenceActions.UPDATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'UPDATE_SUCCESS'
      });
    case OccurrenceActions.UPDATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: 'UPDATE_FAILURE',
        error: handleError(payload)
      });

    case OccurrenceActions.COMMENT:
      return Object.assign({}, state, {
        loading: true,
        message: 'PUBLISHING',
        error: null
      });
    case OccurrenceActions.COMMENT_SUCCESS:
      const commentAdded = state.entities.map(entity => {
        if (entity.id === payload.comment.parent_id) {
          return Object.assign({}, entity, {
            comments: (entity.comments) ? [].concat(entity.comments, [payload.comment]) : [].concat([payload.comment])
          });
        } else if (entity.start === payload.occurrence.start && entity.end === payload.occurrence.end) {
          return Object.assign({}, entity, {
            comments: (entity.comments) ? [].concat(entity.comments, [payload.comment]) : [].concat([payload.comment])
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        loading: false,
        message: 'COMMENT_SUCCESS',
        entities: commentAdded,
        entitiesByEventId: sortEntitiesByEventId(commentAdded),
        error: null
      });
    case OccurrenceActions.COMMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: handleError(payload)
      });
    case OccurrenceActions.DELETE_COMMENT:
      return Object.assign({}, state, {
        loading: true,
        message: 'DELETING_COMMENT',
        error: null
      });
    case OccurrenceActions.DELETE_COMMENT_SUCCESS:
      const removedComment = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: entity.comments.filter(comment => comment.id !== payload.id)
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        message: null,
        loading: false,
        entities: removedComment,
        entitiesByEventId: sortEntitiesByEventId(removedComment)
      });
    case OccurrenceActions.DELETE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: handleError(payload)
      });

    case OccurrenceActions.CANCEL:
      return Object.assign({}, state, {
        loading: true,
        message: 'CANCELLING'
      });
    case OccurrenceActions.CANCEL_SUCCESS:
      const occurrences = updateOccurrence(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        message: '',
        entities: occurrences,
        entitiesByEventId: sortEntitiesByEventId(occurrences)
      });
    case OccurrenceActions.CANCEL_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: handleError(payload)
      });
    case OccurrenceActions.RESET_STATE_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });

    case OccurrenceActions.LOAD_COMMENTS_BY_EVENT_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case OccurrenceActions.LOAD_COMMENTS_BY_EVENT_ID_SUCCESS:
      const addedComment = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: payload.result
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        loading: false,
        entities: addedComment,
        entitiesByEventId: sortEntitiesByEventId(addedComment)
      });
    case OccurrenceActions.LOAD_COMMENTS_BY_EVENT_ID_FAILURE:
      return Object.assign({}, state, {
        error: payload.error,
        loading: false
      });

    case OccurrenceActions.LOAD_COMMENTS_BY_OCCURRENCE_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case OccurrenceActions.LOAD_COMMENTS_BY_OCCURRENCE_ID_SUCCESS:
      const addedOccurrenceComment = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: payload.result
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        loading: false,
        entities: addedOccurrenceComment,
        entitiesByEventId: sortEntitiesByEventId(addedOccurrenceComment)
      });
    case OccurrenceActions.LOAD_COMMENTS_BY_OCCURRENCE_ID_FAILURE:
      return Object.assign({}, state, {
        error: payload.error,
        loading: false
      });

    case OccurrenceActions.UPDATE_COMMENT:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case OccurrenceActions.UPDATE_COMMENT_SUCCESS:
      const commentUpdated = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: entity.comments.map(comment => (comment.id === payload.id) ? payload : comment)
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        error: null,
        loading: false,
        entities: commentUpdated,
        entitiesByEventId: sortEntitiesByEventId(commentUpdated)
      });
    case OccurrenceActions.UPDATE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: OccurrenceState) => state.loading;
export const getEntities = (state: OccurrenceState) => state.entities;
export const getEntitiesByEventId = (state: OccurrenceState) => state.entitiesByEventId;
export const getErrorMessage = (state: OccurrenceState) => state.error;
export const getStateMessage = (state: OccurrenceState) => state.message;

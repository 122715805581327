export function handleError(payload: any): string {
  if (payload.status === 404 || payload.status === 401 || payload.status === 403) {
    return 'NO_ACCESS';
  } else if (payload.status >= 500) {
    if (payload.error) {
      if (payload.error['message']) {
        return payload.error['message'];
      } else if (payload.error['detail']) {
        return payload.error['detail'];
      }
    } else {
      return 'AN_ERROR_OCCURRED';
    }
  } else if (payload.status === 403) {
    if (payload.error) {
      if (payload.error['attempts_left'] > 0) {
        return payload.error['detail'] + ' ' + payload.error['attempts_left'];
      } else {
        return payload.error['detail'];
      }
    }
  }
}

export function sortEntitiesById(entities: any[]): any {
  const entitiesById = {};
  entities.map(entity => {
    if (entity.id) {
      entitiesById[entity.id] = entity;
    } else {
      entitiesById[entity.id] = entity;
    }
  });
  return entitiesById;
}

export function sortEntitiesByUrl(entities: any[]): any {
  const entitiesById = {};

  entities.map(entity => {
    entitiesById[entity.url] = entity;
  });

  return entitiesById;
}

export function sortEntitiesByOwnerId(entities: any[]): any {
  const entitiesByOwnerId = {};
  entities.map(entity => {
    if (entity.owner.id) {
      if (!entitiesByOwnerId[entity.owner.id]) {
        entitiesByOwnerId[entity.owner.id] = [];
      }

      entitiesByOwnerId[entity.owner.id].push(entity);
    }
  });
  return entitiesByOwnerId;
}

export function sortEntitiesByObject(entities: any[], objectUrl): any {
  const entitiesByObject = {};

  if (entities) {
    entities.map(entity => {
      if (!entitiesByObject[objectUrl]) {
        entitiesByObject[objectUrl] = [];
      }

      const index = entitiesByObject[objectUrl].findIndex(e => (e.url === entity.url));
      if (index === -1) {
        entitiesByObject[objectUrl].push(entity);
      }
    });
  }

  return entitiesByObject;
}

export function updateEntity(entities: any[], payload: any): any[] {
  return entities.map(entity => {
    if (entity.id === payload.id) {
      if (entity.comments) {
        return Object.assign({}, payload, {
          comments: entity.comments
        });
      } else {
        return payload;
      }
    } else {
      return entity;
    }
  });
}

export function addEntity(entities: any, entity: any): any[] {

  if (!entities) {
    entities = [];
  }

  const index = entities.findIndex(e => entity.id === e.id);

  if (index === -1) {
    entities = [].concat(...entities, entity);
  } else {
    entities = entities.map(e => (e.id === entity.id) ? Object.assign(e, entity) : e);
  }

  return entities;
}


export function addEntities(entities: any[], payload: any[]): any[] {
  payload.map(entity => {
    const index = entities.findIndex(e => e.id === entity.id);
    if (index !== -1) {
      entities[index] = entity;
    } else {
      entities = [].concat(...entities, ...entity);
    }
  });

  return entities;
}

export function sortEntitiesByEventId(occurrences: any[]): any {
  const occurrencesByEventId = {};


  occurrences.map(occurrence => {
    if (occurrence.event_id) {
      if (!occurrencesByEventId[occurrence.event_id]) {
        occurrencesByEventId[occurrence.event_id] = [];
      }
      occurrencesByEventId[occurrence.event_id].push(occurrence);
    } else {
      if (!occurrencesByEventId[occurrence.id]) {
        occurrencesByEventId[occurrence.id] = [];
      }
      occurrencesByEventId[occurrence.id].push(occurrence);
    }
  });

  return occurrencesByEventId;
}

export function createAdded(state: any[], payload: any): any[] {
  let added: any[];

  if (state) {
    const loaded = state.findIndex(content => content.url === content.url) !== -1;
    if (loaded) {
      added = updateEntity(state, payload);
    } else {
      added = [].concat(...state, payload);
    }
  } else {
    added = [].concat(payload);
  }

  return added;
}

export function sortEntitiesByDeviceToken(entities: any[]) {
  const entitiesByDeviceToken = {};

  entities.map(entity => {
    entitiesByDeviceToken[entity.token] = entity;
  });

  return entitiesByDeviceToken;
}

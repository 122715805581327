import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {ApiService} from './api.service';
import { LocalStorageService } from 'ngx-webstorage';
import {API_URL, API_VERSION} from './config';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class InvitationService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${API_URL}${API_VERSION}account/invitations/`;
    return this._http.get(url, { headers: this._getHeaders() })
      .pipe(
        map((res: any) => res.results),
        map(invitations => invitations.filter(invitation => invitation.is_active)),
        switchMap(invitations => {
        const observables = invitations.map(invitation => {
          const metaObservables = [
            this._http.get(invitation.relation_type, { headers: this._getHeaders() }),
            this._http.get(invitation.relation_to, { headers: this._getHeaders() }),
            this._http.get(invitation.inviter, { headers: this._getHeaders() })
          ];

          return forkJoin(metaObservables)
            .pipe(map((values: any) => {
              return Object.assign({}, invitation, {
                relation: values[0],
                service_receiver: values[1],
                inviter_object: { userprofile: values[2].userprofile, name: `${values[2].first_name} ${values[2].last_name}` },
              });
            }));
        });

        return forkJoin(observables);
      }));
  }

  accept(invitationUrl: string): Observable<any> {
    return this._http.post(`${invitationUrl}accept/`, JSON.stringify({}), { headers: this._getHeaders() });
  }

}

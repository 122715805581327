import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import * as ServiceReceiverActions from './service-receiver.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {ServiceReceiverService} from '../../core/service-receiver.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class ServiceReceiverEffects {

  @Effect() load$: Observable<Action> = this._actions.pipe(
    ofType(ServiceReceiverActions.LOAD),
    switchMap(_ => {
      return this._service.loadServiceReceivers().pipe(
        map(data => new ServiceReceiverActions.LoadSuccess(data)),
        catchError(response => of(new ServiceReceiverActions.LoadFailure(response)))
      );
    })
  );

  @Effect() loadMore$: Observable<Action> = this._actions.pipe(
    ofType(ServiceReceiverActions.LOAD_MORE),
    map((action: ServiceReceiverActions.LoadMore) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload).pipe(
        map(data => new ServiceReceiverActions.LoadMoreSuccess(data)),
        catchError(error => of(new ServiceReceiverActions.LoadMoreFailure(error)))
      );
    })
  );

  @Effect() serviceReceiverSearch$: Observable<Action> = this._actions.pipe(
    ofType(ServiceReceiverActions.SEARCH),
    map((action: ServiceReceiverActions.Search) => action.payload),
    switchMap(payload => {
      return this._serviceReceiverService.search(payload).pipe(
        map(data => new ServiceReceiverActions.SearchSuccess(data)),
        catchError(response => of(new ServiceReceiverActions.SearchFailure(response)))
      );
    })
  );

  @Effect() update$: Observable<Action> = this._actions.pipe(
    ofType(ServiceReceiverActions.UPDATE),
    map((action: ServiceReceiverActions.Update) => action.payload),
    switchMap(payload => {
      return this._service.update(payload).pipe(
        map(data => new ServiceReceiverActions.UpdateSuccess(data)),
        catchError(response => of(new ServiceReceiverActions.UpdateFailure(response)))
      );
    })
  );

  @Effect() loadById$: Observable<Action> = this._actions.pipe(
    ofType(ServiceReceiverActions.LOAD_BY_ID),
    map((action: ServiceReceiverActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload).pipe(
        map(data => new ServiceReceiverActions.LoadByIdSuccess(data)),
        catchError(response => of(new ServiceReceiverActions.LoadByIdFailure(response)))
      );
    })
  );

  @Effect() loadProfile$: Observable<Action> = this._actions.pipe(
    ofType(ServiceReceiverActions.LOAD_PROFILE),
    map((action: ServiceReceiverActions.LoadProfile) => action.payload),
    switchMap(payload => {
      return this._service.loadProfile(payload).pipe(
        map(data => new ServiceReceiverActions.LoadProfileSuccess(data)),
        catchError(response => of(new ServiceReceiverActions.LoadProfileFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _service: UserService, private _userService: UserService,
              private _serviceReceiverService: ServiceReceiverService) {
  }
}

import * as MessageActions from './message.actions';
import {addEntities, handleError, sortEntitiesById, sortEntitiesByOwnerId, updateEntity} from '../helpers';

export type Action = MessageActions.All;

export interface MessageState {
  loading: boolean;
  loadingMore: boolean;
  entities: any[];
  entitiesById: any;
  nextUrl: string;
  error: string;
  message: string;
  entitiesByOwner: any;
  unreadMessages: any;
  pinnedMessages: any[];
  nextPinnedUrl: string;
}

const initialState: MessageState = {
  loading: null,
  loadingMore: null,
  entities: null,
  entitiesById: null,
  nextUrl: null,
  error: null,
  message: null,
  entitiesByOwner: null,
  unreadMessages: null,
  pinnedMessages: null,
  nextPinnedUrl: null
};

export function reducer(state: MessageState = initialState, {type, payload}: Action): MessageState {
  switch (type) {
    case MessageActions.LOAD:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case MessageActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        entitiesById: sortEntitiesById(payload.results),
        entitiesByOwner: sortEntitiesByOwnerId(payload.results),
        nextUrl: payload.next
      });
    case MessageActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        error: handleError(payload)
      });
    case MessageActions.LOAD_MORE:
      return Object.assign({}, state, {
        loadingMore: true,
        error: null
      });
    case MessageActions.LOAD_MORE_SUCCESS:
      const all = [].concat(...state.entities, payload.results);

      return Object.assign({}, state, {
        loadingMore: false,
        entities: all,
        entitiesById: sortEntitiesById(all),
        entitiesByOwner: sortEntitiesByOwnerId(all),
        nextUrl: payload.next
      });
    case MessageActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        loadingMore: false,
        error: handleError(payload)
      });
    case MessageActions.TOGGLE_READ:
      return Object.assign({}, state, {
        loading: true
      });
    case MessageActions.TOGGLE_READ_SUCCESS:
      const updateMessage = updateEntity(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        entities: updateMessage,
        entitiesById: sortEntitiesById(updateMessage),
        entitiesByOwner: sortEntitiesByOwnerId(updateMessage)
      });
    case MessageActions.TOGGLE_READ_FAILURE:
      return Object.assign({}, state, {
        loading: false
      });
    case MessageActions.LOAD_FEED_BY_OWNER:
      return Object.assign({}, state, {
        loading: true,
        nextUrl: null,
        error: null
      });
    case MessageActions.LOAD_FEED_BY_OWNER_SUCCESS:
      const feed = (state.entities) ? addEntities(state.entities, payload.results) : payload.results;

      return Object.assign({}, state, {
        loading: false,
        entities: feed,
        entitiesById: sortEntitiesById(feed),
        entitiesByOwner: sortEntitiesByOwnerId(feed),
        nextUrl: payload.next,
        error: null
      });
    case MessageActions.LOAD_FEED_BY_OWNER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case MessageActions.COMMENT:
      return Object.assign({}, state, {
        loading: true,
        message: 'PUBLISHING',
        error: null
      });
    case MessageActions.COMMENT_SUCCESS:
      const commentAdded = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: (entity.comments) ? [].concat(entity.comments, [payload]) : [].concat([payload])
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        message: null,
        loading: false,
        entities: commentAdded,
        entitiesById: sortEntitiesById(commentAdded),
        entitiesByOwner: sortEntitiesByOwnerId(commentAdded)
      });
    case MessageActions.COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: handleError(payload)
      });
    case MessageActions.DELETE_COMMENT:
      return Object.assign({}, state, {
        message: 'DELETING_COMMENT',
        error: null,
        loading: true
      });
    case MessageActions.DELETE_COMMENT_SUCCESS:
      const removedComment = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: entity.comments.filter(comment => comment.id !== payload.id)
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        message: null,
        loading: false,
        entities: removedComment,
        entitiesById: sortEntitiesById(removedComment),
        entitiesByOwner: sortEntitiesByOwnerId(removedComment)
      });
    case MessageActions.DELETE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: handleError(payload)
      });
    case MessageActions.DELETE:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: 'DELETING'
      });
    case MessageActions.DELETE_SUCCESS:
      const entities = state.entities.filter(entity => (entity.id !== payload.id));

      return Object.assign({}, state, {
        loading: false,
        error: null,
        entities: entities,
        entitiesByOwner: sortEntitiesByOwnerId(entities),
        message: 'DELETE_SUCCESS'
      });
    case MessageActions.DELETE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });
    case MessageActions.UPDATE:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: 'UPDATING'
      });
    case MessageActions.UPDATE_SUCCESS:
      let updated = [];
      if (state.entities) {
        updated = updateEntity(state.entities, payload);
      } else {
        updated = [].concat(payload);
      }

      return Object.assign({}, state, {
        loading: false,
        entities: updated,
        entitiesById: sortEntitiesById(updated),
        entitiesByOwner: sortEntitiesByOwnerId(updated),
        error: null,
        message: 'UPDATE_SUCCESS'
      });
    case MessageActions.UPDATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload.error),
        message: null
      });
    case MessageActions.RESET_STATE_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });
    case MessageActions.RESET:
      return Object.assign({}, state, initialState);
    case MessageActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        loading: true
      });
    case MessageActions.LOAD_BY_ID_SUCCESS:
      let added: any[];

      if (state.entities) {
        const loaded = state.entities.findIndex(message => message.id === payload.id) !== -1;
        if (loaded) {
          added = updateEntity(state.entities, payload);
        } else {
          added = [].concat(...state.entities, payload);
        }
      } else {
        added = [].concat(payload);
      }

      return Object.assign({}, state, {
        loading: false,
        entities: added,
        entitiesById: sortEntitiesById(added),
      });
    case MessageActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });

    case MessageActions.UPDATE_COMMENT:
      return Object.assign({}, state, {
        loading: true,
        message: 'UPDATING_COMMENT',
        error: null
      });
    case MessageActions.UPDATE_COMMENT_SUCCESS:
      const addedComment = state.entities.map(entity => {
        if (entity.id === payload.parent_id) {
          return Object.assign({}, entity, {
            comments: entity.comments.map(comment => (comment.id === payload.id) ? payload : comment)
          });
        } else {
          return entity;
        }
      });

      return Object.assign({}, state, {
        message: null,
        loading: false,
        entities: addedComment,
        entitiesById: sortEntitiesById(addedComment),
        entitiesByOwner: sortEntitiesByOwnerId(addedComment)
      });
    case MessageActions.UPDATE_COMMENT_FAILURE:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: handleError(payload)
      });

    case MessageActions.LOAD_UNREAD:
      return Object.assign({}, state, {
        message: null,
        loading: true,
        error: null
      });
    case MessageActions.LOAD_UNREAD_SUCCESS:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: null,
        unreadMessages: payload.results
      });

    case MessageActions.LOAD_UNREAD_FAILURE:
      return Object.assign({}, state, {
        message: null,
        loading: false,
        error: handleError(payload)
      });

    case MessageActions.MARK_ALL_AS_READ:
      return Object.assign({}, state, {
        message: null,
        loading: true,
        error: null
      });

    case MessageActions.MARK_ALL_AS_READ_SUCCESS:
      return Object.assign({}, state, {
        message: 'MARK_ALL_AS_READ_SUCCESS',
        loading: false,
        error: null
      });

    case MessageActions.MARK_ALL_AS_READ_FAILURE:
      return Object.assign({}, state, {
        message: 'MARK_ALL_AS_READ_FAILURE',
        loading: false,
        error: handleError(payload)
      });

    case MessageActions.LOAD_PINNED_MESSAGES:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });

    case MessageActions.LOAD_PINNED_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        message: 'LOAD_PINNED_MESSAGES_SUCCESS',
        pinnedMessages: payload.results,
        nextPinnedUrl: payload.next
      });

    case MessageActions.LOAD_PINNED_MESSAGES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    case MessageActions.LOAD_MORE_PINNED_MESSAGES:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });

    case MessageActions.LOAD_MORE_PINNED_MESSAGES_SUCCESS:
      const allPinned = [].concat(...state.pinnedMessages, payload.results);

      return Object.assign({}, state, {
        loading: false,
        error: null,
        message: 'LOAD_MORE_PINNED_MESSAGES_SUCCESS',
        pinnedMessages: allPinned,
        nextPinnedUrl: payload.next
      });

    case MessageActions.LOAD_MORE_PINNED_MESSAGES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    default:
      return state;
  }
}

export const getLoadingState = (state: MessageState) => state.loading;
export const getLoadingMoreState = (state: MessageState) => state.loadingMore;
export const getEntities = (state: MessageState) => state.entities;
export const getEntitiesById = (state: MessageState) => state.entitiesById;
export const getNextUrl = (state: MessageState) => state.nextUrl;
export const getError = (state: MessageState) => state.error;
export const getMessage = (state: MessageState) => state.message;
export const getEntitiesByOwner = (state: MessageState) => state.entitiesByOwner;
export const getUnread = (state: MessageState) => state.unreadMessages;
export const getPinnedMessages = (state: MessageState) => state.pinnedMessages;
export const getNextPinnedUrl = (state: MessageState) => state.nextPinnedUrl;

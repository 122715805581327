import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as InvitationActions from './invitation.actions';
import {InvitationService} from '../../core/invitation.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class InvitationEffects {

  @Effect() load$: Observable<Action> = this.actions$.pipe(
    ofType(InvitationActions.LOAD),
    switchMap(_ => {
      return this._service.load().pipe(
        map(data => new InvitationActions.LoadSuccess(data)),
        catchError(error => of(new InvitationActions.LoadFailure(error.json())))
      );
    }),
    catchError(error => of(new InvitationActions.LoadFailure('AN_ERROR_OCCURRED')))
  );

  @Effect() accept$: Observable<Action> = this.actions$.pipe(
    ofType(InvitationActions.ACCEPT),
    map((action: InvitationActions.Accept) => action.payload),
    switchMap(payload => {
      return this._service.accept(payload).pipe(
        map(data => new InvitationActions.AcceptSuccess(data)),
        catchError(error => of(new InvitationActions.AcceptFailure(error.json())))
      );
    })
  );

  @Effect() accepted$: Observable<Action> = this.actions$.pipe(
    ofType(InvitationActions.ACCEPT_SUCCESS),
    switchMap(_ => {
      return this._service.load().pipe(
        map(data => new InvitationActions.LoadSuccess(data)),
        catchError(error => of(new InvitationActions.LoadFailure(error.json())))
      );
    })
  );

  constructor(private actions$: Actions, private _service: InvitationService) {}
}

import * as DialogActions from './dialog.actions';
import {handleError} from '../helpers';

export type Action = DialogActions.All;

export interface DialogState {
  loading: boolean;
  error: string;
  message: string;
  dialog: any;
  dialogById: any;
  recipients: any[];
  nextUrl: string;
  dialogThread: any[];
  loadingThread: boolean;
}

const initialState: DialogState = {
  loading: null,
  error: null,
  message: null,
  dialog: null,
  dialogById: null,
  recipients: null,
  nextUrl: null,
  dialogThread: null,
  loadingThread: null
};

export function reducer(state: DialogState = initialState, {type, payload}: Action): DialogState {
  switch (type) {
    case DialogActions.LOAD_DIALOG:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });

    case DialogActions.LOAD_DIALOG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        message: 'GET_DIALOG_SUCCESS',
        dialog: payload.results
      });

    case DialogActions.LOAD_DIALOG_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    case DialogActions.LOAD_RECIPIENTS:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });

    case DialogActions.LOAD_RECIPIENTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        message: 'GET_RECIPIENTS_SUCCESS',
        recipients: payload.results
      });

    case DialogActions.LOAD_RECIPIENTS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    case DialogActions.CREATE_DIALOG_MESSAGE:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });

    case DialogActions.CREATE_DIALOG_MESSAGE_SUCCESS:
      const addMessage = [].concat(...state.dialogThread, payload);
      return Object.assign({}, state, {
        loading: false,
        error: null,
        message: 'DIALOG_MESSAGE_CREATED',
        dialogThread: addMessage
      });

    case DialogActions.CREATE_DIALOG_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload),
        message: null
      });

    case DialogActions.LOAD_DIALOG_THREAD:
      return Object.assign({}, state, {
        loadingThread: true,
        error: null,
        message: null,
      });

    case DialogActions.LOAD_DIALOG_THREAD_SUCCESS:
      return Object.assign({}, state, {
        loadingThread: false,
        error: null,
        message: 'GET_DIALOG_THREAD_SUCCESS',
        dialogThread: payload.results,
        nextUrl: payload.next
      });

    case DialogActions.LOAD_DIALOG_THREAD_FAILURE:
      return Object.assign({}, state, {
        loadingThread: false,
        error: handleError(payload),
        message: null
      });


    case DialogActions.LOAD_MORE_DIALOG_THREAD:
      return Object.assign({}, state, {
        loadingThread: true,
        error: null,
        message: null,
      });

    case DialogActions.LOAD_MORE_DIALOG_THREAD_SUCCESS:
      const loadedMore = [].concat(...state.dialogThread, payload.results);

      return Object.assign({}, state, {
        loadingThread: false,
        error: null,
        message: 'GET_DIALOG_THREAD_SUCCESS',
        dialogThread: loadedMore,
        nextUrl: payload.next
      });

    case DialogActions.LOAD_MORE_DIALOG_THREAD_FAILURE:
      return Object.assign({}, state, {
        loadingThread: false,
        error: handleError(payload),
        message: null
      });

    default:
      return state;
  }
}

export const getLoadingState = (state: DialogState) => state.loading;
export const getErrorState = (state: DialogState) => state.error;
export const getMessageState = (state: DialogState) => state.message;
export const getDialogState = (state: DialogState) => state.dialog;
export const getDialogThreadState = (state: DialogState) => state.dialogThread;
export const getDialogByIdState = (state: DialogState) => state.dialogById;
export const getRecipientsState = (state: DialogState) => state.recipients;
export const getNextUrlState = (state: DialogState) => state.nextUrl;

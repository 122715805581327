import * as EULAActions from './eula.actions';

export type Action = EULAActions.All;

export interface EULAState {
  loading: boolean;
  entities: any[];
  responses: any;
  message: string;
  error: string;
}

const initialState: EULAState = {
  loading: null,
  entities: null,
  responses: null,
  message: null,
  error: null
};

export function reducer(state: EULAState = initialState, {type, payload}: Action): EULAState {
  switch (type) {
    case EULAActions.LOAD:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });
    case EULAActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload
      });
    case EULAActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: 'COULD_NOT_LOAD_EULA'
      });
    case EULAActions.SEND:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });
    case EULAActions.SEND_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'EULA_RESPONSE_SENT'
      });
    case EULAActions.SEND_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: 'COULD_NOT_SEND_EULA'
      });
    case EULAActions.RESET_MESSAGE:
      return Object.assign({}, state, {
        message: null
      });
    case EULAActions.LOAD_BY_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        message: null
      });
    case EULAActions.LOAD_BY_USER_SUCCESS:
      const responses = Object.assign({}, state.responses, {
        [payload.user.id]: payload
      });

      return Object.assign({}, state, {
        loading: false,
        responses: responses
      });
    case EULAActions.LOAD_BY_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        message: null,
        error: 'COULD_NOT_LOAD_EULA'
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: EULAState) => state.loading;
export const getEntities = (state: EULAState) => state.entities;
export const getResponseByUser = (state: EULAState) => state.responses;
export const getError = (state: EULAState) => state.error;
export const getMessage = (state: EULAState) => state.message;

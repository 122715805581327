import {Action} from '@ngrx/store';

export const SET_MESSAGE = '[Nav] set message';
export const RESET_MESSAGE = '[Nav] reset message';
export const SET_TITLE = '[Nav] set title';
export const SET_BACK_BUTTON = '[Nav] set back button';

export class SetMessage implements Action {
  readonly type = SET_MESSAGE;

  constructor(public payload: string) {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor(public payload?: any) {}
}

export class SetTitle implements Action {
  readonly type = SET_TITLE;

  constructor(public payload?: any) {}
}

export class SetBackButton implements Action {
  readonly type = SET_BACK_BUTTON;

  constructor(public payload: boolean) {}
}

export type All = SetMessage | ResetMessage | SetTitle | SetBackButton;

import {Action} from '@ngrx/store';

export const SEND_BUG_REPORT = '[Feedback] send bug report';
export const SEND_BUG_REPORT_SUCCESS = '[Feedback] send bug report success';
export const SEND_BUG_REPORT_FAILURE = '[Feedback] send bug report failed';
export const RESET_MESSAGE = '[Feedback] reset message';

export class SendBugReport implements Action {
  readonly type = SEND_BUG_REPORT;

  constructor(public payload: any) {}
}

export class SendBugReportSuccess implements Action {
  readonly type = SEND_BUG_REPORT_SUCCESS;

  constructor(public payload: string) {}
}

export class SendBugReportFailure implements Action {
  readonly type = SEND_BUG_REPORT_FAILURE;

  constructor(public payload: string) {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor(public payload?: string) {}
}

export type All = SendBugReport | SendBugReportSuccess | SendBugReportFailure | ResetMessage;

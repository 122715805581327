import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../core/auth.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as RegisterActions from './register.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class RegisterEffects {

  @Effect() register$: Observable<Action> = this.actions$.pipe(
    ofType(RegisterActions.REGISTER),
    map((action: RegisterActions.Register) => action.payload),
    switchMap(payload => {
      return this._service.register(payload).pipe(
        map(authData => new RegisterActions.RegisterSuccess(authData)),
        catchError(response => of(new RegisterActions.RegisterFailure(response.error)))
      );
    }),
    catchError(response => of(new RegisterActions.RegisterFailure(response.error)))
  );

  constructor(private actions$: Actions, private _service: AuthService) {}
}

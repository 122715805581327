import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Ng2Webstorage} from 'ngx-webstorage';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppRoutingModule} from './routing/app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from 'app/core/core.module';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store';
import {EffectsModule} from '@ngrx/effects';
import {NavModule} from './nav/nav.module';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RavenErrorHandler} from './raven-error-handler';
import {SWIPER_CONFIG} from './swiper.config';
import {Angulartics2Module} from 'angulartics2';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {environment} from '../environments/environment';
import {effects} from './store/effects';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import {NotifierModule} from 'angular-notifier';
import {NotificationModule} from './notification.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
     }
   }),
    DeviceDetectorModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot(effects),
    Ng2Webstorage,
    AppRoutingModule,
    CoreModule,
    NavModule,
    BrowserAnimationsModule,
    SwiperModule.forRoot(SWIPER_CONFIG),
    Angulartics2Module.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    NotifierModule,
    NotificationModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    // { provide: ErrorHandler, useClass: RavenErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

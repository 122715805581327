import {Action} from '@ngrx/store';

export const REGISTER = '[Register] register';
export const REGISTER_SUCCESS = '[Register] register success';
export const REGISTER_FAILURE = '[Register] register failure';

export class Register implements Action {
  readonly type = REGISTER;

  constructor(public payload: any) {}
}

export class RegisterSuccess implements Action {
  readonly type = REGISTER_SUCCESS;

  constructor(public payload: string) {}
}

export class RegisterFailure implements Action {
  readonly type = REGISTER_FAILURE;

  constructor(public payload: string) {}
}

export type All
  = Register | RegisterSuccess | RegisterFailure;

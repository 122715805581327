import {Action} from '@ngrx/store';

export const CREATE_INSTITUTION_CHILD = '[CreateInstitutionChild] create child';
export const CREATE_INSTITUTION_CHILD_SUCCESS = '[CreateInstitutionChild] create child success';
export const CREATE_INSTITUTION_CHILD_FAILED = '[CreateInstitutionChild] create child failed';

export class CreateInstitutionChild implements Action {
  readonly type = CREATE_INSTITUTION_CHILD;

  constructor(public payload: any) {}
}

export class CreateInstitutionChildSuccess implements Action {
  readonly type = CREATE_INSTITUTION_CHILD_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateInstitutionChildFailed implements Action {
  readonly type = CREATE_INSTITUTION_CHILD_FAILED;

  constructor(public payload: string) {}
}

export type All = CreateInstitutionChild | CreateInstitutionChildFailed | CreateInstitutionChildSuccess;

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {PermissionService} from '../../core/permission.service';
import * as PermissionActions from '../permissions/permissions.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class PermissionsEffects {

  @Effect() checkPermissions$: Observable<Action> = this._actions.pipe(
    ofType(PermissionActions.CHECK_PERMISSIONS),
    map((action: PermissionActions.CheckPermissions) => action.payload),
    switchMap(payload => {
      return this._service.checkPermission(payload).pipe(
        map(data => new PermissionActions.CheckPermissionsSuccess(data)),
        catchError(response => of(new PermissionActions.CheckPermissionsFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _service: PermissionService) {}
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {FCMService} from '../../core/fcm.service';
import * as FCMActions from './fcm.actions';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';

@Injectable()
export class FCMEffects {

  @Effect() loadDevices$: Observable<Action> = this._actions.pipe(
    ofType(FCMActions.LOAD),
    map((action: FCMActions.Load) => action.payload),
    switchMap(payload => {
      return this._service.load(payload).pipe(
        map(data => new FCMActions.LoadSuccess(data)),
        catchError(response => of(new FCMActions.LoadFailure(response)))
      );
    }),
    catchError(error => of(new FCMActions.LoadFailure('AN_ERROR_OCCURRED')))
  );

  @Effect() registerFCMDevice$ = this._actions.pipe(
    ofType(FCMActions.REGISTER_DEVICE),
    map((action: FCMActions.RegisterDevice) => action.payload),
    switchMap(payload => {
      return this._service.registerFCMDevice(payload.token, payload.language, payload.userId).pipe(
        map(data => new FCMActions.RegisterDeviceSuccess(data)),
        catchError(error => of(new FCMActions.RegisterDeviceFailure(error)))
      );
    }),
    catchError(response => of(new FCMActions.RegisterDeviceFailure('AN_ERROR_OCCURRED')))
  );

  @Effect() removeDevice$ = this._actions.pipe(
    ofType(FCMActions.REMOVE_DEVICE),
    switchMap(_ => {
      return this._service.unregisterFCMDevice().pipe(
        map(data => new FCMActions.RemoveDeviceSuccess(data)),
        catchError(error => of(new FCMActions.RemoveDeviceFailure(error)))
      );
    }),
    catchError(_ => of(new FCMActions.RegisterDeviceFailure('AN_ERROR_OCCURRED')))
  );

  constructor(private _actions: Actions, private _service: FCMService) {
  }
}

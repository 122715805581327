import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Store, select} from '@ngrx/store';
import * as rootState from '../../store';
import {NavService} from '../nav.service';
import {NavigationEnd, Router, Event} from '@angular/router';
import {AuthenticationState} from '../../enums';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map} from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
})
export class TabBarComponent implements OnInit, OnDestroy {
  @Output() onRouteChanged: EventEmitter<any> = new EventEmitter();

  isAuthenticated$: Observable<boolean>;
  unreadMessages: any[] = [];
  loggedInUser: any;
  navMode: string;
  serviceReceiver: string;
  isRoot: boolean;
  userMessagesSubscription: Subscription;
  serviceReceiverSubscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>, private _nav: NavService, private _router: Router) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this._store.pipe(select(rootState.getAuthenticationState))
      .pipe(map(autState => (autState === AuthenticationState.AUTHENTICATED)));

    this._router.events.subscribe(event => this._getCurrentUrl(event));


    const loggedInUser$ = this._store.pipe(select(rootState.getLoggedInUser));
    const messages$ = this._store.pipe(select(rootState.getMessages));

    this.userMessagesSubscription = this._store.pipe(select(rootState.getMessagesUnread))
      .subscribe(unread => (unread) ? this.unreadMessages = unread  : null);

    const serviceReceivers$ = this._store.pipe(select(rootState.getServiceReceivers));
    this.serviceReceiverSubscription = combineLatest(loggedInUser$, serviceReceivers$)
      .subscribe(values => {
        this.loggedInUser = values[0];
        this.navMode = this._nav.getNavMode(values[0], values[1]);
        if (this.navMode === 'single') {
          this.serviceReceiver = (this.loggedInUser.is_service_receiver) ? this.loggedInUser : values[1][0];
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userMessagesSubscription) {
      this.userMessagesSubscription.unsubscribe();
    }
  }

  private _getCurrentUrl(event: Event): string {
    if (event instanceof NavigationEnd) {
      this.isRoot = this._nav.isRootRoute(event.url);
      this.onRouteChanged.emit({ isRoot: this.isRoot, url: event.url });
    } else {
      return null;
    }
  }

}

import {NgModule} from '@angular/core';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {TabBarComponent} from './tab-bar/tab-bar.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {NavService} from './nav.service';
import {NavBarButtonComponent} from './nav-mobile/nav-bar-button.component';
import {NavMobileComponent} from './nav-mobile/nav-mobile.component';

@NgModule({
  declarations: [
    NavBarComponent,
    TabBarComponent,
    NavBarButtonComponent,
    NavMobileComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  exports: [
    NavBarComponent,
    TabBarComponent,
    NavMobileComponent
  ],
  providers: [
    NavService
  ]
})

export class NavModule {}

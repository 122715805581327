import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from './config';
import { ApiService } from './api.service';
import { LocalStorageService } from 'ngx-webstorage';
import {forkJoin, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class GroupService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  load(): Observable<any> {
    return this._http.get(`${API_URL}${API_VERSION}groups?limit=10`, { headers: this._getHeaders() });
  }

  search(name: string): Observable<any> {
    let url = `${API_URL}${API_VERSION}groups/`;
    if (name) {
      url += `?name=${name}`;
    }
    return this._http.get(url, { headers: this._getHeaders() });
  }

  loadById(id: number): Observable<any> {
    const url = `${API_URL}${API_VERSION}groups/${id}/`;
    return this._http.get(url, { headers: this._getHeaders() });
  }

  create(group: any): Observable<any> {
    const url = `${API_URL}${API_VERSION}groups`;
    return this._http.post(url, JSON.stringify({name: group.name}), { headers: this._getHeaders() })
      .pipe(
        switchMap((newGroup: any) => {
        if (group.members.length > 0) {
          return forkJoin(group.members.map(memberId => this.addMember(newGroup.id, memberId)));
        } else {
          return of({});
        }
      }));
  }

  loadMemberByGroupId(id: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}groups/${id}/members`;
    return this._http.get(url, { headers: this._getHeaders() })
      .pipe(map(members => ({ groupId: id, members: members })));
  }

  removeMember(groupId: string, userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}groups/${groupId}/members/${userId}`;
    return this._http.delete(url, { headers: this._getHeaders() })
      .pipe(map(result => ({ result: result, groupId: groupId })));
  }

  addMember(groupId: string, userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}groups/${groupId}/members`;
    return this._http.post(url, { member_id: userId }, { headers: this._getHeaders() })
      .pipe(map(result => ({ result: result, groupId: groupId })));
  }

  loadByUserId(userId: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}users/${userId}/groups`;
    return this._http.get(url, { headers: this._getHeaders() })
      .pipe(map(result => ({ userId: userId, result: result })));
  }
}

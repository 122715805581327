import * as ServiceReceiverActions from './service-receiver.actions';
import {
  addEntity,
  handleError, sortEntitiesById, sortEntitiesByObject,
  sortEntitiesByUrl
} from '../helpers';

export type Action = ServiceReceiverActions.All;

export interface ServiceReceiverState {
  loading: boolean;
  loadingMore: boolean;
  entities: any[];
  entitiesById: any;
  searching: boolean;
  searchResults: any;
  nextUrl: string;
  error: string;
  userProfile: any;
}

const initialState: ServiceReceiverState = {
  loading: false,
  loadingMore: false,
  entities: null,
  entitiesById: null,
  searchResults: null,
  searching: false,
  nextUrl: null,
  error: null,
  userProfile: null
};

export function reducer(state: ServiceReceiverState = initialState, {type, payload}: Action): ServiceReceiverState {
  switch (type) {
    case ServiceReceiverActions.LOAD:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case ServiceReceiverActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        entitiesById: sortEntitiesById(payload.results),
        nextUrl: payload.next
      });
    case ServiceReceiverActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        error: payload
      });
    case ServiceReceiverActions.LOAD_MORE:
      return Object.assign({}, state, {
        loadingMore: true,
        error: null
      });
    case ServiceReceiverActions.LOAD_MORE_SUCCESS:
      const added = [].concat(...state.entities, payload.results);

      return Object.assign({}, state, {
        loadingMore: false,
        entities: added,
        entitiesById: sortEntitiesById(added),
        nextUrl: payload.next
      });
    case ServiceReceiverActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        loadingMore: false,
        error: payload
      });
    case ServiceReceiverActions.RESET:
      return Object.assign({}, state, initialState);
    case ServiceReceiverActions.SEARCH:
      return Object.assign({}, state, {
        searching: true
      });
    case ServiceReceiverActions.SEARCH_SUCCESS:
      return Object.assign({}, state, {
        searchResults: payload.results,
        searching: false
      });
    case ServiceReceiverActions.SEARCH_FAILURE:
      return Object.assign({}, state, {
        searching: false,
        error: handleError(payload)
      });
    case ServiceReceiverActions.UPDATE:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case ServiceReceiverActions.UPDATE_SUCCESS:
      const updated = state.entities.map(entity => (entity.url === payload.url) ? payload : entity);

      return Object.assign({}, state, {
        loading: false,
        entities: updated,
        entitiesById: sortEntitiesById(updated),
        error: null
      });
    case ServiceReceiverActions.UPDATE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case ServiceReceiverActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case ServiceReceiverActions.LOAD_BY_ID_SUCCESS:
      const added1 = addEntity(state.entities, payload);

      return Object.assign({}, state, {
        loading: false,
        entities: added1,
        entitiesById: sortEntitiesById(added1),
        nextUrl: payload.next
      });
    case ServiceReceiverActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case ServiceReceiverActions.LOAD_PROFILE:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case ServiceReceiverActions.LOAD_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        userProfile: payload
      });
    case ServiceReceiverActions.LOAD_MORE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: ServiceReceiverState) => state.loading;
export const getEntities = (state: ServiceReceiverState) => state.entities;
export const getEntitiesById = (state: ServiceReceiverState) => state.entitiesById;
export const getSearchResults = (state: ServiceReceiverState) => state.searchResults;
export const getNextUrl = (state: ServiceReceiverState) => state.nextUrl;
export const getError = (state: ServiceReceiverState) => state.error;
export const getSearchState = (state: ServiceReceiverState) => state.searching;
export const getLoadingMoreState = (state: ServiceReceiverState) => state.loadingMore;
export const getUserProfile = (state: ServiceReceiverState) => state.userProfile;

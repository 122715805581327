export const environment = {
  production: true,
  envName: 'poc-usa',
  API_URL: 'https://poc-api.jodacare.com/api/',
  API_VERSION: 'v3/',
  AUTH_TOKEN: 'jodacare-poc-token',
  STRIPE_KEY: '',
  IDPORTEN_LOGIN_URL: '',
  LOGOUT_URL: '',
  firebase: {
    apiKey: 'AIzaSyCdHWEEcpkvmt58fsWR9nUv9WhsAlQZedc',
    authDomain: 'jodacare-151312.firebaseapp.com',
    databaseURL: 'https://jodacare-151312.firebaseio.com',
    projectId: 'jodacare-151312',
    storageBucket: 'jodacare-151312.appspot.com',
    messagingSenderId: '172551206679'
  }
};

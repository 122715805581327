import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {NavService} from '../nav.service';
import {Subscription} from 'rxjs';
import * as rootState from '../../store/index';
import {Store, select} from '@ngrx/store';

@Component({
  moduleId: module.id,
  selector: 'app-nav-mobile',
  templateUrl: 'nav-mobile.component.html'
})

export class NavMobileComponent implements OnInit, OnDestroy {
  showBackButton: boolean = false;
  pageTitle: string;
  currentUrl: string;

  pageTitleSubscription: Subscription;
  routerEventSubscription: Subscription;
  backButtonSubscription: Subscription;

  constructor(private _location: Location,
              private _nav: NavService,
              private _router: Router,
              private _store: Store<rootState.IAppState>) {}

  ngOnInit(): void {
    this.routerEventSubscription = this._router.events.subscribe(event => {
      this._setCurrentUrl(event);
    });

    this.pageTitleSubscription = this._store.pipe(select(rootState.getNavPageTitle))
      .subscribe(title => this.pageTitle = title);

    this.backButtonSubscription = this._store.pipe(select(rootState.getNavPageBackButton))
      .subscribe(value => this.showBackButton = value);
  }

  goBack(): void {
    if (this.currentUrl && this.currentUrl !== undefined) {
      switch (this.currentUrl) {
        case 'service-receiver-detail':
          this._router.navigate(['service-receiver']);
          break;
        case 'message-detail':
          this._router.navigate(['message']);
          break;
        case 'group-detail':
          this._router.navigate(['group']);
          break;
        case 'dialog-window':
          this._router.navigate(['message'], { queryParams: { tab: 'dialogs' } });
          break;
        default:
          this._location.back();
      }
    } else {
      this._router.navigate(['home']);
    }
  }

  ngOnDestroy(): void {
    if (this.pageTitleSubscription) {
      this.pageTitleSubscription.unsubscribe();
    }
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }

  private _setCurrentUrl(event): void {
    if (event instanceof NavigationEnd) {
      const url = event.url.split('/');
      this.currentUrl = url[1];
    }
  }
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {MessageService} from '../../core/message.service';
import {EntryService} from '../../core/entry.service';
import * as CreateActions from './create.actions';
import * as EntryActions from '../entry/entry.actions';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {CalendarService} from '../../core/calendar.service';
import {UserService} from '../../core/user.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class CreateEffects {
  @Effect() publishMessage$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.PUBLISH_MESSAGE),
    map((action: CreateActions.PublishMessage) => action.payload),
    switchMap(payload => {
      return this._messageService.publish(payload).pipe(
        map(data => new CreateActions.PublishMessageSuccess(data)),
        catchError(response => of(new CreateActions.PublishMessageFailure(response)))
      );
    })
  );

  @Effect() publishEntry$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.PUBLISH_ENTRY),
    map((action: CreateActions.PublishEntry) => action.payload),
    switchMap(payload => {
      return this._entryService.publish(payload.entry, payload.images).pipe(
        map(data => new EntryActions.LoadTempImagesSuccess(data)),
        catchError(response => of(new CreateActions.PublishEntryFailure(response)))
      );
    })
  );

  @Effect() publishEvent$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.PUBLISH_EVENT),
    map((action: CreateActions.PublishEvent) => action.payload),
    switchMap(payload => {
      return this._calendarService.publish(payload.event, payload.image, payload.isRecurring).pipe(
        map(data => new CreateActions.PublishEventSuccess(data),
        catchError(response => of(new CreateActions.PublishEventFailure(response)))
        )
      );
    })
  );

  @Effect() bulkPublishMessage$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.BULK_PUBLISH_MESSAGE),
    map((action: CreateActions.BulkPublishMessage) => action.payload),
    switchMap(payload => {
      return this._messageService.bulkPublish(payload.message, payload.owners).pipe(
        map(data => new CreateActions.BulkPublishMessageSuccess(data)),
        catchError(response => of(new CreateActions.BulkPublishMessageFailure(response)))
      );
    })
  );

  @Effect() bulkPublishEntry$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.BULK_PUBLISH_ENTRY),
    map((action: CreateActions.BulkPublishEntry) => action.payload),
    switchMap(payload => {
      return this._entryService.bulkPublish(payload.entry, payload.images, payload.targets).pipe(
        map(data => new EntryActions.LoadTempImagesSuccess(data)),
        catchError(response => of(new CreateActions.BulkPublishEntryFailure(response)))
      );
    })
  );

  @Effect() bulkPublishEvent$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.BULK_PUBLISH_EVENT),
    map((action: CreateActions.BulkPublishEvent) => action.payload),
    switchMap(payload => {
      return this._calendarService.bulkPublish(payload.event, payload.image, payload.targets, payload.isRecurring).pipe(
        map(data => new CreateActions.BulkPublishEventSuccess(data)),
        catchError(response => of(new CreateActions.BulkPublishEventFailure(response)))
      );
    })
  );

  @Effect() createUser$: Observable<Action> = this._actions.pipe(
    ofType(CreateActions.CREATE_USER),
    map((action: CreateActions.CreateUser) => action.payload),
    switchMap(payload => {
      return this._user.create(payload.user, payload.institution, payload.relation).pipe(
        map(data => new CreateActions.CreateUserSuccess(data)),
        catchError(response => of(new CreateActions.CreateUserFailure(response)))
      );
    })
  );

  constructor(private _actions: Actions, private _messageService: MessageService, private _entryService: EntryService,
              private _calendarService: CalendarService, private _user: UserService) {
  }
}

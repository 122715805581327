import {Action} from '@ngrx/store';

export const LOAD_BY_USER = '[Settings] loads';
export const LOAD_BY_USER_SUCCESS = '[Settings] load';
export const LOAD_BY_USER_FAILURE = '[Settings] load failed';
export const SAVE = '[Settings] save';
export const SAVE_SUCCESS = '[Settings] save success';
export const SAVE_FAILURE = '[Settings] save failed';
export const RESET = '[Settings] reset';
export const RESET_MESSAGE = '[Settings] reset message';

export class LoadByUser implements Action {
  readonly type = LOAD_BY_USER;

  constructor(public payload: string) {}
}

export class LoadByUserSuccess implements Action {
  readonly type = LOAD_BY_USER_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadByUserFailure implements Action {
  readonly type = LOAD_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export class Save implements Action {
  readonly type = SAVE;

  constructor(public payload: any) {}
}

export class SaveSuccess implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: any[]) {}
}

export class SaveFailure implements Action {
  readonly type = SAVE_FAILURE;

  constructor(public payload: string) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor(public payload?: any) {}
}

export type All = LoadByUser | LoadByUserSuccess | LoadByUserFailure | Save | SaveSuccess | SaveFailure |
  Reset | ResetMessage;

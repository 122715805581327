import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from './config';
import { ApiService } from './api.service';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AddressService extends ApiService {
  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  loadAddressByUrl(url: string): Observable<any> {
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadCountries(): Observable<any> {
    const url = `${API_URL}${API_VERSION}addresses/?limit=20&offset=20`;
    return this._http.get(url, {headers: this._getHeaders()});
  }

  loadMunicipalities(country: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}municipalities?country=${country}&limit=500&offset=0`;
    return this._http.get(url, {headers: {'Content-Type': 'application/json'}});
  }

  searchMunicipalities(name: string): Observable<any> {
    const url = `${API_URL}${API_VERSION}municipalities/search?name=${name}`;
    return this._http.get(url, {headers: {'Content-Type': 'application/json'}});
  }
}

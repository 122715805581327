import {Action} from '@ngrx/store';

export const CREATE_SUBSCRIPTION = '[CreateServiceReceiver] create subscription';
export const CREATE_SUBSCRIPTION_SUCCESS = '[CreateServiceReceiver] create subscription succes';
export const CREATE_SUBSCRIPTION_FAILURE = '[CreateServiceReceiver] create subscription failure';
export const RESET_MESSAGE ='[CreateServiceReceiver] reset message';

export class CreateSubscription implements Action {
  readonly type = CREATE_SUBSCRIPTION;

  constructor(public payload: any) {}
}

export class CreateSubscriptionSuccess implements Action {
  readonly type = CREATE_SUBSCRIPTION_SUCCESS;

  constructor(public payload: string) {}
}

export class CreateSubscriptionFailure implements Action {
  readonly type = CREATE_SUBSCRIPTION_FAILURE;

  constructor(public payload: string) {}
}

export class ResetMessage implements Action {
  readonly type = RESET_MESSAGE;

  constructor(public payload?: string) {}
}

export type All = CreateSubscription | CreateSubscriptionSuccess | CreateSubscriptionFailure | ResetMessage;

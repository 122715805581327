import * as UIActions from './ui.action';

export type Action = UIActions.All;

export interface UIState {
  scrollEvent: any;
  scrolledPastCalendarHeader: boolean;
}

const initialState: UIState = {
  scrollEvent: null,
  scrolledPastCalendarHeader: null,
};

export function reducer(state: UIState = initialState, {type, payload}: Action): UIState {
  switch (type) {
    case UIActions.MAIN_SCROLL:
      return Object.assign({}, state, {
        scrollEvent: payload,
        scrolledPastCalendarHeader: state.scrolledPastCalendarHeader,
      });
    case UIActions.SCROLLED_PAST_CALENDAR_HEADER:
      return Object.assign({}, state, {
        scrollEvent: state.scrollEvent,
        scrolledPastCalendarHeader: payload,
      });
    default:
      return state;
  }
}

export const getMainScrollEvent = (state: UIState) => state.scrollEvent;
export const getScrolledPastCalendarHeader = (state: UIState) => state.scrolledPastCalendarHeader;

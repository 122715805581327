import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {InstitutionService} from '../../core/institution.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as InstitutionActions from './institutions.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class InstitutionEffects {

  @Effect() loadInstitution$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD),
    switchMap(_ => {
      return this._service.load().pipe(
        map(payload => new InstitutionActions.LoadSuccess(payload)),
        catchError(error => of(new InstitutionActions.LoadFailure(error)))
      );
    })
  );

  @Effect() loadInstitutionById$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_BY_ID),
    map((action: InstitutionActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload).pipe(
        map(data => new InstitutionActions.LoadByIdSuccess(data)),
        catchError(error => of(new InstitutionActions.LoadByIdFailure(error)))
      );
    })
  );

  @Effect() loadInstitutionManager$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_MANAGER),
    map((action: InstitutionActions.LoadManager) => action.payload),
    switchMap(payload => {
      return this._service.loadManager(payload.institutionId, payload.managerId).pipe(
        map(data => new InstitutionActions.LoadManagerSuccess(data)),
        catchError(error => of(new InstitutionActions.LoadManagerFailed(error)))
      );
    })
  );

  @Effect() loadMoreInstitutions$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_MORE),
    map((action: InstitutionActions.LoadMore) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload).pipe(
        map(data => new InstitutionActions.LoadMoreSuccess(data)),
        catchError(error => of(new InstitutionActions.LoadMoreFailure(error)))
      );
    })
  );

  @Effect() searchInstitutions$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.SEARCH),
    map((action: InstitutionActions.Search) => action.payload),
    switchMap(payload => {
      return this._service.search(payload).pipe(
        map(data => new InstitutionActions.SearchSuccess(data)),
        catchError(response => of(new InstitutionActions.SearchFailed(response)))
      );
    })
  );

  @Effect() loadChildren$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_CHILDREN),
    map((action: InstitutionActions.LoadChildren) => action.payload),
    switchMap(payload => {
      return this._service.loadChildren(payload).pipe(
        map(data => new InstitutionActions.LoadChildrenSuccess(data)),
        catchError(response => of(new InstitutionActions.LoadChildrenFailure(response)))
      );
    })
  );

  @Effect() loadInstitutionEmployees$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_EMPLOYEES),
    map((action: InstitutionActions.LoadEmployees) => action.payload),
    switchMap(payload => {
      return this._service.loadEmployees(payload).pipe(
        map(data => new InstitutionActions.LoadEmployeesSuccess(data)),
        catchError(error => of(new InstitutionActions.LoadEmployeesFailure(error)))
      );
    })
  );

  @Effect() loadInstitutionServiceReceivers$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_SERVICE_RECEIVERS),
    map((action: InstitutionActions.LoadServiceReceivers) => action.payload),
    switchMap(payload => {
      return this._service.loadServiceReceivers(payload).pipe(
        map(data => new InstitutionActions.LoadServiceReceiversSuccess(data)),
        catchError(error => of(new InstitutionActions.LoadServiceReceiversFailure(error)))
      );
    })
  );

  @Effect() loadInstitutionsByUserId$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.LOAD_BY_USER_ID),
    map((action: InstitutionActions.LoadByUserId) => action.payload),
    switchMap(payload => {
      return this._service.loadByUserId(payload).pipe(
        map(data => new InstitutionActions.LoadByUserIdSuccess(data)),
        catchError(error => of(new InstitutionActions.LoadByUserIdFailure(error)))
      );
    })
  );

  @Effect() addInstitutionEmployee$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.ADD_EMPLOYEE),
    map((action: InstitutionActions.AddEmployee) => action.payload),
    switchMap(payload => {
      return this._service.addEmployee(payload.institutionId, payload.employeeId).pipe(
        map(data => new InstitutionActions.AddEmployeeSuccess(data)),
        catchError(response => of(new InstitutionActions.AddEmployeeFailure(response)))
      );
    })
  );

  @Effect() removeInstitutionEmployee$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.REMOVE_EMPLOYEE),
    map((action: InstitutionActions.AddEmployee) => action.payload),
    switchMap(payload => {
      return this._service.removeEmployee(payload.institutionId, payload.employeeId).pipe(
        map(data => new InstitutionActions.RemoveEmployeeSuccess(data)),
        catchError(response => of(new InstitutionActions.RemoveEmployeeFailure(response)))
      );
    })
  );

  @Effect() addInstitutionServiceReceivers$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.ADD_SERVICE_RECEIVER),
    map((action: InstitutionActions.AddServiceReceiver) => action.payload),
    switchMap(payload => {
      return this._service.addServiceReceiver(payload.institutionId, payload.serviceReceiverId).pipe(
        map(data => new InstitutionActions.AddServiceReceiverSuccess(data)),
        catchError(response => of(new InstitutionActions.AddServiceReceiverFailed(response)))
      );
    })
  );

  @Effect() removeInstitutionServiceReceivers$: Observable<Action> = this.actions$.pipe(
    ofType(InstitutionActions.REMOVE_SERVICE_RECEIVER),
    map((action: InstitutionActions.RemoveServiceReceiver) => action.payload),
    switchMap(payload => {
      return this._service.removeServiceReceiver(payload.institutionId, payload.serviceReceiverId).pipe(
        map(data => new InstitutionActions.RemoveServiceReceiverSuccess(data)),
        catchError(response => of(new InstitutionActions.RemoveServiceReceiverFailed(response)))
      );
    })
  );

  constructor(private actions$: Actions, private _service: InstitutionService) {
  }
}

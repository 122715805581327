import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {GroupService} from '../../core/group.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as GroupActions from './groups.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class GroupEffects {

  @Effect() load$: Observable<Action> = this.actions$.pipe(
    ofType(GroupActions.LOAD),
    switchMap(_ => {
      return this._service.load().pipe(
        map(payload => new GroupActions.LoadSuccess(payload)),
        catchError(response => of(new GroupActions.LoadFailure(response)))
      );
    })
  );

  @Effect() searchGroups$: Observable<Action> = this.actions$.pipe(
    ofType(GroupActions.SEARCH),
    map((action: GroupActions.Search) => action.payload),
    switchMap(payload => {
      return this._service.search(payload).pipe(
        map(data => new GroupActions.SearchSuccess(data)),
        catchError(response => of(new GroupActions.SearchFailed(response)))
      );
    })
  );

  @Effect() loadById$: Observable<Action> = this.actions$.pipe(
    ofType(GroupActions.LOAD_BY_ID),
    map((action: GroupActions.LoadById) => action.payload),
    switchMap(payload => {
      return this._service.loadById(payload).pipe(
        map(data => new GroupActions.LoadByIdSuccess(data)),
        catchError(response => of(new GroupActions.LoadByIdFailure(response)))
      );
    })
  );

  @Effect() create$: Observable<Action> = this.actions$.pipe(
    ofType(GroupActions.CREATE),
    map((action: GroupActions.Create) => action.payload),
    switchMap(payload => {
      return this._service.create(payload).pipe(
        map(data => new GroupActions.CreateSuccess(data)),
        catchError(response => of(new GroupActions.CreateFailure(response)))
      );
    })
  );

  @Effect() loadByUserId$: Observable<Action> = this.actions$.pipe(
    ofType(GroupActions.LOAD_BY_USER_ID),
    map((action: GroupActions.LoadByUserId) => action.payload),
    switchMap(payload => {
      return this._service.loadByUserId(payload).pipe(
        map(data => new GroupActions.LoadByUserIdSuccess(data)),
        catchError(response => of(new GroupActions.LoadByUserIdFailure(response)))
      );
    })
  );

  constructor(private actions$: Actions, private _service: GroupService) {}
}

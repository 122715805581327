import {Action} from '@ngrx/store';

export const LOAD_BY_USER = '[Relation] load by user';
export const LOAD_BY_USER_SUCCESS = '[Relation] load by user success';
export const LOAD_BY_USER_FAILURE = '[Relation] load by user failed';
export const RESET = '[Entry] reset';

export class LoadByUser implements Action {
  readonly type = LOAD_BY_USER;

  constructor(public payload: string) {}
}

export class LoadByUserSuccess implements Action {
  readonly type = LOAD_BY_USER_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadByUserFailure implements Action {
  readonly type = LOAD_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export type All = LoadByUser | LoadByUserSuccess | LoadByUserFailure | Reset;

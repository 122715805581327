import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AddressService} from '../../core/address.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as AddressAction from './address.action';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class AddressEffects {
  @Effect() loadAddress$: Observable<Action> = this.actions$
    .pipe(
      ofType(AddressAction.LOAD_ADDRESS),
      map((action: AddressAction.LoadAddress) => action.payload),
      switchMap(payload => {
        return this._service.loadAddressByUrl(payload)
          .pipe(
            map(data => new AddressAction.LoadAddressSuccess(data)),
            catchError(error => of(new AddressAction.LoadAddressFailed(error)))
          );
      })
  );

  @Effect() loadCountries$: Observable<Action> = this.actions$
    .pipe(
      ofType(AddressAction.LOAD_COUNTRIES),
      switchMap(_ => {
        return this._service.loadCountries()
          .pipe(
            map(data => new AddressAction.LoadCountriesSuccess(data)),
            catchError(error => of(new AddressAction.LoadCountriesFailed(error)))
          );
      })
  );

  @Effect() loadMunicipalities$: Observable<Action> = this.actions$
    .pipe(
      ofType(AddressAction.LOAD_MUNICIPALITIES),
      map((action: AddressAction.LoadMunicipalities) => action.payload),
      switchMap(payload => {
        return this._service.loadMunicipalities(payload)
          .pipe(
            map(data => new AddressAction.LoadMunicipalitiesSuccess(data)),
            catchError(error => of(new AddressAction.LoadMunicipalitiesFailed(error)))
          );
      })
    );

  @Effect() searchMunicipalities$: Observable<Action> = this.actions$
    .pipe(
      ofType(AddressAction.SEARCH_MUNICIPALITIES),
      map((action: AddressAction.SearchMunicipalities) => action.payload),
      switchMap(payload => {
        return this._service.searchMunicipalities(payload)
          .pipe(
            map(data => new AddressAction.SearchMunicipalitiesSuccess(data)),
            catchError(error => of(new AddressAction.SearchMunicipalitiesFailed(error)))
          );
      })
    );

  constructor(private actions$: Actions, private _service: AddressService) {}
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Location} from '@angular/common';
import { Observable , Subscription} from 'rxjs';
import * as rootState from '../../store';
import * as AuthActions from '../../store/auth/auth.actions';
import {Router} from '@angular/router';
import {NavService} from '../nav.service';
import {AuthenticationState} from '../../enums';
import {map} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
  moduleId: module.id,
  templateUrl: './nav-bar.component.html',
  selector: 'app-nav-bar'
})
export class NavBarComponent implements OnInit, OnDestroy {
  isAuthenticated$: Observable<boolean>;
  navMode: string;
  serviceReceiver: string;
  loggedInUser: any;
  unreadMessages: any[] = [];
  showSubMenu: boolean;
  subMenuXPosition: string;
  combinedSubscription: Subscription;
  messageSubscription: Subscription;
  navSubscription: Subscription;
  viewImageSubscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>,
              private _router: Router,
              private _location: Location,
              private _nav: NavService,
              private _cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.showSubMenu) {
      this.showSubMenu = false;
    }

    this.isAuthenticated$ = this._store.pipe(
      select(rootState.getAuthenticationState),
      map(autState => (autState === AuthenticationState.AUTHENTICATED))
    );

    const loggedInUser$ = this._store.pipe(select(rootState.getLoggedInUser));
    const serviceReceivers$ = this._store.pipe(select(rootState.getServiceReceivers));
    this.combinedSubscription = combineLatest(loggedInUser$, serviceReceivers$)
      .subscribe(values => {
        this.loggedInUser = values[0];
        this.navMode = this._nav.getNavMode(values[0], values[1]);

        if (this.navMode === 'single') {
          this.serviceReceiver = (this.loggedInUser.is_service_receiver) ? this.loggedInUser : values[1][0];
        }
        this.setSubMenuPosition();
      });


    this.messageSubscription = this._store.pipe(select(rootState.getMessagesUnread))
      .subscribe(unread => {
        if (unread) {
          this.unreadMessages = unread;
        }
      });

    this.viewImageSubscription = this._store.pipe(
      select(rootState.getUsersViewImage),
      map(viewImage => {
        if (viewImage) {
          const reader = new FileReader();
          reader.readAsDataURL(viewImage);
          reader.onload = () => {
            this.loggedInUser.image = Object.assign({}, this.loggedInUser.image, {small: reader.result});
          };
        }
      }))
      .subscribe();

    this.navSubscription = this._nav.navEvents$
      .subscribe(event => (this.showSubMenu) ? this.toggleShowSubMenu() : null );
  }

  toggleShowSubMenu(): void {
    this.showSubMenu = !this.showSubMenu;
  }

  logout(): void {
    this._store.dispatch(new AuthActions.UnAuthenticate());
  }

  setSubMenuPosition(): void {
    const navLoggedInUser = document.getElementById('nav__logged-in-user');
    if (navLoggedInUser) {
      this.subMenuXPosition = (navLoggedInUser.offsetLeft - 70) + 'px';
    }
  }

  onImageError(event, image) {
    if (image['original']) {
      if (event.srcElement.src === image['original']) {
        event.srcElement.src = 'assets/imgUnavailable.png';
      } else {
        event.srcElement.src = image['original'];
      }
    }
  }


  ngOnDestroy(): void {
    if (this.combinedSubscription) {
      this.combinedSubscription.unsubscribe();
    }

    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
    }

    if (this.viewImageSubscription) {
      this.viewImageSubscription.unsubscribe();
    }
  }
}

import {Action} from '@ngrx/store';

export const LOAD = '[Relative] loads';
export const LOAD_SUCCESS = '[Relative] load';
export const LOAD_FAILURE = '[Relative] load failed';
export const LOAD_MORE = '[Relative] loads';
export const LOAD_MORE_SUCCESS = '[Relative] load success';
export const LOAD_MORE_FAILURE = '[Relative] load failed';
export const RESET = '[Relative] reset';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload: string) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any[]) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export type All = Load | LoadSuccess | LoadFailure | LoadMore | LoadMoreSuccess | LoadMoreFailure | Reset;
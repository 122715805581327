import * as CreateInstitutionChildActions from './create-institution-child.actions';
import {handleError} from '../helpers';

export type Action = CreateInstitutionChildActions.All;

export interface CreateInstitutionChildState {
  loading: boolean;
  message: string;
  error: string;
}

const initalState: CreateInstitutionChildState = {
  loading: null,
  message: null,
  error: null
};

export function reducer(state: CreateInstitutionChildState = initalState, {type, payload}: Action): CreateInstitutionChildState {
  switch (type) {
    case CreateInstitutionChildActions.CREATE_INSTITUTION_CHILD:
      return Object.assign({}, state, {
        loading: true,
        message: null,
        error: null
      });
    case CreateInstitutionChildActions.CREATE_INSTITUTION_CHILD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        message: 'CREATED_SUCCESS',
        error: null
      });
    case CreateInstitutionChildActions.CREATE_INSTITUTION_CHILD_FAILED:
      return Object.assign({}, state, {
        loading: false,
        message: 'AN_ERROR_OCCURRED',
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: CreateInstitutionChildState) => state.loading;
export const getError = (state: CreateInstitutionChildState) => state.error;
export const getMessage = (state: CreateInstitutionChildState) => state.message;

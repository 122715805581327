import * as InvitationActions from './invitation.actions';
import {handleError} from "../helpers";

export type Action = InvitationActions.All;

export interface InvitationState {
  loading: boolean;
  entities: any[];
  error: string;
}

const initialState: InvitationState = {
  loading: null,
  entities: null,
  error: null
};

export function reducer(state: InvitationState = initialState, {type, payload}: Action): InvitationState {
  switch (type) {
    case InvitationActions.LOAD:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InvitationActions.LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload,
        error: null
      });
    case InvitationActions.LOAD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: 'COULD_NOT_LOAD_INVITATIONS'
      });
    case InvitationActions.ACCEPT:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InvitationActions.ACCEPT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null
      });
    case InvitationActions.ACCEPT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: InvitationState) => state.loading;
export const getEntities = (state: InvitationState) => state.entities;
export const getError = (state: InvitationState) => state.error;

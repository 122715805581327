import * as RegisterActions from './register.actions';

export type Action = RegisterActions.All;

export interface RegisterState {
  isRegistering: boolean;
  errorMessage: string;
}

const initialState: RegisterState = {
  isRegistering: null,
  errorMessage: null
};

function handleErrorMessage(payload: any): string {
  if (payload.email) {
    return 'EMAIL_ALREADY_EXISTS';
  } else {
    return 'AN_ERROR_OCCURRED';
  }
}

export function reducer(state: RegisterState = initialState, {type, payload}: Action): RegisterState {
  switch (type) {
    case RegisterActions.REGISTER:
      return Object.assign({}, state, {
        errorMessage: null,
        isRegistering: true
      });
    case RegisterActions.REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isRegistering: false
      });
    case RegisterActions.REGISTER_FAILURE:
      return Object.assign({}, state, {
        isRegistering: false,
        errorMessage: handleErrorMessage(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: RegisterState) => state.isRegistering;
export const getErrorMessage = (state: RegisterState) => state.errorMessage;

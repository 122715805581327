import {Action} from '@ngrx/store';

export const LOAD_DIALOG = '[Dialog] load dialogs';
export const LOAD_DIALOG_SUCCESS = '[Dialog] load dialogs success';
export const LOAD_DIALOG_FAILURE = '[Dialog] load dialogs failure';
export const LOAD_RECIPIENTS = '[Dialog] load dialog recipients';
export const LOAD_RECIPIENTS_SUCCESS = '[Dialog] load dialog recipients success';
export const LOAD_RECIPIENTS_FAILURE = '[Dialog] load dialog recipients failure';
export const CREATE_DIALOG_MESSAGE = '[Dialog] create dialog';
export const CREATE_DIALOG_MESSAGE_SUCCESS = '[Dialog] create dialog success';
export const CREATE_DIALOG_MESSAGE_FAILURE = '[Dialog] create dialog failure';
export const LOAD_DIALOG_THREAD = '[Dialog] load dialog tread';
export const LOAD_DIALOG_THREAD_SUCCESS = '[Dialog] load dialog tread success';
export const LOAD_DIALOG_THREAD_FAILURE = '[Dialog] load dialog tread failure';
export const LOAD_MORE_DIALOG_THREAD = '[Dialog] load more dialog tread';
export const LOAD_MORE_DIALOG_THREAD_SUCCESS = '[Dialog] load more dialog tread success';
export const LOAD_MORE_DIALOG_THREAD_FAILURE = '[Dialog] load more dialog tread failure';

export class LoadDialog implements Action {
  readonly type = LOAD_DIALOG;

  constructor(public payload?: any) {
  }
}

export class LoadDialogSuccess implements Action {
  readonly type = LOAD_DIALOG_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadDialogFailure implements Action {
  readonly type = LOAD_DIALOG_FAILURE;

  constructor(public payload: any) {
  }
}

export class LoadRecipients implements Action {
  readonly type = LOAD_RECIPIENTS;

  constructor(public payload?: any) {
  }
}

export class LoadRecipientsSuccess implements Action {
  readonly type = LOAD_RECIPIENTS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadRecipientsFailure implements Action {
  readonly type = LOAD_RECIPIENTS_FAILURE;

  constructor(public payload: any) {
  }
}

export class CreateDialogMessage implements Action {
  readonly type = CREATE_DIALOG_MESSAGE;

  constructor(public payload: any) {
  }
}

export class CreateDialogMessageSuccess implements Action {
  readonly type = CREATE_DIALOG_MESSAGE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class CreateDialogMessageFailure implements Action {
  readonly type = CREATE_DIALOG_MESSAGE_FAILURE;

  constructor(public payload: any) {
  }
}

export class LoadDialogThread implements Action {
  readonly type = LOAD_DIALOG_THREAD;

  constructor(public payload: any) {
  }
}

export class LoadDialogThreadSuccess implements Action {
  readonly type = LOAD_DIALOG_THREAD_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadDialogThreadFailure implements Action {
  readonly type = LOAD_DIALOG_THREAD_FAILURE;

  constructor(public payload: string) {
  }
}

export class LoadMoreDialogThread implements Action {
  readonly type = LOAD_MORE_DIALOG_THREAD;

  constructor(public payload: any) {
  }
}

export class LoadMoreDialogThreadSuccess implements Action {
  readonly type = LOAD_MORE_DIALOG_THREAD_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadMoreDialogThreadFailure implements Action {
  readonly type = LOAD_MORE_DIALOG_THREAD_FAILURE;

  constructor(public payload: any) {
  }
}


export type All = LoadDialog | LoadDialogFailure | LoadDialogSuccess | LoadRecipients | LoadRecipientsFailure | LoadRecipientsSuccess |
  CreateDialogMessage | CreateDialogMessageFailure | CreateDialogMessageSuccess | LoadDialogThread | LoadDialogThreadSuccess |
  LoadDialogThreadFailure | LoadMoreDialogThread | LoadMoreDialogThreadSuccess | LoadMoreDialogThreadFailure;

import * as PermissionsActions from './permissions.actions';
import {handleError} from '../helpers';

export type Action = PermissionsActions.ALL;

export interface PermissionsState {
  loading: boolean;
  error: string;
  permissions: any;
}

const initialState: PermissionsState = {
  loading: null,
  error: null,
  permissions: null
};

export function reducer(state: PermissionsState = initialState, {type, payload}: Action): PermissionsState {
  switch (type) {
    case PermissionsActions.CHECK_PERMISSIONS:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case PermissionsActions.CHECK_PERMISSIONS_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        loading: false,
        permissions: payload
      });
    case PermissionsActions.CHECK_PERMISSIONS_FAILURE:
      return Object.assign({}, state, {
        error: handleError(payload),
        loading: false
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: PermissionsState) => state.loading;
export const getPermissions = (state: PermissionsState) => state.permissions;
export const getError = (state: PermissionsState) => state.error;

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LocalStorageService} from 'ngx-webstorage';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL, API_VERSION} from './config';
import {UploadResponse} from '../interfaces';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class ImageService extends ApiService {

  constructor(protected _ls: LocalStorageService, private _http: HttpClient) {
    super(_ls);
  }

  uploadImage(file: File, orientation?: number): Observable<any> {
    const url = `${API_URL}${API_VERSION}images/upload`;

    return this._http.post(url, JSON.stringify({filename: file.name}), {headers: this._getHeaders()})
      .pipe(
        switchMap((response: UploadResponse) => {
          const headers = new HttpHeaders({'Content-Type': file.type});
          return this._http.put(response.url, file, {headers: headers})
            .pipe(map(_ => response.filename));
        }),
        switchMap(filename => {
          const imageUrl = `${API_URL}${API_VERSION}images`;
          return this._http.post(imageUrl, JSON.stringify({filename: filename, orientation: orientation}), {headers: this._getHeaders()});
        })
      );
  }

  remove(id: string): Observable<any> {
    return this._http.delete(`${API_URL}${API_VERSION}images/${id}`, {headers: this._getHeaders()});
  }
}

import {Action} from '@ngrx/store';

export const LOAD = '[Messages] load';
export const LOAD_SUCCESS = '[Messages] load success';
export const LOAD_FAILURE = '[Messages] load failed';
export const LOAD_MORE = '[Messages] load more';
export const LOAD_MORE_SUCCESS = '[Messages] load more success';
export const LOAD_MORE_FAILURE = '[Messages] load more failed';
export const TOGGLE_READ = '[Messages] toggle read';
export const TOGGLE_READ_SUCCESS = '[Messages] toggle read success';
export const TOGGLE_READ_FAILURE = '[Messages] toggle read failed';
export const LOAD_FEED_BY_OWNER = '[Messages] load feed by owner';
export const LOAD_FEED_BY_OWNER_SUCCESS = '[Messages] load feed by owner success';
export const LOAD_FEED_BY_OWNER_FAILURE = '[Messages] load feed by owner failure';
export const COMMENT = '[Messages]';
export const COMMENT_SUCCESS = '[Messages] success';
export const COMMENT_FAILURE = '[Messages] failure';
export const DELETE_COMMENT = '[Messages] delete comment';
export const DELETE_COMMENT_SUCCESS = '[Messages] delete comment success';
export const DELETE_COMMENT_FAILURE = '[Messages] delete comment failure';
export const DELETE = '[Messages] delete';
export const DELETE_SUCCESS = '[Messages] delete success';
export const DELETE_FAILURE = '[Messages] delete failure';
export const UPDATE = '[Messages] update';
export const UPDATE_SUCCESS = '[Messages] update success';
export const UPDATE_FAILURE = '[Messages] update failure';
export const RESET_STATE_MESSAGE = '[Messages] reset state message';
export const RESET = '[Messages] reset';
export const LOAD_BY_ID = '[Messages] load by id';
export const LOAD_BY_ID_SUCCESS = '[Messages] load by id success';
export const LOAD_BY_ID_FAILURE = '[Messages] load by id failure';
export const UPDATE_COMMENT = '[Messages] update comment';
export const UPDATE_COMMENT_SUCCESS = '[Messages] update comment success';
export const UPDATE_COMMENT_FAILURE = '[Messages] update comment failure';
export const LOAD_UNREAD = '[Messages] load unread messages';
export const LOAD_UNREAD_SUCCESS = '[Messages] load unread messages success';
export const LOAD_UNREAD_FAILURE = '[Messages] load unread messages failure';
export const MARK_ALL_AS_READ = '[Messages] mark all as read';
export const MARK_ALL_AS_READ_SUCCESS = '[Messages] mark all as read success';
export const MARK_ALL_AS_READ_FAILURE = '[Messages] mark all as read failure';
export const LOAD_PINNED_MESSAGES = '[Messages] load pinned posts';
export const LOAD_PINNED_MESSAGES_SUCCESS = '[Messages] load pinned posts success';
export const LOAD_PINNED_MESSAGES_FAILURE = '[Messages] load pinned posts failure';
export const LOAD_MORE_PINNED_MESSAGES = '[Messages] load pinned posts';
export const LOAD_MORE_PINNED_MESSAGES_SUCCESS = '[Messages] load pinned posts success';
export const LOAD_MORE_PINNED_MESSAGES_FAILURE = '[Messages] load pinned posts failure';

export class Load implements Action {
  readonly type = LOAD;

  constructor(public payload?: any) {}
}

export class LoadSuccess implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFailure implements Action {
  readonly type = LOAD_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMore implements Action {
  readonly type = LOAD_MORE;

  constructor(public payload?: any) {}
}

export class LoadMoreSuccess implements Action {
  readonly type = LOAD_MORE_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreFailure implements Action {
  readonly type = LOAD_MORE_FAILURE;

  constructor(public payload: string) {}
}

export class ToggleRead implements Action {
  readonly type = TOGGLE_READ;

  constructor(public payload: any) {}
}

export class ToggleReadSuccess implements Action {
  readonly type = TOGGLE_READ_SUCCESS;

  constructor(public payload: any) {}
}

export class ToggleReadFailure implements Action {
  readonly type = TOGGLE_READ_FAILURE;

  constructor(public payload: string) {}
}

export class LoadFeedByOwner implements Action {
  readonly type = LOAD_FEED_BY_OWNER;

  constructor(public payload: any) {}
}

export class LoadFeedByOwnerSuccess implements Action {
  readonly type = LOAD_FEED_BY_OWNER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadFeedByOwnerFailure implements Action {
  readonly type = LOAD_FEED_BY_OWNER_FAILURE;

  constructor(public payload: string) {}
}

export class Comment implements Action {
  readonly type = COMMENT;

  constructor(public payload: any) {}
}

export class CommentSuccess implements Action {
  readonly type = COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class CommentFailure implements Action {
  readonly type = COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class DeleteComment implements Action {
  readonly type = DELETE_COMMENT;

  constructor(public payload: any) {}
}

export class DeleteCommentSuccess implements Action {
  readonly type = DELETE_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteCommentFailure implements Action {
  readonly type = DELETE_COMMENT_FAILURE;

  constructor(public payload: string) {}
}

export class Delete implements Action {
  readonly type = DELETE;

  constructor(public payload: any) {}
}

export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteFailure implements Action {
  readonly type = DELETE_FAILURE;

  constructor(public payload: string) {}
}

export class Update implements Action {
  readonly type = UPDATE;

  constructor(public payload: any) {}
}

export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFailure implements Action {
  readonly type = UPDATE_FAILURE;

  constructor(public payload: string) {}
}

export class ResetStateMessage implements Action {
  readonly type = RESET_STATE_MESSAGE;

  constructor(public payload?: any) {}
}

export class Reset implements Action {
  readonly type = RESET;

  constructor(public payload?: any) {}
}

export class LoadById implements Action {
  readonly type = LOAD_BY_ID;

  constructor(public payload: string) {}
}

export class LoadByIdSuccess implements Action {
  readonly type = LOAD_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadByIdFailure implements Action {
  readonly type = LOAD_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateComment implements Action {
  readonly type = UPDATE_COMMENT;

  constructor(public payload: string) {}
}

export class UpdateCommentSuccess implements Action {
  readonly type = UPDATE_COMMENT_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateCommentFailure implements Action {
  readonly type = UPDATE_COMMENT_FAILURE;

  constructor(public payload: any) {}
}

export class LoadUnread implements Action {
  readonly type = LOAD_UNREAD;

  constructor(public payload?: string) {}
}

export class LoadUnreadSuccess implements Action {
  readonly type = LOAD_UNREAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUnreadFailure implements Action {
  readonly type = LOAD_UNREAD_FAILURE;

  constructor(public payload: string) {}
}

export class MarkAllAsRead implements Action {
  readonly type = MARK_ALL_AS_READ;

  constructor(public payload?: string) {}
}

export class MarkAllAsReadSuccess implements Action {
  readonly type = MARK_ALL_AS_READ_SUCCESS;

  constructor(public payload: any) {}
}

export class MarkAllAsReadFailure implements Action {
  readonly type = MARK_ALL_AS_READ_FAILURE;

  constructor(public payload: any) {}
}

export class LoadPinnedMessages implements Action {
  readonly type  = LOAD_PINNED_MESSAGES;

  constructor(public payload?: any) {}
}

export class LoadPinnedMessagesSuccess implements Action {
  readonly type  = LOAD_PINNED_MESSAGES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadPinnedMessagesFailure implements Action {
  readonly type  = LOAD_PINNED_MESSAGES_FAILURE;

  constructor(public payload: any) {}
}

export class LoadMorePinnedMessages implements Action {
  readonly type  = LOAD_MORE_PINNED_MESSAGES;

  constructor(public payload: string) {}
}

export class LoadMorePinnedMessagesSuccess implements Action {
  readonly type  = LOAD_MORE_PINNED_MESSAGES_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMorePinnedMessagesFailure implements Action {
  readonly type  = LOAD_MORE_PINNED_MESSAGES_FAILURE;

  constructor(public payload: any) {}
}

export type All = Load | LoadSuccess | LoadFailure | LoadMore | LoadMoreSuccess | LoadMoreFailure | ToggleRead | ToggleReadSuccess|
  ToggleReadFailure | LoadFeedByOwner | LoadFeedByOwnerSuccess | LoadFeedByOwnerFailure | Comment | CommentSuccess | DeleteComment |
  DeleteCommentSuccess | DeleteCommentFailure | CommentFailure | Delete | DeleteSuccess | DeleteFailure | Update | UpdateSuccess |
  UpdateFailure | ResetStateMessage | Reset | LoadById | LoadByIdSuccess | LoadByIdFailure |
  UpdateComment | UpdateCommentFailure | UpdateCommentSuccess | LoadUnread | LoadUnreadFailure | LoadUnreadSuccess |
  MarkAllAsRead | MarkAllAsReadFailure | MarkAllAsReadSuccess | LoadPinnedMessages | LoadPinnedMessagesFailure | LoadPinnedMessagesSuccess |
  LoadMorePinnedMessages | LoadMorePinnedMessagesFailure | LoadMorePinnedMessagesSuccess;

import * as MemberActions from './member.actions';
import {handleError, updateEntity} from '../helpers';

export type Action = MemberActions.All;

export interface MemberState {
  loading: boolean;
  entitiesByGroupId: any;
  error: string;
}

const initialState: MemberState = {
  loading: false,
  entitiesByGroupId: null,
  error: null
};

export function reducer(state: MemberState = initialState, {type, payload}: Action): MemberState {
  switch (type) {
    case MemberActions.LOAD_BY_ID:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case MemberActions.LOAD_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesByGroupId: Object.assign({}, state.entitiesByGroupId, {
          [payload.groupId]: payload.members
        })
      });
    case MemberActions.LOAD_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case MemberActions.REMOVE_MEMBER:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case MemberActions.REMOVE_MEMBER_SUCCESS:
      const removedMembers = (state.entitiesByGroupId) ? updateEntity(state.entitiesByGroupId, payload) : payload;

      return Object.assign({}, state, {
        loading: false,
        entitiesByGroupId: removedMembers,
        error: null
      });
    case MemberActions.REMOVE_MEMBER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case MemberActions.ADD_MEMBER:
      return Object.assign({}, state, {
        error: null,
        loading: true
      });
    case MemberActions.ADD_MEMBER_SUCCESS:
      const addedMembers = (state.entitiesByGroupId) ? updateEntity(state.entitiesByGroupId, payload) : payload;

      return Object.assign({}, state, {
        loading: false,
        entitiesByGroupId: addedMembers,
        error: null
      });
    case MemberActions.ADD_MEMBER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: MemberState) => state.loading;
export const getEntitiesByGroupId = (state: MemberState) => state.entitiesByGroupId;
export const getError = (state: MemberState) => state.error;

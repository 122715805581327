import {Injectable} from '@angular/core';
import {API_URL, API_VERSION} from 'app/core/config';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';
import { DeviceDetectorService } from 'ngx-device-detector';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class FCMService extends ApiService {


  constructor(protected _ls: LocalStorageService, private _http: HttpClient, private _deviceDetector: DeviceDetectorService) {
    super(_ls);
  }

  load(userId: string): Observable<any> {
    return this._http.get(`${API_URL}${API_VERSION}users/${userId}/fcm`, { headers: this._getHeaders() });
  }

  registerFCMDevice(fbToken: string, language: string, userId: string): Observable<any> {
    const device = this._deviceDetector.getDeviceInfo();
    const data = {
      token: fbToken,
      language: language,
      device_id: userId,
      description: device.browser + ' ' + device.browser_version
    };

    const url = `${API_URL}${API_VERSION}fcm/device`;
    return this._http.post(url, data, { headers: this._getHeaders() });
  }

  unregisterFCMDevice() {
    const url = `${API_URL}${API_VERSION}fcm/device/${'device.uuid'}`;
    return this._http.delete(url, { headers: this._getHeaders() });
  }
}

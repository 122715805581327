import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CalendarService} from '../../core/calendar.service';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import * as OccurrenceActions from './occurrence.actions';
import * as CreateActions from '../create/create.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class OccurrenceEffects {

  @Effect() loadByOwner$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.LOAD_BY_OWNER),
    map((action: OccurrenceActions.LoadByOwner) => action.payload),
    switchMap(payload => {
      return this._service.loadByOwner(payload.owner, payload.start, payload.end).pipe(
        map(data => new OccurrenceActions.LoadByOwnerSuccess(data)),
        catchError(error => of(new OccurrenceActions.LoadByOwnerFailure(error)))
      );
    })
  );

  @Effect() loadByEventId$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.LOAD_BY_EVENT_ID),
    map((action: OccurrenceActions.LoadByEventId) => action.payload),
    switchMap(payload => {
      return this._service.loadEventById(payload.id, payload.start, payload.end).pipe(
        map(data => new OccurrenceActions.LoadByEventIdSuccess(data)),
        catchError(error => of(new OccurrenceActions.LoadByEventIdFailure(error)))
      );
    })
  );

  @Effect() delete$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.DELETE),
    map((action: OccurrenceActions.Delete) => action.payload),
    switchMap(payload => {
      return this._service.deleteEvent(payload).pipe(
        map(data => new OccurrenceActions.DeleteSuccess(data)),
        catchError(error => of(new OccurrenceActions.DeleteFailure(error)))
      );
    })
  );

  @Effect() cancel$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.CANCEL),
    map((action: OccurrenceActions.Cancel) => action.payload),
    switchMap(payload => {
      return this._service.cancel(payload).pipe(
        map(data => new OccurrenceActions.CancelSuccess(data)),
        catchError(error => of(new OccurrenceActions.CancelFailure(error)))
      );
    })
  );

  @Effect() onCreate$: Observable<Action> = this.actions$.pipe(
    ofType(CreateActions.PUBLISH_EVENT_SUCCESS),
    map((action: CreateActions.PublishEventSuccess) => action.payload),
    switchMap(payload => {
      if (payload) {
        const ownerId = payload.owner.id;
        return this._service.loadByOwner(ownerId, payload.start, payload.end).pipe(
          map(data => new OccurrenceActions.LoadByOwnerSuccess(data)),
          catchError(error => of(new OccurrenceActions.LoadByOwnerFailure(error)))
        );
      }
      return of(payload);
    })
  );

  @Effect() update$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.UPDATE),
    map((action: OccurrenceActions.Update) => action.payload),
    switchMap(payload => {
      return this._service.update(payload.editedEvent, payload.deleteImage, payload.image, payload.editMode, payload.isRecurring).pipe(
        map(data => new OccurrenceActions.UpdateSuccess(data)),
        catchError(error => of(new OccurrenceActions.UpdateFailure(error)))
      );
    })
  );

  @Effect() comment$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.COMMENT),
    map((action: OccurrenceActions.Comment) => action.payload),
    switchMap(payload => {
      return this._service.comment(payload.text, payload.occurrence).pipe(
        map(data => new OccurrenceActions.CommentSuccess(data)),
        catchError(error => of(new OccurrenceActions.CommentFailure(error)))
      );
    })
  );

  @Effect() deleteComment$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.DELETE_COMMENT),
    map((action: OccurrenceActions.DeleteComment) => action.payload),
    switchMap(payload => {
      return this._service.deleteComment(payload).pipe(
        map(data => new OccurrenceActions.DeleteCommentSuccess(data)),
        catchError(response => of(new OccurrenceActions.DeleteCommentFailure(response)))
      );
    })
  );

  @Effect() LoadCommentByEventId$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.LOAD_COMMENTS_BY_EVENT_ID),
    map((action: OccurrenceActions.LoadCommentsByEventId) => action.payload),
    switchMap(payload => {
      return this._service.loadCommentByEventId(payload).pipe(
        map(data => new OccurrenceActions.LoadCommentsByEventIdSuccess(data)),
        catchError(response => of(new OccurrenceActions.LoadCommentsByEventIdFailure(response)))
      );
    })
  );

  @Effect() LoadCommentByOccurrenceId$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.LOAD_COMMENTS_BY_OCCURRENCE_ID),
    map((action: OccurrenceActions.LoadCommentsByOccurrenceId) => action.payload),
    switchMap(payload => {
      return this._service.loadCommentByOccurrenceId(payload.event_id, payload.id).pipe(
        map(data => new OccurrenceActions.LoadCommentsByOccurrenceIdSuccess(data)),
        catchError(response => of(new OccurrenceActions.LoadCommentsByOccurrenceIdFailure(response)))
      );
    })
  );

  @Effect() UpdateComment$: Observable<Action> = this.actions$.pipe(
    ofType(OccurrenceActions.UPDATE_COMMENT),
    map((action: OccurrenceActions.UpdateComment) => action.payload),
    switchMap(payload => {
      return this._service.updateComment(payload).pipe(
        map(data => new OccurrenceActions.UpdateCommentSuccess(data)),
        catchError(response => of(new OccurrenceActions.UpdateCommentFailure(response)))
      );
    })
  );


  constructor(private actions$: Actions, private _service: CalendarService) {
  }
}
